import React, {useState} from "react";
import {useMount} from "ahooks";
import {useLocation, useNavigate} from "react-router-dom";
import StoreOil from "./store_oil";
import {Modal, Toast} from "antd-mobile";
import {useDispatch, useSelector} from "react-redux";
import {userAppInfo} from "@store/auth.slice";
import {isCashStatus} from "@api/order";
import * as authStore from "@store/auth.slice";
import * as payStore from "@store/pay.slice";
import Tools from "@utils/tools";
import wx from "weixin-js-sdk-ts";
import {isSubcribeStatus} from "@api/app";
import {CloseCircleOutlined} from "@ant-design/icons";
import '../../components/loading_app.scss';

const alert = Modal.alert;

const UserPayApp = () => {
  const userinfo = useSelector(userAppInfo);
  const {user_pay_info} = userinfo;
  const navigate = useNavigate();
  const location = useLocation();
  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();
  const [is_show, setShowOfficial] = useState(false);
  const [initUrl, setInitUrl] = useState('');

  useMount(() => {
    initPage().then(()=> {
      console.log('初始化支付...')
    })
  })

  useMount(()=> {
    const urlParam = Tools.param2Obj();
    if (urlParam.hasOwnProperty('time')) {
      const dateTime = new Date().getTime() / 1000;
      const num = (dateTime - Number(urlParam.time)) / 60;
      if (num - 5 > 0) {
        Modal.alert('温馨提示', '此链接已过期，请重新扫码获取!', [{
          text: '我知道了',
          onPress: ()=> wx.closeWindow()
        }])
      }
    }
  })

  const initPage = ()=> {
    Toast.loading('请稍候...')
    return Promise.all([
      setUserPayInfo(),
      cash_order_status(),
      isOfficialImg(),
      disPatch(payStore.setInitMoney()),
    ]).then(()=> {
      Toast.hide();
    })
  }

  const setUserPayInfo = () => {
    disPatch(authStore.setUserPayCon(location.search));
  }

  const isOfficialImg = async ()=> {
    try {
      const urlParam = Tools.param2Obj();
      if (!urlParam.hasOwnProperty('qrcode_id')) return;
      const res = await isSubcribeStatus(urlParam.qrcode_id, urlParam.app_token);
      if (res.code === 200) {
        if (res.data.subscribe === 0) {
          setInitUrl(res.data.url);
          setShowOfficial(true);
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const cash_order_status = async()=> {
    const res = await isCashStatus();
    if (res.code === 200) {
      if (res.data.data) {
        alert('确认订单', '您有未确认订单，请联系加油员进行确认!', [
          { text: '我知道了', onPress: () => {
              navigate(`/viphome`, {replace: true})
            }
          }
        ])
      }
    }
  }

  return (
    <>
      <StoreOil userInfo={user_pay_info} />
      <div className={'mask-box'} style={is_show ? {display: 'block'}:{display: 'none'}}>
        <img src={initUrl} alt={'关注公众号'}/>
        <div className={'close-icon-all'}>
          <CloseCircleOutlined onClick={()=> setShowOfficial(false)} />
        </div>
      </div>
    </>
  )
}

export default UserPayApp;