import React from "react";
import {useMount, useSetState} from "ahooks";
import {failOrderCallBack, getOrderDetail} from "@api/order";
import Tools from "@utils/tools";
import PayResult, { PayResultProps } from "@components/PayResult";
import {List} from "antd-mobile";

import '@components/PayResult/index.scss';

const PayResultOrder = ()=> {
  const urlParam: { ordersn: string, status: string|null } = Tools.param2Obj();
  const balance_status = urlParam.hasOwnProperty('balance');
  const [orderInfo, setOrderInfo] = useSetState<PayResultProps>({
    amount: 0,
    money: 0,
    status: null,
    ordersn: "",
    name: "",
    pay_discount: 0,
    dec_point_money: 0,
    dec_discount_money: 0,
    dec_coupon_money: 0,
    take_point: 0,
    create_time: "",
    isBalance: false,
    platform_discount: 0
  });

  useMount(()=> {
    getOrderInfo();
  })

  const getOrderInfo = async() => {
    try {
      if (urlParam.hasOwnProperty('ordersn')) {
        if (urlParam.status === 'fail') await failOrderCallBack(urlParam.ordersn)
        const res = await getOrderDetail(urlParam.ordersn);
        if (res.code === 200) {
          setOrderInfo(res.data);
          if (!urlParam.hasOwnProperty('status)' )) {
            setOrderInfo({isBalance: res.data.pay_status})
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <PayResult amount={orderInfo.amount} money={orderInfo.money} status={urlParam.status} isBalance={balance_status}/>
      <List>
        <List.Item extra={orderInfo.ordersn}>订单号</List.Item>
        <List.Item extra={orderInfo.name}>名称</List.Item>
        <List.Item extra={orderInfo.point}>使用积分</List.Item>
        <List.Item extra={orderInfo.pay_discount}>充值优惠</List.Item>
        <List.Item extra={orderInfo.dec_point_money}>积分抵扣金额</List.Item>
        <List.Item extra={orderInfo.dec_coupon_money}>优惠券抵扣金额</List.Item>
        <List.Item extra={orderInfo.dec_discount_money}>折扣抵扣金额</List.Item>
        <List.Item extra={orderInfo.platform_discount}>平台卡优惠金额</List.Item>
        <List.Item extra={orderInfo.take_point}>获得积分</List.Item>
        <List.Item extra={orderInfo.create_time}>创建时间</List.Item>
      </List>
      {/*<div style={{marginTop: '10px'}}>*/}
      {/*  <Button type={'primary'} onClick={()=> closePage()}>完成</Button>*/}
      {/*</div>*/}
    </div>
  )
}

export default PayResultOrder;