import React, {FC, useRef, useState} from "react";
import {useUpdateEffect} from "ahooks";

interface RewardBgMusicState {
  isPlay: boolean;
}

const LotteryBgMusic: FC<RewardBgMusicState> = (props)=> {

  const bg_music: any = useRef();
  const [url, seUrl] = useState('');
  const [isPlay, setIsPlay] = useState(false);

  useUpdateEffect(()=> {
    if (props.isPlay) {
      return startPlayMusic()
    }
    return stopPlayMusic();
  }, [props.isPlay])

  // 开始音乐
  const startPlayMusic = ()=> {
    const musicC = bg_music.current;
    musicC.volume = 0.7;
    if (musicC) {
      musicC.src ='https://zltc.oss-cn-shanghai.aliyuncs.com/3394.mp3'
      musicC.play();
      setIsPlay(true);
    }
  }

  // 暂停音乐
  const stopPlayMusic =()=> {
    if (isPlay) {
      const musicC = bg_music.current;
      if (musicC) {
        musicC.pause();
        seUrl('')
      }
    }
  }

  return (
    <audio ref={bg_music} src={url}/>
  )
}


export default LotteryBgMusic;