import React, { useEffect, useState, useCallback } from "react";
import { TabBar } from "antd-mobile";
import { TabArr } from "./init";
import { useNavigate, useLocation } from "react-router-dom";
import { MyIcon } from "@components/AppIcon";
import "./tabbar.scss";

function TabBarBottom() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setTab] = useState(0);
  const [arrPath] = useState(['/home', '/oilmap', '/shop', '/person', '/', ])

  const tarBarIdx = useCallback( () => {
    setInitBtn();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    tarBarIdx()
  }, [tarBarIdx]);

  const setInitBtn = () => {
    if (location.pathname === '/') return setTab(0)
    let idx = arrPath.indexOf(location.pathname);
    if (idx > -1) setTab(idx);
  }

  const changeRoute = (path: string, index: number) => {
    setTab(index);
    if (path === '/' && location.pathname === '/home') return;
    navigate(path, { replace: true });
  }

  return (
    <div className="tabbar-con">
      <TabBar
        unselectedTintColor="#949494"
        tintColor="#D98128"
        barTintColor="white"
      >
        { TabArr.map((item, index) => {
          return (
            <TabBar.Item
                icon={<MyIcon style={{
                  fontSize: '24px'
                }} type={item.icon}/>}
                selectedIcon={<MyIcon style={{
                  fontSize: '24px',
                  color: "D98128"
                }} type={item.icon}/>}
                title={item.title}
                key={index}
                selected={selectedTab === index}
                onPress={() => changeRoute(item.path, index)}
              />
            );
          })}
      </TabBar>
    </div>
  );
}


export default TabBarBottom;
