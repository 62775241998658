import React, {useState} from "react";
import {List, InputItem, Button, TextareaItem, Toast, Picker} from 'antd-mobile';
import {setCooperationCity, setCooperationMarket, setCooperationOil, getAreaList } from "@api/app";
import {useMount} from "ahooks";
import Tools from "@utils/tools";
import wx from "weixin-js-sdk-ts";

const seasons = [
  [
    {
      label: '1-10',
      value: '1',
    },
    {
      label: '11-20',
      value: '2',
    },
    {
      label: '20以上',
      value: '3',
    },
  ]
];

const CooperateInfo = ()=> {
  const [nowCity, setNowCity] = useState<any>([]);
  const [oilName, setOilName] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [content, setContent] = useState('');
  const [areaData, setAreaData] = useState<any>([]);
  const [address, setAddress] = useState('');
  const [pickerValue,setPickerValue] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [cols, setCols] = useState(1);

  const urlParam = Tools.param2Obj();

  useMount(async ()=> {
    await getAreaData();
  })

  const getAreaData = async (id?: number)=> {
    try {
      const info = await getAreaList(id);
      if (info.code === 200) {
        let data: { label: string; value: number; }[]= [];
        info.data.forEach((item: { name: string; id: number; }) => {
          let obj = {
            label: item.name,
            value: item.id,
            children: []
          }
          data.push(obj)
        })
       setAreaData(data);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const pushUserInfo = async ()=> {
    console.log(pickerValue)
    try {
      let res;
      const data = {
        name: oilName,
        oilAddress: '',
        num_type: pickerValue[0],
        mobile: phone,
        linker: username,
        area_id: nowCity[1],
        area: nowCity[0],
        address,
        content
      }
      switch (urlParam.type) {
        case '0':
          res = await setCooperationOil(data);
          break;
        case '1':
          res = await setCooperationCity(data);
          break;
        case '2':
          res = await setCooperationMarket(data);
          break;
      }
      if (res?.code === 200) {
        Toast.success('提交成功');
        setTimeout(()=> wx.closeWindow(), 1000);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onPickerChange = async (val: any[])=> {
    try {
      let colNum = 1;
      const data = [...areaData];
      let asyncValue = [...val];
      console.log(val);
      if (val[0]) {
        const res = await getAreaList(val[0]);
        if (res.code === 200) {
          if (res.data.length > 2) {
            colNum = 2;
            let arr: Array<{label: string; value: number; children: any[]; parent_id: number}> = [];
            res.data.forEach((key: any) => {
              let obj = {
                label: key.name,
                value: key.id,
                children: [],
                parent_id: key.parent_id
              }
              arr.push(obj);
            })
            data.forEach((item => {
              if (item.value === arr[0].parent_id) {
                item.children = arr;
              }
            }))
            asyncValue.push(arr[0].value)
          } else {
            colNum = 1;
          }
        }
      } else {
        colNum = 1;
      }
      setAreaData(data);
      setCols(colNum);
      setNowCity(asyncValue);
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={'write-info'}>
      <div className={'main-con'}>
        <List renderHeader={() => '请填写合作信息'}>
          <InputItem clear placeholder="请输入姓名" required onChange={(val)=> setUsername(val)}>联系人</InputItem>
          <InputItem clear placeholder="请输入手机号码" maxLength={11} type={'number'} onChange={(value)=> setPhone(value)} required>联系电话</InputItem>
          {
            urlParam.type === '0' && (
              <>
                <InputItem clear placeholder="请输入加油站名称" onChange={(val)=> setOilName(val)} required>
                  加油站名称
                </InputItem>
                <Picker
                  visible={visible}
                  data={seasons}
                  value={pickerValue}
                  cols={1}
                  cascade={false}
                  onChange={v => setPickerValue(v)}
                  onOk={() => setVisible(false)}
                  onDismiss={() => setVisible(false) }
                >
                  <List.Item arrow={"horizontal"} onClick={()=> setVisible(true)}>
                    加油站数量
                  </List.Item>
                </Picker>
              </>
            )
          }
          {
            urlParam.type !== '0' && <>
                <InputItem clear placeholder="请输入公司名称" onChange={(val)=> setOilName(val)} required>
                    公司名称
                </InputItem>
            </>
          }

          <Picker
            data={areaData}
            cols={cols}
            value={nowCity}
            onPickerChange={(val)=> onPickerChange(val)}
            onOk={v => setCols(1)}
          >
            <List.Item arrow="horizontal" >所在地区</List.Item>
          </Picker>
          {
            urlParam.type !== '0' &&
            <InputItem
                clear
                placeholder="请输入详细地址"
                required
                onChange={(val)=> setAddress(val)}>
                公司详细地址
            </InputItem>
          }

          <TextareaItem
            title="备注"
            value={content}
            onChange={(val)=> val && setContent(val)}
            placeholder="请输入您的备注信息"
            data-seed="logId"
            autoHeight
          />
        </List>
        <Button className={'push-btn'} type={'primary'} onClick={()=> pushUserInfo()}>提交</Button>
      </div>
    </div>
  )
}

export default CooperateInfo;