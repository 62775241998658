import React, {FC} from "react";
import { MyIcon } from "../AppIcon";
import './index.scss';

const CopyrightBottom: FC = () => {
  return(
    <div className='bottom-copyright'>
      <span>汇悦客提供技术支持</span>
      <a href="tel:18888217968" className='learn-more'>
        <span>了解更多</span>
        <MyIcon type='iconqianjin'></MyIcon>
      </a>
    </div>
  )
}

export default CopyrightBottom;