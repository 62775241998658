import React, {FC, useState} from "react";
import {Button, Toast} from "antd-mobile";
import Copyright from "@components/Copyright/index"
import { AuthState } from "@store/auth.slice";
import {useDispatch} from "react-redux";
import {OilTypeState} from "@store/pay.slice";
import * as payStore from "@store/pay.slice";
import {useNavigate} from "react-router-dom";
import {useMount} from "ahooks";
import Tools from "@utils/tools";
import {activtiyDetail} from "@api/user";
import { usePageConfig } from "@utils/app_hooks";

import './pay.scss';

export interface StoreInfoProps {
  userInfo: AuthState['user_pay_info'];
}

const StoreTopInfo:FC<StoreInfoProps> = (props) => {
  const { userInfo } = props;
  const navigate  = useNavigate();
  const [dyActive, setDyActive] = useState(0);
  const [activityInfo, setActivityInfo] = useState({
    id: '',
    detail: {
      is_pay: '',
      is_reward: ''
    },
    title: '',
    video: {
      id: "",
      url: ""
    }
  });
  const {setOilType} = payStore.paySlice.actions;
  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();
  // const [nowInfo, setNowInfo] = useSessionStorageState('oil-info', {});

  // 是否开发票
  const is_show_receipt = usePageConfig('receipt_button');

  useMount(()=> {
    initActivity();
  })

  const setNowOilInfo = async (info: OilTypeState) => {
    Toast.loading('请稍候...')
    let obj = {
      cate_id: info.id,
      room_id: userInfo.room_id,
      money: 0
    }
    await disPatch(payStore.setManageCardList(info.goods_id, info.goods.type.id))
    await disPatch(payStore.setUserBuyCardList(info.goods_id, info.goods.type.id))
    await disPatch(payStore.setRechargeInfo(obj));
    // setNowInfo(info);
    await disPatch(setOilType(info));
    if (dyActive > 0) {
      return navigate(`/userPay?dy_active=${dyActive}&video_id=${activityInfo.video.id}&receipt=${is_show_receipt}`);
    }
    navigate(`/userPay?receipt=${is_show_receipt}`);
    Toast.hide()
  }

  // 初始化活动
  const initActivity = async ()=> {
    const urlParam = Tools.param2Obj();
    if (urlParam.hasOwnProperty('dy_active') && Tools.isDouYin()) {
      setActivity(urlParam.dy_active);
      setDyActive(urlParam.dy_active);
    }
  }

  // 展示活动信息
  const setActivity = async (id: string)=> {
    try {
      if (!id) return Toast.info('获取活动信息失败');
      const res = await activtiyDetail(id);
      if (res.code === 200) {
        setActivityInfo(res.data);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const oilListView = ()=> {
    return userInfo.cate.map((item: any, index) => {
      return (
        <div className={'oil-con-list'} key={index}>
          {
            item.list.map((child: any, idx: number) => {
              return <OilItem key={idx} item={child}></OilItem>
            })
          }
        </div>
      )
    })
  }

  const OilItem = (child: any)=> {
    let item = child.item;
    return(
      <div className='oil-btn-con'>
        <Button
          className='oil-btn'
          activeClassName='oil-btm-active'
          onClick={() => setNowOilInfo(item)}
        >
          <span>{item.goods.name}</span>
          <span>{item.name}</span>
        </Button>
      </div>
    )
  }

  return(
    <div className="add-oil-con">
      <div className="oil-center">
        <div className='store-info'>
          <div className='store-img'>
            <img alt='门店logo' src={userInfo.room_image}/>
          </div>
          <div className='store-main'>
            <h6>{userInfo.title}</h6>
            <span>{userInfo.room_area}</span>
          </div>
        </div>
        <div className='store-oil'>
          <div className='store-title'>选择油品类型</div>
          <div className='oil-con'>
            {oilListView()}
          </div>
        </div>
      </div>
      <Copyright/>
    </div>
  )
}

export default StoreTopInfo;