import React, {FC} from "react";
import {Carousel} from 'antd-mobile';
import {useMount} from "ahooks";
import './home.scss';

interface HomeBannerState {
  bannerArr: Array<{url: string}>
}

const HomeBanner: FC<HomeBannerState> = (props)=> {
  const {bannerArr} = props;

  useMount(()=> {
    window.addEventListener('touchmove', afterChange, { passive: false })
    window.addEventListener('touchmove', beforeChange, { passive: false })
  })

  const beforeChange = ()=> {
    console.log('xxx')
  }

  const afterChange = ()=> {
    console.log('aaa')
  }

  if (bannerArr.length === 0) return <></>;

  return (
    <Carousel
      autoplay={false}
      infinite
      dots={false}
      beforeChange={(from, to) => beforeChange()}
      afterChange={index => afterChange()}
    >
      {bannerArr.map((val, index) => (
        <img
          key={index}
          src={val.url}
          alt=""
          style={{ width: '100%', verticalAlign: 'top' }}
          onLoad={() => {
            window.dispatchEvent(new Event('resize'));
          }}
        />
      ))}
    </Carousel>
  )
}

export default HomeBanner;
