import React, {useState} from "react";
import {Button, InputItem, List, Modal, Toast} from 'antd-mobile';
import { dYConnectMan } from "@api/app";
import {getStorage} from "@utils/my_storage";
import './write_form.scss';

const LotteryInfo = ()=> {
  const [user_name, setUserName] = useState('');
  const [user_phone, setUserPhone] = useState('');

  const pushInfo = async ()=> {
    if (!user_name) return Toast.info('请输入姓名');
    if (!user_phone) return Toast.info('请输入手机号码');
    const res = await dYConnectMan({
      name: user_name,
      mobile: user_phone,
      dy_token: getStorage('dy_token')
    })
    Modal.alert('温馨提示', res.msg);
  }

  return (
    <div className={'lottery-info'}>
      <div className={'lottery-top'}></div>
      <div className={'lottery-main'}>
        <List renderHeader={() => '请填写信息'}>
          <InputItem
            type={'text'}
            placeholder={'请输入您的姓名'}
            value={user_name}
            onChange={(val)=> setUserName(val)}
          >
            姓名
          </InputItem>
          <InputItem
            type={'phone'}
            placeholder={'请输入您的手机号码'}
            value={user_phone}
            onChange={(val)=> setUserPhone(val)}
          >
            手机号
          </InputItem>
        </List>

        <Button
          style={{marginTop: '20px'}}
          type={'primary'}
          className={'get-btn'}
          onClick={()=> pushInfo()}
        >立即领取</Button>
      </div>
      <div className={'lottery-ad'}></div>
      <div className={'lottery-bot'}></div>
    </div>
  )
}

export default LotteryInfo;