import React from "react";
import { useNavigate } from 'react-router-dom';
import JiqYouZhan from '@assets/images/jiayouzhan.png';
import QIYe from '@assets/images/qiye.png';
import YunYing from '@assets/images/yunying.png';
import { WhatsAppOutlined } from "@ant-design/icons";
import './index.scss'

const cooArr = [
  {
    title: '加油站入驻',
    des: '新油站入驻, 提升交易量30%',
    img: JiqYouZhan,
    key: '0'
  },
  {
    title: '车队/企业合作',
    des: '物流车队, 企业及政府用车',
    img: QIYe,
    key: '1'
  },
  {
    title: '城市运营商',
    des: '申请当地城市的开发运营',
    img: YunYing,
    key: '2'
  }
]

const Cooperate = ()=> {

  const navigate = useNavigate();

  const changeParam = (index: string)=> {
    navigate(`/write_cooperate?type=${index}`)
  }

  return (
    <div className={'cooperate-con'}>
      <div className={'cooperate-title'}>选择合作内容</div>
      <div className={'cooperate-main'}>
        { cooArr.map((item, index) => {
          return (
            <div className={'cooperate-item'} key={index} onClick={()=> changeParam(item.key)}>
              <div className={'cooperate-left'}>
                <img alt={''} src={item.img}/>
              </div>
              <div className={'cooperate-right'}>
                <span>{item.title}</span>
                <span>{item.des}</span>
              </div>
            </div>
          )
        })}
      </div>
      <div className={'about-more'}>
        <a href="tel:18888217968" className='learn-more'>
          <span>了解更多合作，请联系客服</span>
          <span> <WhatsAppOutlined /> 18888217968</span>
        </a>
      </div>
    </div>
  )
}

export  default Cooperate;