import React, {FC} from 'react';
import mountain from '@assets/images/load-mountain.png';
import state from '@assets/images/load-state.png';
import car from '@assets/images/load-car.png';
import './loading_app.scss';

interface Loading {
  status: boolean,
  children?: React.ReactNode;
}

export const PageLoading: FC<Loading> = (props) => {
  const { status, children } = props;

  const sty_show = {
    display: "block",
  }

  const sty_hidden = {
    display: "none",
  }


  const loadingView = () => {
    return (
      <div className='loading_con' style={status?sty_show:sty_hidden}>
        <div className='loadPage-anim'>
          <div className='flexDiv moyntainAni'>
            <img alt='' className='loadPage_mountain' src={mountain} />
            <img alt='' className='loadPage_mountain' src={mountain} />
          </div>
          <img alt='' src={state} className='loading_state' />
          <img alt='' src={car} className='loading_car' />
        </div>
      </div>
    )
  }

  return(
    <div>{status? loadingView():children}</div>
  )
}
