import React from 'react';
import './index.scss';


interface PasswordInfo {
  pwd: string[]
}

const sty = {
  visibility: "hidden"
}

const sty_1 = {}

const PasswordInput: React.FC<PasswordInfo> = (props)=> {
  const { pwd } = props

  return (
    <div className='pwd_con'>
      {
        pwd.map((item,index) => {
          return (
            <div className='pwd_item' key={index}>
              <span className='pwd_i' style={item === "" ? sty:sty_1}/>
            </div>
          )
        })
      }
    </div>
  )
}

export default PasswordInput;
