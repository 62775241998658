import Tools from './tools';

export const setUserToken = (val: any)=> {
  setStorage('app_token', val);
}

export const getUserToken = ()=> {
  return getStorageParam('app_token');
}

export const getUserAppId = ()=> {
  if (getStorageParam('app_id')) return getStorageParam('app_id');
  else return getStorageParam('appid');
}

export const getStorageParam = (str: string)=> {
  const urlParam = Tools.param2Obj();
  if (urlParam.hasOwnProperty(str)) {
    return urlParam[str];
  }
  return getStorage(str);
}

export const setStorage =(key: string, val: any)=> {
  sessionStorage.setItem(key, val)
}

export const getStorage =(key: string): any => {
  return sessionStorage.getItem(key);
}

export const clearStorage =()=> {
  sessionStorage.clear();
}

export const removeStorage =(key: string)=> {
  sessionStorage.removeItem(key);
}

