import {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import PayUtils from "./pay_utils";
import {useMount} from "ahooks";
import {getAppPageConfig} from "@api/app";
import {Toast} from "antd-mobile";

export interface WxPayState {
  appId: string;
  nonceStr: string;
  paySign: string;
  prepay_id: string;
  timeStamp: string;
  signType: string;
  ordersn: string;
  [key: string]: any;
}

/**
 * 计时器 hooks
 * @param callback
 * @param delay
 */
const useInterval = (callback: any, delay: number) => {
  const savedCallback: any = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

/**
 * 微信支付 hooks
 */
const useWxPay = ()=> {
  const navigate = useNavigate();
  return {
    pay: (data: WxPayState)=> {
      PayUtils.wxPay(data).then(res => {
        if (res) navigate(res.url);
      })
    }
  }
}

/**
 * 功能开关
 * @param btn_name
 */
export const usePageConfig = (btn_name: string)=> {
  const [status, setStatus] = useState(false);

  useMount(()=> {
    isShowAddOil()
  })

  const isShowAddOil = async ()=> {
    const res = await getAppPageConfig(btn_name);
    if (res.data.status === 1) setStatus(true);
    else setStatus(false);
  }

  return status
}

/***
 * loading url
 */

const useRequest = ()=> {
  const [status, setStatus] = useState(false);
  const [error, setError] = useState<any>();

  const run = useCallback(async (...funs)=> {
    setStatus(true);
    try {
      await Promise.all(funs.map(fn => {
        if (typeof fn === 'function') {
          return fn();
        }
        return fn;
      }))
    } catch (e) {
      setError(e)
    } finally {
      setStatus(false)
    }
  }, [])

  return {run, status, error}
}

/**
 * 上传图片组件
 */
const useUploadImage = (file: any)=> {

  if (!file) return Toast.info('请选择上传图片');

  // 校验图片大小
  const checkImage = ()=> {
    console.log('axx')
  }

  // 上传图片
  const upload = ()=> {
    console.log('上传')
  }

  return { checkImage, upload }
}

/**
 * 抖音SDK
 */
// const useDySdk = ()=> {
//
// }

export { useInterval, useWxPay, useRequest, useUploadImage };
