import wx from 'weixin-js-sdk-ts';
type PayEnv = 'wx' | 'ali' | 'h5';

class Tools {
  // 是否是微信环境
  isWeixin(): boolean {
    const ua: any = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) === "micromessenger";
  }
  // 支付环境判断
  getSystemPayEnv(): PayEnv {
    let payEnv: PayEnv;
    if (/MicroMessenger/.test(window.navigator.userAgent)) {
      payEnv = 'wx';
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      payEnv = 'ali';
    } else {
      payEnv = 'h5';
    }
    return payEnv;
  }
  // 解析地址栏参数
  param2Obj(url: string = window.location.search) {
    let theRequest: any = {};
    if (url.indexOf("?") !== -1) {
      let str = url.substr(1)
      let str_2 = str.split("&")
      for (let i = 0; i < str_2.length; i++) {
        theRequest[str_2[i].split("=")[0]] = str_2[i].split("=")[1]
      }
    }
    return theRequest;
  }
  // 存储session缓存
  initUserInfo = (param: any) => {
    try {
      if (typeof(param) === "string") {
        param = this.param2Obj(window.location.search);
      }
      const keysArr = Object.keys(param);
      if (keysArr.length > 0) {
        keysArr.forEach((item) => {
          if (param[item] !== undefined && param[item] !== null) {
            return sessionStorage.setItem(item, param[item]);
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  changeDisDate(obj: any) {
    let arr = [];
    if (obj instanceof Array) return [];
    if (Object.keys(obj).length>0) {
      obj = JSON.parse(JSON.stringify(obj));
      for (let i in obj) {
        let item: any = obj[i];
        item['dis_type'] = i;
        switch (i) {
          case 'point':
            item.name = "积分抵扣";
            item.icon = 'iconjifen1';
            break;
          case 'discount':
            item.name = "会员优惠";
            item.icon = 'iconyouhui';
            break;
          case 'coupon':
            item.name = "优惠券";
            item.icon = 'iconyouhuiquan'
            break;
        }
        arr.push(item);
      }
      return arr;
    }
  }

  // 校验金额
  checkMoneyStr(val: string): boolean {
    const MaxMoney = 99999.99
    const str = val.toString()
    // 金额不能为0
    if (!Number(str)) return false
    // 首位只有一个 0
    if (str.substr(0, 2) === '00') return false
    // 首位禁止小数
    if (str.substring(0, 1) === '.') return false
    // 小数点
    if (str.indexOf('.') > -1) {
      // 禁止两个小数点输入
      if (str.split('.').length > 2) return false
      // 小数后最多输入两位
      if (str.split('.')[1].length > 2) return false
    }
    // 最大限额
    if (Number(str) > MaxMoney) return false
    return true
  }

  // 获取所有优惠
  getAllDisInfoStatus =(param: any)=> {
    let obj: {[key: string]: any} = {};
    Object.keys(param).forEach(item => {
      if (param[item] instanceof Object) {
        Object.keys(param[item]).forEach(child => {
          obj[child] = param[item][child].checked ? 1 : 0;
        })
      }
    })
    return obj;
  }
  // 手机短振动
  vibrateShort() {
    if ('vibrate' in window.navigator) {
      window.navigator.vibrate(100);
    }
  }
  // 判断是否抖音环境
  isDouYin() {
    const userAgentStr = window.navigator.userAgent;
    if(/aweme/.test(userAgentStr)) {
      return true;
    }
    return false;
  }
  // 转换boolean
  changeBool (obj: {[key: string]: any}, arr: any[]) {
    let items = Object.assign({}, obj);
    for (let i in arr) {
      if (Object.keys(items).indexOf(arr[i]) > -1) {
        items[arr[i]] = items[arr[i]] === 1 ? true:false
      }
    }
    return items;
  }
  isShowMini() {
    return new Promise((resolve => {
      wx.miniProgram.getEnv((res: any)=> {
        console.log(res.miniprogram)
        resolve(!res.miniprogram)
      })
    }))
  }
  // 是否在微信小程序中
  isWeiXinWebView() {
    if (this.getSystemPayEnv() === 'wx') {
      return !/miniProgram/i.test(navigator.userAgent.toLowerCase())
    } return false
  }

  isArrayFn(value: any) {
    return Object.prototype.toString.call(value) === "[object Array]";
  }

}

export default new Tools()

