import {useState} from "react";
import {getOrganizeTeam, getUserOrganizeTeam, setPartOrganizeTeam, setPrizeOrganizeTeam} from '@api/rewards';
import {useDispatch, useSelector} from "react-redux";
import {activityInfo, activitySlice} from "@store/activity.slice";
import {Toast} from "antd-mobile";
import Tools from "@utils/tools";
import {enjoyFriend} from "@utils/sdk/wexin";
import {webUrl} from "@utils/http";
import {getStorageParam} from "@utils/my_storage";

interface TeamDetailState {
  id: number;
  end_time: number;
  draw_num: number;
  team_num: number;
  team_num_now: number;
}

interface TeamInfo {
  id: number;
  prize_id: number;
  member_id: number;
  team_num: number,
  team_num_now: number;
  draw_num: number;
  end_time: string;
  status: 0 | 1;
  create_time: string;
  update_time: string;
  delete_time: string;
}

/**微信分享组队**/

 export const useWeiXinTeam =()=> {

  const info = useSelector(activityInfo);

  const {activity} = info;

  const [prize_group_id, setPrizeGroupId] = useState('');

  // true 继续组队; false 立即组队
  const [hasTeam, setHasTeam] = useState(false);
  // 是否组队完成
  const [isTeamUpStatus, setIsTeamUpStatus] = useState(false);

  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();
  const {setIsTeamShow} = activitySlice.actions;

  // 当前组队信息
  const [nowTeamInfo, setTeamInfo] = useState<TeamInfo>({
    create_time: "",
    delete_time: "",
    draw_num: 0,
    end_time: "",
    id: 0,
    member_id: 0,
    prize_id: 0,
    status: 0,
    team_num: 0,
    team_num_now: 0,
    update_time: ""
  });

  const [teamInfo, serTeamInfo] = useState<TeamDetailState>({
    draw_num: 0,
    end_time: 0,
    id: 0,
    team_num: 0,
    team_num_now: 0
  })

  // useUpdateEffect(()=> {
  //   all_info();
  // }, [info.activity.id])

  // 获取用户所有组队信息
  const all_info = async ()=> {
    if (info.activity.status === 0) return;
    const res = await getUserOrganizeTeam(info.activity.id);
    if (res.code === 200 && res.data.length>0) {
      await setTeamInfo(res.data[0]);
      await getTeamDetail(res.data[0].id);
    }
  }

  // 队长立即组队
  const teamUp = async ()=> {
    const res = await setPrizeOrganizeTeam(activity.id);
    Toast.info(res.msg);
    if (res.code === 200) {
      await getTeamDetail(res.data.prize_group_id);
      await disPatch(setIsTeamShow(true));
      enjoyFriend({
        title: activity.team_title,
        desc: activity.team_statement,
        link: `${webUrl}/reward?app_id=${getStorageParam('app_id')}&player=${res.data.prize_group_id}`,
        imgUrl: activity.team_pic_url
      });
      return res.data.prize_group_id;
    }
    setPrizeGroupId(res.data.prize_group_id);
    setHasTeam(true);
    return '';
  }

  // 队员参与组队
  const playerTeam = async ()=> {
    const param = Tools.param2Obj();
    const res = await setPartOrganizeTeam(param.player);
    Toast.info(res.msg);
    await getTeamDetail(param.player);
  }

  // 获取组队详情
  const getTeamDetail = async (id: number)=> {
    try {
      const res = await getOrganizeTeam(id);
      if (res.code === 200) {
        serTeamInfo(res.data);
        // 组队完成
        if (res.data.status === 1) {
          // Modal.alert('温馨提示', `您已经组队成功,快去抽奖吧`, [
          //   { text: '我知道了'}
          // ])
          setIsTeamUpStatus(true)
        } else {
          setHasTeam(true);
        }
        return res.data.team_num - res.data.team_num_now;
      }
    } catch (e) {
      console.log(e)
    }
  }

  return { hasTeam, nowTeamInfo, teamUp, playerTeam, teamInfo, getTeamDetail, all_info, isTeamUpStatus, prize_group_id };
 }