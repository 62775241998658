import React, {FC, useEffect, useRef} from "react";
import './loading_app.scss';

interface MaskStatus {
  status: boolean;
}

const AppMaskBox: FC<MaskStatus> = (props)=> {
  const ref: any = useRef(null);

  const {status} = props;

  useEffect(()=> {
    stopBodyScroll(props.status);
  })

  const stopBodyScroll =(isFixed: boolean)=> {
    let bodyEl = document.body
    let top = 0;
    if (isFixed) {
      top = window.scrollY
      bodyEl.style.position = 'fixed'
      bodyEl.style.top = -top + 'px'
    } else {
      bodyEl.style.position = ''
      bodyEl.style.top = ''
      window.scrollTo(0, top) // 回到原先的top
    }
  }

  return (
    <div ref={ref} className={'mask-box'} style={status ? {display: 'block'}:{display: 'none'}}>
      {props.children}
    </div>
  )
}

export default AppMaskBox;