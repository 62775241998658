import {createSlice} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "store/index";
import {UserData} from "@view/login";
import {Toast} from "antd-mobile";
import {setStorage, setUserToken, getUserToken, getStorageParam} from "@utils/my_storage";
import {
  aliUserLogin,
  aliUserRegister,
  getWxUserCodeInfo,
  UserLoginH5,
  wxUserLoginAuthUrl,
  WxCodeInfo,
  douYinLoginInfo, DyRegister, getAppUserInfo
} from "@api/user";
import { getAliAuthCode } from "@utils/sdk/ali_pay";
import Tools from "@utils/tools";
import {getOilInfo} from "@api/order";
import { getDouYinAuthUrl } from "@api/app";

const apiUrl = process.env.REACT_APP_API_URL;

export interface AuthState {
  user_info: any | null;
  ali_token: string;
  isLoginStatus: boolean;
  user_pay_info: {
    cate: any[];
    cate_id: number;
    emp_id: number;
    member: number;
    qrcode_id: number;
    room_area: string;
    room_id: number;
    room_image: string;
    room_name: string;
    show_cate: number;
    store_id: number;
    take_card: number
    title: string;
    uid: number;
  },
  checkCarType: {
    car_type_id: number;
    name: string;
    url: string;
    mobile: number;
    verification_code: number;
  }
}

const initialState: AuthState = {
  user_info: {
    mobile: "",
    is_balance_confidential_payment: 0,
  },
  checkCarType: {
    car_type_id: 0,
    name: '',
    url: '',
    mobile: 0,
    verification_code: 0,
  },
  user_pay_info: {
    cate: [],
    cate_id: 0,
    emp_id: 0,
    member: 0,
    qrcode_id: 0,
    room_area: '',
    room_id: 0,
    room_image: '',
    room_name: '',
    show_cate: 0,
    store_id: 0,
    take_card: 0,
    title: '',
    uid: 0,
  },
  ali_token: "",
  isLoginStatus: Tools.getSystemPayEnv() === "h5" ? true:false
};

const token = getUserToken();

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user_info = action.payload;
    },
    setLoginStatus(state, action) {
      state.isLoginStatus = action.payload;
    },
    setAliToken(state, action) {
      state.ali_token = action.payload;
    },
    setUserPayInfo(state, action) {
      state.user_pay_info = action.payload;
    },
    setCheckCarType(state, action) {
      state.checkCarType = action.payload;
    }
  },
});

const {setUser, setLoginStatus, setAliToken, setUserPayInfo } = authSlice.actions;

export const userAppInfo = (state: RootState) => state.auth;

export const setAppUserInfo = ()=> async (dispatch: AppDispatch) => {
  try {
    const res = await getAppUserInfo();
    if (res.code === 200) dispatch(setUser(res.data));
  } catch (e) {
    console.log(e)
  }
}

export const setUserPayCon =  (url: string) => async (dispatch: AppDispatch) => {
  try {
    let data = {
      qrcode_id: getStorageParam('qrcode_id'),
      room_id: getStorageParam('room_id'),
      app_id: getStorageParam('app_id'),
      store_id: 0
    }
    Object.keys(data).forEach(item => {
      // @ts-ignore
      if(!data[item]) {
        // @ts-ignore
        delete data[item]
      }
    });
    const res = await getOilInfo(data);
    if (res.code === 200) {
      dispatch(setUserPayInfo(res.data));
      setStorage('room_id', res.data.room_id);
    }
  } catch (e) {
    console.log(e)
  }
}

// H5登录
export const userLoginWeb = ({mobile, check_code}: UserData) => async (dispatch: AppDispatch) => {
  if (!mobile) return Toast.fail('请检查手机号');
  if (!check_code) return Toast.fail('请输入验证码');
  let user;
  if (Tools.isDouYin()) {
    user = await DyRegister({
      mobile,
      check_code,
      dy_token: getStorageParam('dy_token'),
      dy_active: getStorageParam('dy_active')
    })
  } else {
    user = await UserLoginH5({mobile,check_code})
  }
  if (user.code === 200) {
    dispatch(setUser(user.data))
    dispatch(setLoginStatus(true))
    setUserToken(user.data.app_token);
  } else {
    Toast.fail(user.msg);
    dispatch(setLoginStatus(false))
  }
};

// 检查H5登录状态
export const checkH5Token = () =>(dispath: AppDispatch) => {
  if (token) {
    dispath(setLoginStatus(true))
  } else {
    dispath(setLoginStatus(false));
  }
}

// 支付宝环境登录
export const aliUserLoginApp = () => async(dispath: AppDispatch) => {
  const auth_code = await getAliAuthCode();
  if (auth_code) {
    const info = await aliUserLogin(auth_code);
    if (info.code === 200) {
      const { app_token } = info.data;
      setUserToken(app_token);
      dispath(setLoginStatus(true));
    } else {
      const { ali_token } = info.data;
      dispath(setAliToken(ali_token));
      dispath(setLoginStatus(false));
    }
  }
}

// 支付宝扫码注册
export const goUserLoginByAliToken = ({mobile, check_code}: UserData, ali_token: string) =>
  async(dispath: AppDispatch) => {
    try {
      if (!mobile) return Toast.fail('请检查手机号');
      if (!check_code) return Toast.fail('请输入验证码');
      const res = await aliUserRegister({mobile, check_code}, ali_token);
      if (res.code === 200) {
        dispath(setUser(res.data));
        dispath(setLoginStatus(true));
        setUserToken(res.data.app_token);
        return true;
      }
      return false;
    } catch (e) {
      console.log(e)
    }
}

// 微信环境跳转链接登录
export const userWxLogin = ()=> async(disPath:AppDispatch) => {
  try {
    const redirectUrl = window.location.href;
    const obj = Tools.param2Obj();
    let app_id = obj.app_id;
    if (!app_id) app_id = obj.appid;
    const wxUrl = `${apiUrl}/wechat/user.login/snsapi_base?app_id=${app_id}&callback=${redirectUrl}`;
    if (obj.app_token) {
      return disPath(setLoginStatus(true));
    }
    // 跳转wx login
    window.location.href = encodeURI(wxUrl);
  } catch (e) {
    console.log(e);
  }
}

// 微信登录业务
export const getUserInfoByCode = ()=> async(disPath:AppDispatch) => {
  try {
    // const redirectUrl = window.location.href;
    const obj = Tools.param2Obj();
    if(obj.hasOwnProperty('code')) {
      await disPath(getUserInfo(obj));
    } else {
      await disPath(getGoWxUrl());
    }
  } catch (e) {
    console.log(e);
  }
}

// 获取微信授权链接
const getGoWxUrl = ()=> async(disPath:AppDispatch) => {
  try {
    const redirectUrl = window.location.href;
    const obj = Tools.param2Obj();
    const res = await wxUserLoginAuthUrl(obj.app_id, redirectUrl);
    if (res.code === 200) {
      window.location.href = res.data.url;
    }
  } catch (e) {
    console.log(e);
  }
}

// 微信登录
const getUserInfo = (obj: WxCodeInfo) => async(disPath:AppDispatch)=> {
  try {
    const res = await getWxUserCodeInfo(obj);
    if (res.code === 200) {
      Tools.initUserInfo(res.data);
      disPath(setLoginStatus(true));
    } else {
      if (res.code === 407) return window.location.href = res.data.url;
      if (res.code === 400 || res.code === 405) return Toast.info('请重新扫码');
    }
  } catch (e) {
    console.log(e)
  }
}

// 抖音登录
export const douYinLogin = () => async(disPath: AppDispatch)=> {
  try {
    const urlParam = Tools.param2Obj();
    if (urlParam.hasOwnProperty('app_token')) {
      return disPath(setLoginStatus(true));
    }
    if (urlParam.hasOwnProperty('dy_token') && urlParam.hasOwnProperty('dy_active')) {
      return disPath(setLoginStatus(false));
    }
    if (urlParam.hasOwnProperty('code')) {
      let info: any = await douYinLoginInfo(urlParam.code);
      if (info.code === 200) {
        const {app_token} = info.data;
        setUserToken(app_token);
        disPath(setLoginStatus(true));
      } else if (info.code === 406) {
        disPath(setLoginStatus(false));
        return Toast.info(info.msg);
      } else if (info.code === 501) {
        disPath(setLoginStatus(false));
        return Toast.info(info.msg);
      }
    } else {
      const res = await getDouYinAuthUrl();
      if (res.code === 200) {
        window.location.href = res.data.url;
      }
    }
  } catch (e) {
    console.log(e)
  }
}
