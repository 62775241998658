import {configureStore} from "@reduxjs/toolkit";
import {authSlice} from "@store/auth.slice";
import {paySlice} from "@store/pay.slice";
import {couponSlice} from "@components/Coupon/coupon.slice";
import { passwordSlice } from "@components/BalancePay/balance.slice";
import { activitySlice } from "@store/activity.slice";


export const rootReducer = {
  auth: authSlice.reducer,
  payInfo: paySlice.reducer,
  couponInfo: couponSlice.reducer,
  passwordInfo: passwordSlice.reducer,
  activityInfo: activitySlice.reducer,
};

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
