import { useInterval } from "@utils/app_hooks";
import React, {FC, useState} from "react";
import {Button, Toast} from "antd-mobile";
import { sendCheckCode } from "@api/app";
import '@view/login/index.scss';

interface SendCodeState {
  phone: string;
  type?: 'default'|'none'
}

// 获取验证码按钮
export const SendCheckCode: FC<SendCodeState> = (props) => {
  const [count, setCount] = useState(60);
  let [code_status, setStatus] = useState(true);

  const change_status = async(status: boolean) => {
    try {
      const { phone } = props;
      if (!phone) return Toast.info("请输入手机号");
      if (!code_status) return;
      const res = await sendCheckCode(phone);
      if (res.code === 200) {
        setStatus(code_status = status);
      }
      Toast.info(res.msg);
      return true;
    } catch (e) {
      console.log(e)
    }
  }

  useInterval(() => {
    if (!code_status) {
      if (count === 1) {
        setCount(60);
        setStatus(true);
        return;
      }
      setCount(count - 1);
    }
  }, 1000);

  if (props.type === 'none') {
    return code_status ? <span onClick={()=> change_status(false)}>获取验证码</span>:<span>count+'s后重试'</span>
  }

  return (
    <Button className='send-code' onClick={()=> change_status(false)}>
      { code_status ? '获取验证码':count+'s后重试'}
    </Button>
  );
}