import React, {FC, useState} from "react";
import {MyIcon} from "@components/AppIcon";
import CountDown from "@components/CountDown";

import './index.scss';


interface TeamDetailState {
  id: number;
  end_time: number;
  draw_num: number;
  team_num: number;
  team_num_now: number;
}

interface TeamState {
  teamInfo: TeamDetailState;
  closeTeamDetail: ()=> void;
  isTeamUpStatus: boolean;
}

const TeamUpPerson:FC<TeamState> = (props)=> {
  const [enjoyBtnStatus, setEnjoyBtnStatus] = useState(true);
  const {closeTeamDetail, isTeamUpStatus} = props;
  const {team_num, draw_num, end_time, team_num_now} = props.teamInfo;
  if (end_time === 0) return <></>
  const status = team_num - team_num_now > 0 ? true:false;

  const closeModal = ()=> {
    closeTeamDetail();
    setEnjoyBtnStatus(true)
  }

  // 配置转发信息
  const setOfficialInfo = ()=> {
    setEnjoyBtnStatus(false)
  }

  const EnjoyBtn = ()=> {
    if (isTeamUpStatus) return <div className={'team-com-friend-un'}>组队完成</div>;
    if (!enjoyBtnStatus) {
      if (team_num-team_num_now>0) {
        return (
          <div className={'team-com-friend-un'}>点击右上角分享给好友</div>
        )
      }
    }
    return <div className={'team-com-friend'} onClick={()=> setOfficialInfo()}>邀请好友组队</div>
  }

  const unTeam = ()=> {
    return (
      <>
        <div className={'team-com-people'}>
          <span>还差 <span className={'pub-color'}>{team_num-team_num_now}</span> 人组队成功</span>
        </div>
        <div className={'team-com-info'}>
          <span>若当天未完成组队，当前队伍将失效，有效时间还剩 <CountDown timeStamp={end_time}/> ，次日可重新组队</span>
        </div>
        <EnjoyBtn/>
        <div className={'team-com-span'}>
          <span> 完成组队后所有队员增加 <span className={'pub-color'}>{draw_num}</span> 次抽奖机会 </span>
        </div>
      </>
    )
  }

  const carryOutTeam = ()=> {
    return (
      <>
        <div className={'team-com-info'}>
          <span>组队成功, 每人增加 <span className={'pub-color'}>{draw_num}</span> 次抽奖机会，有效次日可重新组队</span>
        </div>
        <EnjoyBtn/>
      </>
    )
  }


  return (
    <div className={'team-con'}>
      <MyIcon
        type={'icondel'}
        className={'close-icon'}
        onClick={()=>closeModal()}
      />
      <div className={'team-com-title'}>组队详情</div>
      <div className={'team-com-img'}>恭喜您,组队成功!</div>
      {status ? unTeam():carryOutTeam()}

    </div>
  )

}

export default TeamUpPerson;