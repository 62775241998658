import React, { FC } from "react";
import { MyIcon } from "@components/AppIcon";
import { Result, Icon } from "antd-mobile";
import Tools from "@utils/tools";
import './index.scss';

export interface PayResultProps {
  money: number;
  amount: number;
  status: string | null;
  ordersn?: string;
  name?: string;
  pay_discount?: number;
  dec_point_money?:number;
  dec_discount_money?:number;
  dec_coupon_money?: number;
  take_point?:number;
  create_time?: string;
  point?: number;
  isBalance: boolean;
  platform_discount?: number;
}


const PayResult: FC<PayResultProps> = (props)=> {
  const env = Tools.getSystemPayEnv();
  const {status, money, amount, isBalance} = props;

  const successIcon = ()=> {
    if (env === "wx") {
      return <MyIcon className={'icon-success'} type={'iconweixin1'}/>;
    } else {
      if (isBalance) {
        return <Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />;
      }
      return <MyIcon className={'icon-success'} type={'iconzhifubao'}/>
    }
  }
  if (status === 'success') {
    return (
      <Result
        img={successIcon()}
        title="支付成功"
        message={<div>{amount}元 <del>{money}元</del></div>}
      />
    )
  }
  return (
    <Result
      img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
      title="支付失败"
      message={<div>{amount}元 <del>{money}</del></div>}
    />
  )
}

export default PayResult;