import qs from "qs";
import {getStorage, getUserToken} from "./my_storage";
import {useState} from "react";
import { WxPayState } from "./app_hooks";
declare let WeixinJSBridge: any

class PayUtils {

  ready(callback: any) {
    if ((window as any).AlipayJSBridge) {
      callback && callback();
    } else {
      document.addEventListener('AlipayJSBridgeReady', callback, false);
    }
  }

  // 支付宝支付
  aliPay(tradeNO: string) {
    return new Promise((resolve, reject) => {
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        (window as any).AlipayJSBridge.call("tradePay", {
          tradeNO: tradeNO
        }, function (data: any) {
          if ("9000" === data.resultCode) {
            resolve(true)
          } else {
            reject(false)
          }
        });
      });
    })
  }

  // 微信支付
  async wxPay(res: any) {
    try {
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
          // @ts-ignore
          document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady, false);
        } else if ((document as any).attachEvent) {
          (document as any).attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          (document as any).attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        return await this.onBridgeReady(res);
      }
    } catch (e) {
      console.log(e)
    }
  }

  // 服务商版本支付
  onBridgeReady(data: WxPayState): Promise<{status: boolean, url: string}> {
    return new Promise(((resolve) => {
      const { appId, nonceStr, paySign, prepay_id, timeStamp, signType } = data;
      (WeixinJSBridge as any).invoke(
        "getBrandWCPayRequest",
        {
          "appId": appId,     //公众号名称，由商户传入
          "timeStamp": timeStamp, //时间戳，自1970年以来的秒数
          "nonceStr": nonceStr, //随机串
          "package": `prepay_id=${prepay_id}`,
          "signType": signType, //微信签名方式：
          "paySign": paySign //微信签名
        },
        function(res: any) {
          data.app_token = getUserToken();
          data.app_id = getStorage('app_id');
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示
            data.status = 'success';
            resolve({
              status: true,
              url: `/payresult?${qs.stringify(data)}`
            })
          } else {
            data.status = 'fail';
            resolve({
              status: false,
              url: `/payresult?${qs.stringify(data)}`
            })
          }
        }
      );
    }))
  }
}

export const useArray = <T>(initArr: T[])=> {
  const [value, setValue] = useState(initArr);
  return {
    add: (item: T)=> setValue([...value, item]),
    clear: ()=> setValue([]),
    delete: (index: number)=> {
      const copy = [...value];
      copy.splice(index, 1);
      setValue(copy);
    }
  }
}

export default new PayUtils()
