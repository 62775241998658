import HttpServer from "@utils/http";

interface AddVideo {
  dy_token: string;
  scene: "activtiy" | "cardpay" | "lottery"; // 场景 activtiy活动 cardpay支付 lottery抽奖
  id: string;
  video_id?: string;
}

// 发送验证码
export const sendCheckCode = (mobile: string) => {
  return HttpServer.post({
    url: "user/user.index/send_code",
    params: {
      mobile: mobile.replace(/\s*/g, ""),
    },
  });
};

// 验证手机验证码
export const verifyMobile = (code: string) => {
  return HttpServer.post({
    url: "client/verify_code/verify_mobile",
    params: {
      verification_code: code,
    },
  });
};

// 配置快速输入金额
export const initMoneySetting = (app_id?: string) => {
  return HttpServer.post({
    url: "client/order/show_setting",
    params: {
      app_id,
    },
  });
};

// 获取抖音授权链接
export const getDouYinAuthUrl = () => {
  return HttpServer.post({
    url: "client/douyin.login/auth_url",
    params: {
      redirect_uri: window.location.href,
    },
  });
};

// 发布抖音视频
export const addVideo = (param: AddVideo) => {
  return HttpServer.post({ url: "client/douyin.video/video", params: param });
};

interface ConnectMan {
  name: string;
  mobile: string;
  dy_token: string;
}

// 抖音引流
export const dYConnectMan = (param: ConnectMan) => {
  return HttpServer.post({
    url: "client/douyin.connect_man/add",
    params: {
      name: param.name,
      mobile: param.mobile.replace(/\s*/g, ""),
      dy_token: param.dy_token,
    },
  });
};

// 平台卡列表
export const getManageCardList = (goods_id: number, cate_id: number) => {
  return HttpServer.post({
    url: "client/card/card_list",
    params: {
      goods_id,
      cate_id,
    },
  });
};

// 获取微信配置信息
export const getWeiXinConfig = (
  url: string,
  apis: string,
  debug: boolean = false
) => {
  return HttpServer.post({
    url: "/wechat/user.jssdk/index",
    params: {
      url,
      apis: apis,
      debug: debug,
    },
  });
};

// 功能开关
export const getAppPageConfig = (button_name: string) => {
  return HttpServer.post({
    url: "/api/tool/get_button",
    params: { button_name },
  });
};

// 上传图片
export const uploadImageFile = (image: any, file_group = 4) => {
  return HttpServer.post({
    url: "/shop/user.upload/uploads",
    params: {
      image,
      file_group,
    },
  });
};

// 获取省市区规划
export const getAreaList = (area_id?: number) => {
  return HttpServer.post({
    url: "/shop/user.area/area_list",
    params: {
      area_id,
    },
  });
};

interface CooperationCity {
  name?: string;
  num_type?: string;
  area_id: number;
  area: number;
  address: string;
  linker: string;
  mobile: string;
  content: string;
}

// 城市运营商合作
export const setCooperationCity = (params: CooperationCity) => {
  return HttpServer.post({
    url: "client/cooperation/urban_operators_apply",
    params,
  });
};

// 加油站合作
export const setCooperationOil = (params: CooperationCity) => {
  return HttpServer.post({
    url: "client/cooperation/gas_station_apply",
    params,
  });
};

// 市场合作
export const setCooperationMarket = (params: CooperationCity) => {
  return HttpServer.post({ url: "client/cooperation/market_apply", params });
};

// 是否关注二维码
export const isSubcribeStatus = (qrcode_id: string, token: string) => {
  return HttpServer.post({
    url: "client/wechat.subcribe/subcribe_h5",
    params: {
      qrcode_id,
      token,
    },
  });
};

// 首页轮播图
export const getHomeBanner = () => {
  return HttpServer.post({ url: "/api/tool/ali_image", params: {} });
};

// 用户密码安全配置
export const userSelfStatus = (button_name: string) => {
  return HttpServer.post({ url: "/api/tool/safe_config", params: {
    button_name
  }});
};
