import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Index from "@view/home/index";
import Home from "@view/home/home";
import Person from "@view/person/index";
import Shop from "@view/store_shop/shop_view/index";
import ChooseOil from "@view/user_pay";
import UserPay from "@view/user_pay/user_pay";
import CheckPhone from "@components/ChangePassword/CheckPhone";
import Password from "@components/ChangePassword/Password";
import DyActivity from "@view/activity/index";
import PayResultOrder from "@view/pay_result";
import Lottery from "@view/activity/reward";
import NoActivity from "@view/activity/no_activity";
import LotteryInfo from "@view/activity/lottery/write_info";
import Reward from "@view/activity/reward";
import CouponList from "@view/person/coupon";
import RewArdDetAil from "@view/activity/reward/detail";
import UnPassword from "@view/user_pay/un_password/index";
import SpecialCar from "@view/person/special_car";
import CarTypeDetail from "@view/person/special_car/detail";
import UpdateSpecialCard from "@view/person/special_car/update";
import TimePay from "@view/user_pay/timePay";
import Cooperate from "@view/person/cooperate";
import CooperateInfo from "./person/cooperate/write_info";
import OilMap from "@view/oil_map";
import RechargePageItem from "./user_pay/recharge_pay";
import PayTextInfo from "@view/user_pay/zltc_info";

interface AppRouterProps {
  path?: string;
}

export const AppRouter = (props: AppRouterProps) => {
  return (
    <Routes>
      <Route path="cardpay" element={<ChooseOil/>} />
      <Route path="userPay" element={<UserPay/>} />
      <Route path="payresult" element={<PayResultOrder/>} />
      <Route path="checkPhone/:type" element={<CheckPhone/>} />
      <Route path="password/:type/:entry" element={<Password/>} />
      <Route path="activity" element={<DyActivity/>} />
      <Route path="lottery" element={<Lottery/>} />
      <Route path="noActivity" element={<NoActivity/>} />
      <Route path="add_lottery" element={<LotteryInfo/>} />
      <Route path="reward" element={<Reward/>} />
      <Route path="coupon" element={<CouponList/>} />
      <Route path="reward_detail/:id" element={<RewArdDetAil/>} />
      <Route path="un_password" element={<UnPassword/>} />
      <Route path="special_car" element={<SpecialCar/>} />
      <Route path="special_car_detail" element={<CarTypeDetail/>} />
      <Route path="special_car_update" element={<UpdateSpecialCard/>} />
      <Route path="time_pay" element={<TimePay/>} />
      <Route path="cooperate" element={<Cooperate/>} />
      <Route path="write_cooperate" element={<CooperateInfo/>} />
      <Route path="recharge_pay" element={<RechargePageItem/>} />
      <Route path="pay_text_info" element={<PayTextInfo/>} />
      <Route path='/' element={<Index />}>
        <Navigate replace={true} to={'/home'} />
        <Route path="home" element={<Home />} />
        <Route path="person" element={<Person />} />
        <Route path="shop" element={<Shop />} />
        <Route path="oilmap" element={<OilMap/>} />
      </Route>
    </Routes>
  )
}
