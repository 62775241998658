import React, {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {payAppInfo} from "@store/pay.slice";
import {UserCardItem} from "@view/user_pay/pay_store_interface";
import * as payStore from "@store/pay.slice";
import { cardDisItem } from "./card_tools";
import './index.scss';

const UserCardInfo:FC = ()=> {

  const payInfo = useSelector(payAppInfo);
  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();

  const { manageCard } = payInfo;

  const {
    setPayParam,
    setNowUsedManageCard,
    setUserCardModal,
    setUsedCardStatus
  } = payStore.paySlice.actions;

  const setNowManage = async (item: UserCardItem)=> {
    // if (nowManageCard.id === item.id) {
    //   await disPatch(setManageCardStates(false));
    //   await disPatch(setPayParam({ platform: 0}));
    //   return await disPatch(noUsedManageCard());
    // }
    await disPatch(setPayParam({ platform: 1, platform_card_data_id: item.platform_card_data_id}));
    await disPatch(setNowUsedManageCard(item));
    await disPatch(setUserCardModal(false));
    await disPatch(setUsedCardStatus(true));
  }

  // const cardTypeText = (type: number)=> {
  //   // ：1，按金额；2，按次数；3，按时间；
  //   if (type === 1) return '金额卡';
  //   if (type === 2) return '次数卡';
  //   if (type === 3) return '时间卡';
  // }

  // const cardType = (item: UserCardItem)=> {
  //   switch (item.card_type) {
  //     case 1:
  //       return (
  //         <div className={'user-card-item-right-top'}>
  //           <span>剩余金额: </span>
  //           <span>{item.remain_card_amount}元</span>
  //         </div>
  //       )
  //     case 2:
  //       return (
  //         <div className={'user-card-item-right-top'}>
  //           <span>剩余次数: </span>
  //           <span>{item.remain_card_num}次</span>
  //         </div>
  //       )
  //     case 3:
  //       return (
  //         <div className={'user-card-item-right-top'}>
  //           <span>到期时间: </span>
  //           <span>{item.card_expire}</span>
  //         </div>
  //       )
  //   }
  // }

  const cardDisInfo = (item: UserCardItem)=> {
    const arr = cardDisItem(item);
    return (
      <div className={'card-info-dis'}>
        {arr?.map((item, key) => <span key={key}>{item.dis_str}</span>)}
      </div>
    )
  }

  // const cardItem = (item: UserCardItem)=> {
  //   return (
  //     <div className={'user-card-all'}  key={item.id} onClick={()=> setNowManage(item)}>
  //       <div className={'user-card-item'}>
  //         <div className={'user-card-item-left'}>
  //           <span>{item.card_name}</span>
  //           {cardType(item)}
  //         </div>
  //         <div className={'user-card-item-right'}>
  //           <span>卡类型: {cardTypeText(item.card_type)}</span>
  //         </div>
  //       </div>
  //       {fullType(item)}
  //     </div>
  //   )
  // }

  const manageItem = (item: UserCardItem, index: number)=> {
    return (
      <div className={'user-card-all'}  key={index} onClick={()=> setNowManage(item)}>
        <div className={'user-card-item'}>
          <div className={'user-card-item-left'}>
            <span>{item.name}</span>
            {/*{cardType(item)}*/}
          </div>
          <div className={'user-card-item-right'}>
            <span style={{color: '#F05454'}}>￥{item.sale_amount}</span>
          </div>
        </div>
        <div className={'card-info-bot'}>
          {cardDisInfo(item)}
        </div>
      </div>
    )
  }

  return(
    <div className={'user-card-con'}>
      <div className={'user-card-main'}>
        {/*<div className={'user-card-title'}>可用平台卡</div>*/}
        {/*<div className={'user-card-list'}>*/}
        {/*  { userCardData.map(item => cardItem(item))  }*/}
        {/*</div>*/}
        <div className={'user-card-title'}>油站平台卡</div>
        <div className={'user-card-list'}>
          { manageCard.map((item, index) => manageItem(item, index))  }
        </div>
      </div>
    </div>
  )
}

export default UserCardInfo;