import React, {FC} from "react";
import {Tabs, Toast,} from 'antd-mobile';
import {ReachargeRule} from "@view/user_pay/pay_store_interface";
import {payAppInfo} from "@store/pay.slice";
import {userAppInfo} from "@store/auth.slice";
import {useDispatch, useSelector} from "react-redux";
import * as payStore from "@store/pay.slice";
import {useUpdateEffect} from "ahooks";
import Tools from '@utils/tools';

import './pay_type.scss';

interface PayUsedTypeProps {
  payMoney?: string|undefined;
}

const RechargePay: FC<PayUsedTypeProps> = (props) => {
  const payInfo = useSelector(payAppInfo);
  const userInfo = useSelector(userAppInfo)
  const {rechargeRuleIdx, nowRechargeRule: nowRule, rechargeInfoStatus, nowOilItem} = payInfo;
  const { setRechargeItem, setRechargeRuleIdx, setPayParam, setRechargeInfoStatus } = payStore.paySlice.actions

  const dispatch: (...args: unknown[]) => Promise<any> = useDispatch();

  const {payMoney} = props;

  useUpdateEffect(()=> {
    getRecharges()
  }, [nowOilItem.id])

  const getRecharges = ()=> {
    let obj = {
      cate_id: nowOilItem.id,
      room_id: userInfo.user_pay_info.room_id,
      money: Number(payMoney)
    };
    // if (obj.cate_id === 0) {
    //   obj.cate_id = JSON.parse(getStorage('oil-info')).id;
    //   obj.room_id = getStorage('room_id');
    // }
    dispatch(payStore.setRechargeInfo(obj));
  }

  const setRechargeIdx = async (index: number, item: ReachargeRule, is_multiple_status?: boolean) => {
    try {
      if (item.charge_money < Number(payMoney)) {
        return Toast.fail('充值金额不能低于支付金额');
      }
      await dispatch(setRechargeRuleIdx({item, index}));
      await dispatch(setRechargeInfoStatus(true))
      if (rechargeRuleIdx !== index) {
        let account_id = payInfo.rechargeArr[payInfo.rechargeIdx].account_id
        await dispatch(setPayParam({
          recharge_id: (payInfo.rechargeArr[payInfo.rechargeIdx].recharge_item.id).toString(),
          key: is_multiple_status ? item.key:index.toString(),
          account_id,
          money: payMoney,
          recharge_multiple: is_multiple_status ? 1:0
        }));
      } else {
        await dispatch(setPayParam({
          recharge_id: "",
          key: "",
          account_id: 0,
          money: payMoney
        }));
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateDiscount = (item: any)=> {
    if (item.hasOwnProperty('first_discount_max') && Number(item.first_discount_max )> 0) {
      return `最高减免${item.first_discount_max}元`
    }
    if (item.first_pay_discount === '0' || item.first_pay_discount === '100') return `享受免单优惠`
    else return `享受${10 - Number(item.first_pay_discount) / 10}折优惠`
  }

  const more_multiple_rule = ()=> {
    const multiple_rule = payInfo.rechargeArr[payInfo.rechargeIdx]?.recharge_item.multiple_rule
    if (multiple_rule?.first_discount === '1') {
      return updateDiscount(multiple_rule)
    } else {
      return '查看更多'
    }
  }

  const filterRechargeInfo = ()=> {
    let info_arr = [];
    if (nowRule?.point_status === '1') info_arr.push({text: `赠送${nowRule.point}积分`});
    if (nowRule?.coupon_status === '1') info_arr.push({text: `赠送${nowRule.coupon_data?.length}张优惠券`});
    if (nowRule?.money_status === '1') info_arr.push({text: `赠送${nowRule.money}元余额`});
    if (nowRule?.first_discount === '1') info_arr.push({text: `首单${updateDiscount(nowRule)}`});
    if (nowRule?.is_unify === '1') {
      let type = filterLiterArr(nowRule.liter_good_money)
      info_arr.push({child: type});
    }
    if (nowRule?.is_lock === '1') info_arr.push({text: `锁油价已开启`});
    if (nowRule?.good_status === '1') info_arr.push({text: `赠送${nowRule.good_name}`});
    return info_arr;
  }

  const filterLiterArr = (item: any)=> {
    let arr: any = [];
    if (item instanceof Array && item.length>0) {
      item.forEach(item => {
        if (item.itemArr.length > 0) {
          item.itemArr.forEach((keys: any) => {
            if (keys.money>0) {
              arr.push(` ${item.name} ${keys.name}每升减${keys.money}元;`)
            }
          })
        }
      })
    }
    return arr;
  }

  // 储值支付优惠信息
  const rechargeInfo = ()=> {
    const arr = filterRechargeInfo();
    if (arr.length === 0) return <></>;
    if ((nowRule.is_show === 1 && rechargeInfoStatus) || rechargeInfoStatus) {
      return (
        <div className={'recharge-info'}>
          {arr.map((item, index) => {
            if (item.child) return item.child.map((keys: string, idx: number) => <span key={idx}>{keys}</span>)
            return <span key={index}>{item.text}</span>
          })}
        </div>
      )
    }
  }

  // 倍数充值
  const multipleRule = ()=> {
    const rule_item = payInfo.rechargeArr[payInfo.rechargeIdx]?.recharge_item
    const multiple_rule = rule_item?.multiple_rule
    if (multiple_rule) {
      if (!Tools.isArrayFn(multiple_rule)) {
        return (
          <div
            onClick={() => setRechargeIdx(-1, multiple_rule, true)}
            className={-1 === payInfo.rechargeRuleIdx ? 'recharge-item-active' : 'recharge-item'}
          >
            <span>充{Number(multiple_rule.charge_money)}元</span>
            <span>{more_multiple_rule()}</span>
          </div>
        )
      }
    } else return <></>
  }

  // 普通充值
  const rechargeRule = () => {
    if (payInfo.rechargeArr.length > 0) {
      const rule_item = payInfo.rechargeArr[payInfo.rechargeIdx]?.recharge_item
      if (Tools.isArrayFn(rule_item)) return <></>
      if (rule_item?.rule.length > 0) {
        return payInfo.rechargeArr[payInfo.rechargeIdx]?.recharge_item.rule.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => setRechargeIdx(index, item)}
              className={index === payInfo.rechargeRuleIdx ? 'recharge-item-active' : 'recharge-item'}
            >
              <span>充{item.charge_money}元</span>
              <span>{item.money > 0 && `赠${item.money}元`}</span>
            </div>
          )
        })
      }
    }
  }

  // 是否展示储值支付
  const isShowRechargeInfo = ()=> {
    if (payInfo.rechargeArr.length === 0) return <></>;
    let arr = payInfo.rechargeArr;
    arr.filter(item => item.recharge_item.rule.length>0);
    if (arr.length>0) return rechargeTabs();
    return <></>;
  }

  const rechargeTabs = ()=> {
    return (
      <div>
        <Tabs
          tabs={payInfo.rechargeTabs}
          swipeable={false}
          initialPage={0}
          page={payInfo.rechargeIdx}
          onTabClick={(tab, index) => dispatch(setRechargeItem(index))}
        >
          {payInfo.rechargeArr.map((item, index) => {
            return (
              <div key={index} className={'recharge-list'}>
                {
                  Tools.isArrayFn(item.recharge_item) ? <div>暂无充值项...</div>:<>
                    {multipleRule()}
                    {rechargeRule()}
                  </>
                }
              </div>
            )
          })}
        </Tabs>
        {payInfo.openRechargeStatus && rechargeInfo()}
        {payInfo.openRechargeStatus && <div className={'recharge-text'}>
            <span>充值后自动买单,买单后余额{payInfo.rechargeStoreInfo.recharge_balance}元</span>
        </div>
        }
      </div>
    )
  }

  return isShowRechargeInfo();
}

export default RechargePay;