import wx from 'weixin-js-sdk-ts';
import { getWeiXinConfig } from '@api/app';
import {useMount} from "ahooks";

interface WeXinEnjoy {
  title: string; // 分享标题
  desc: string; // 分享描述
  link: string; // 分享链接
  imgUrl: string; // 分享图标
}

/**
 * 配置微信 sdk
 * @param api
 * @param debug
 */

export const useWeiXinApi = (api: string, debug: boolean)=> {
  const pageUrl = window.location.href;

  useMount(async ()=> {
    const res = await getWeiApiParam(pageUrl, api, debug);
    if (res.code === 200) {
      const { appId, nonceStr, timestamp, signature } = JSON.parse(res.data);
      wx.config({
        debug: debug,
        appId: appId,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        // @ts-ignore
        jsApiList: [api],
        openTagList: ['wx-open-launch-weapp'],
      });
      wx.ready(()=> {
        wx.checkJsApi({
          // @ts-ignore
          jsApiList: [api],
          success(res: { checkResult: { [p: string]: boolean }; errMsg: string }) {
            console.log('微信接口正常xxxx')
          }
        })
      })
    }
  })

  const getWeiApiParam = async (pageUrl: string, api: string, debug: boolean)=> {
    return await getWeiXinConfig(pageUrl,api,debug);
  }
}


// 微信分享给朋友
export const enjoyFriend = (param: WeXinEnjoy)=> {
  const {title, desc, link, imgUrl} = param;
  console.log(link)
  wx.updateAppMessageShareData({
    title,
    desc,
    link,
    imgUrl,
    // 用户确认分享后执行的回调函数
    success: ()=> {
      console.log('分享成功过xxx')
    },
    // 用户取消分享后执行的回调函数
    cancel: () => {
      console.log('分享失败xxx')
    }
  });
}

console.log(wx)