import qs from 'qs';
import axios from 'axios';
import {Toast} from "antd-mobile";
import {getUserToken, getUserAppId} from "@utils/my_storage";

interface HttpParam {
  url: string;
  method?: "POST" | "GET";
  headers?: any;
  query?: any;
  params?: any;
  requestType?: "JSON" | "FormData";
}

interface IObjectKeys {
  [key: string]: string | Blob;
}

export const apiUrl = process.env.REACT_APP_API_URL;
export const webUrl = process.env.REACT_APP_WX_URL;

class HttpServe {
  constructor(init_config = {}) {
    this.config = {
      ...this.config,
      ...init_config
    };
  }

  config = {
    BASE_URL: apiUrl,
    headers: {},
    timeOut: 50000,
    requestType: "JSON",
  };

  dataOperation = {
    JSON: {
      headers: {
        'Content-Type': 'application/json', 
      },
      formatting(params: IObjectKeys) {
        return JSON.stringify(params)
      }
    },
    FormData: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      formatting(params: IObjectKeys) {
        let _formData = new FormData();
        let app_id = getUserAppId();
        Object.keys(params).forEach(key => {
          _formData.append(key, params[key]);
        })
        if (!params.hasOwnProperty('app_id') && app_id) {
          _formData.append('app_id', app_id);
        }
        return _formData
      }
    }
  }

  private Ajax = axios.create({
    baseURL: this.config.BASE_URL,
    headers: this.config.headers,
    timeout: this.config.timeOut,
  });

  send(AjaxParam: HttpParam) {
    // 发送 ajax 请求
    const { BASE_URL } = this.config
    const { url, params, headers, method } = AjaxParam;
    return new Promise((resolve, reject) => {
      this.Ajax({ method, url: `${BASE_URL}/${url}`, data: params, headers }).then((res) => {
        switch (res.data.code) {
          case 400:
            Toast.info(res.data.msg);
            break;
          case 404:
            Toast.fail(res.data.msg);
            break;
          case 0:
            Toast.fail(res.data.msg);
            break;
        }
        resolve(res.data);
      }).catch((err) => {
        Toast.fail('服务器开小差了~');
        reject(err)
      })
    })
  }

  // 封装请求
  get(AjaxParam: HttpParam): Promise<any> {
    let { url, query, headers } = AjaxParam;
    if (!query.hasOwnProperty('app_id')) {
      query.app_id = getUserAppId();
    }
    headers = { ...headers, 'token': getUserToken() };
    return this.send({ url: `${url}?${qs.stringify(query)}`, headers, method: 'GET' })
  }

  post(AjaxParam: HttpParam): Promise<any> {
    const { url, params, headers } = AjaxParam;
    return this.send({
      url,
      params: this.dataOperation.FormData.formatting(params),
      headers: {
        ...this.dataOperation.FormData.headers,
        ...headers,
        'token': getUserToken()
      },
      method: 'POST' 
    })
  }
}

export default new HttpServe();