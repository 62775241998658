import React, {useRef, FC} from 'react';
import {Modal} from 'antd-mobile';
import {getAwardGoods, startAward} from '@api/rewards';
import {activitySlice} from '@store/activity.slice';
import {useMount, useSessionStorageState} from 'ahooks';
// @ts-ignore
import { LuckyWheel } from 'react-luck-draw';
import {useDispatch} from 'react-redux';
import Tools from "@utils/tools";
import {Toast} from "antd-mobile";
import {getStorage, setStorage} from "@utils/my_storage";
import {addVideo} from "@api/app";
import TopImg from '@assets/images/yuanpan.png';

const alert = Modal.alert;

const rewardData = {
  blocks: [
    { padding: '13px' },
    {
      padding: '20px',
      imgs: [{
        src: TopImg,
        width: '100%',
        rotate: true
      }]
    }
  ],
  buttons: [
    { radius: '50px', background: '#d64737' },
    { radius: '45px', background: '#fff' },
    { radius: '41px', background: '#f6c66f', pointer: true },
    {
      radius: '35px', background: '#ffdea0',
      fonts: [{ text: '开始\n抽奖', fontSize: '18px', top: -18 }]
    }
  ],
  defaultStyle: {
    fontColor: '#d64737',
    fontSize: '18px',
  },
  defaultConfig: {
    speed: 23,
    accelerationTime: 800,
    decelerationTime: 3000
  }
}

export interface PrizesItem {
  id: number;
  title: string;
  background: string;
  award_type?: number;
  fonts: [{ text: string, top: string }];
  imgs:[{ src: string, width: string, top: string}];
}

interface RewardStyState {
  isPlayMusic: ()=> void;
  toggle: ()=> void;
}

const RewardSty: FC<RewardStyState> = (props)=> {
  const myLucky:any = useRef(null);
  const {isPlayMusic, toggle} = props;
  const {
    setActivityInfo,
    delSurplusNum,
    setJackpotStatus,
    setNowPrizesItem,
    setIsShowJackPot,
  } = activitySlice.actions;
  const [prizeData, setPrizeData] = useSessionStorageState<any[]>('prizes', []);
  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();

  useMount(async ()=> {
    // 是否发布视频
    localStorage.setItem('isPushVideo', '1');
    const urlParam: {id: '', order_sn: '', type: 1|2} = Tools.param2Obj();
    console.log(urlParam)
    const res = await getAwardGoods(urlParam);
    if (res.code === 200) {
      await setStorage('activityId', res.data.id);
      await getPrizeArr(res.data.awards);
      await disPatch(setActivityInfo(res.data));
    }
  })

  const getPrizeArr = (prizeArr: any[])=> {
    const prizes: PrizesItem[] = [];
    if (prizeArr.length>0) {
      prizeArr.forEach((item: any, index: number) => {
        prizes.push({
          id: Number(item.id),
          award_type: item.award_type,
          title: item.award_name,
          background: index % 2 ? '#f9e3bb' : '#f8d384',
          fonts: [{ text: item.award_name, top: '10%' }],
          imgs:[{ src: item.pic_url, width: '28%', top: '42%' }],
        })
      })
    }
    setPrizeData(prizes);
  }

  // 弹出信息
  const isPushVideo = async ()=> {
    let status = localStorage.getItem('isPushVideo');
    if (Tools.isDouYin() && status === '1') {
      alert('提示', '您将在抖音平台发布一条由商家提供的作品', [
        { text: '考虑一下', onPress: () => console.log('xxx') },
        {
          text: '确定知道',
          onPress: () => {
            pushVideo();
          }
        }
      ])
    } else {
      await startPlay();
    }
  }

  // 发布视频
  const pushVideo = async ()=> {
    try {
      Toast.loading('请稍候...', 0);
      const res = await addVideo({
        dy_token: getStorage('dy_token'),
        scene: 'lottery',
        id: getStorage('dy_active'),
      })
      if (res.code === 200) {
        Toast.success('分享成功');
        // 开始抽奖
        await startPlay();
      } else {
        Toast.fail(res.msg);
      }
    } catch (e) {
      console.log(e);
      Toast.hide();
    }
  }

  const startPlay = async()=> {
    const reward_id = await startAwardGoods();
    if (reward_id) {
      // 抽奖声音
      await isPlayMusic();
      await toggle();
      await disPatch(delSurplusNum());
      await localStorage.setItem('isPushVideo', '2');
      const arr: PrizesItem[] = JSON.parse(getStorage('prizes'));
      arr.forEach((item, index) => {
        if (Number(item.id) === Number(reward_id)) {
          myLucky.current.play();
          setTimeout(() => {
            myLucky.current.stop(index);
          }, 800)
        }
      })
    }
  }

  const endPlay = async (item: PrizesItem)=> {
    // 关闭声音
    isPlayMusic();
    if (item.award_type !== 5) {
      await disPatch(setIsShowJackPot(true));
      await disPatch(setJackpotStatus(true));
    } else {
      await disPatch(setIsShowJackPot(true));
      await disPatch(setJackpotStatus(false));
    }
  }

  const startAwardGoods = async(idx?: number) => {
    try {
      const res = await startAward(getStorage('activityId'));
      if (res.code === 200) {
        await disPatch(setNowPrizesItem(res.data));
        return res.data.id;
      };
      return false;
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <LuckyWheel
      ref={myLucky}
      width="380px"
      height="380px"
      class="luck-draw"
      blocks={rewardData.blocks}
      prizes={prizeData}
      buttons={rewardData.buttons}
      defaultStyle={rewardData.defaultStyle}
      defaultConfig={rewardData.defaultConfig}
      onStart={() => isPushVideo()}
      onEnd={(prize: PrizesItem) => endPlay(prize)}
    />
  )
}

export default RewardSty;
