import React, {FC, useState} from "react";
import {Button, InputItem, List, Toast} from "antd-mobile";
import LoginBg from "@assets/images/login_banner.png";
import {MyIcon} from "@components/AppIcon";
import {SendCheckCode} from "@components/SendCheckCode";
import Tools from "@utils/tools";
import {useDispatch, useSelector} from "react-redux";
import * as authStore from "@store/auth.slice";
import {userAppInfo} from "@store/auth.slice";
import {useLocation} from "react-router-dom";
import {useMount} from "ahooks";
import './index.scss';

export interface UserData {
  mobile: string;
  check_code: string;
  ali_token?: string;
  dy_token?: string;
  dy_active?: string;
}


const UserLogin: FC = ()=> {
  const user_info = useSelector(userAppInfo);
  const location = useLocation();
  const dispatch: (...args: unknown[]) => Promise<UserData> = useDispatch();
  const [mobile, setMobile] = useState("");
  const [check_code, setCheckCode] = useState("");

  useMount(()=> {
    console.log(location)
  })

  const changeMobile = (evt: any) => {
    setMobile(evt)
  }

  const changeCode = (evt: any) => {
    setCheckCode(evt)
  }

  // ali用户注册
  const registerUser = async() => {
    try {
      const status = await dispatch(authStore.goUserLoginByAliToken(
        {mobile, check_code},
        user_info.ali_token)
      );
      if (status) Toast.info('登录成功!');
    } catch (e) {
      console.log(e)
    }
  }

  // h5 入口登录
  const h5EntryLogin = async () => {
    try {
      await dispatch(authStore.userLoginWeb({mobile, check_code}))
    } catch (e) {
      console.log(e)
    }
  }

  const userButton = () => {
    const env = Tools.getSystemPayEnv();
    if (env === 'h5') {
      return (
        <Button className='login-btn' type='primary' onClick={()=>h5EntryLogin()}>登录</Button>
      )
    }
    return <Button type='primary' onClick={()=>registerUser()} className='sign-btn'>注册</Button>
  }

  return (
    <div className='login-con'>
      <div className='login-banner'>
        <span className='login-title'>用户登录</span>
        <img src={LoginBg} alt='登录背景'/>
      </div>
      <div className='login-main'>
        <List className='login-input'>
          <InputItem onChange={changeMobile} type='phone' placeholder='请输入手机号'>
            <MyIcon type='iconphone'/>
          </InputItem>
          <InputItem onChange={changeCode} maxLength={8} placeholder='请输入验证码'>
            <MyIcon type='iconAPImiyue'/>
          </InputItem>
          <SendCheckCode phone={mobile} />
        </List>
        <div className='login-bot'>
          {userButton()}
          <div className='login-bot-text'>
            <span className='bot-text-title'>带你美好生活</span>
            <div className='bot-text-end'>
              <span className='text-info'>登录使用就表示同意汇悦客</span>
              <span style={{color: '#0136DE'}}>条款</span>和<span style={{color: '#0136DE'}}>隐私</span>政策
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserLogin;