import React, {useState} from "react";
import {Button, Modal, Toast} from "antd-mobile";
import {useDispatch, useSelector} from "react-redux";
import {BalanceArr, payAppInfo, paySlice} from "@store/pay.slice";
import {userAppInfo} from "@store/auth.slice";
import {MyIcon} from "@components/AppIcon";
import {passwordSlice} from "@components/BalancePay/balance.slice";
import {useNavigate} from "react-router-dom";
import { useWxPay } from "@utils/app_hooks";
import * as payStore from "@store/pay.slice";
import PayUtils from "@utils/pay_utils";
import {getStorage} from "@utils/my_storage";
import Tools from "@utils/tools";
import { isSetPayPassword } from "@api/user";
import {addVideo, userSelfStatus} from "@api/app";
import {useMount} from "ahooks";
import { apiUrl } from "@utils/http";
import { getPrizeInfo } from "@api/rewards";
import {failOrderCallBack} from "@api/order";
import "./pay.scss";

const alert = Modal.alert;
const webUrl = process.env.REACT_APP_Host_URL;


const BottomPay = () => {
  const navigate = useNavigate();
  const [orderStatus, setOrderStatus] = useState(false);
  const payinfo = useSelector(payAppInfo);
  const authInfo = useSelector(userAppInfo);
  const {
    bottomPay,
    nowPayItem: {mutex},
    isUsedAppCardStatus,
    nowManageCard,
    isOpenInvoiceStatus,
    nowInvoiceId,
    isShowCardStatus
  } = payinfo;
  const { setMorePayStatus } = paySlice.actions;
  const { changeStatus } = passwordSlice.actions;
  const dispatch: (...args: unknown[]) => Promise<any> = useDispatch();
  const userWxPay = useWxPay();
  const { payment_code } = payinfo.nowPayItem;
  const [zeroStatus, setZeroStatus] = useState(false);

  useMount(()=> {
    console.log('底部支付xxxx')
  })

  const isActivity = async ()=> {
    if (Tools.isDouYin()) {
      const urlParam = Tools.param2Obj();
      if (urlParam.hasOwnProperty('dy_active')) {
        alert('提示', '您将在抖音平台发布一条由商家提供的作品', [
          { text: '考虑一下', onPress: () => pushOrder()},
          {
            text: '确定知道',
            onPress: () => pushVideo(urlParam.dy_active, urlParam.video_id)
          },
        ])
      }
    } else {
      if (!orderStatus) pushOrder();
    }
  }

  // 发布视频
  const pushVideo = async (id: string, video_id: string)=> {
    try {
      Toast.loading('请稍候...', 0);
      const res = await addVideo({
        dy_token: getStorage('dy_token'),
        scene: 'cardpay',
        id,
        video_id: video_id
      })
      if (res.code === 200) {
        Toast.hide();
        await pushOrder();
      } else {
        Toast.fail('请重新扫码');
      }
    } catch (e) {
      console.log(e);
      Toast.hide();
    }
  }

  // 提交订单
  const pushOrder = async () => {
    try {
      setOrderStatus(true);
      const param = Tools.getAllDisInfoStatus(mutex);
      let data = Object.assign({}, payinfo.payParams, param);
      console.log(payment_code)
      data.payment_code = payment_code;
      // 是否购买平台卡
      if (isUsedAppCardStatus && isShowCardStatus) data.platform_card_data_id = nowManageCard.id;
      else delete data.platform_card_data_id;
      // 是否开发票
      if (isOpenInvoiceStatus) data.receipt_id = nowInvoiceId;
      // 余额支付
      let status = BalanceArr.indexOf(payment_code) > -1;
      if (status) {
        data.account_id = payinfo.nowPayItem.account_id;
        return await isUsedPassword(data);
      }
      // 银联支付
      if (payment_code === 'unionpay') data.return_url =
       `http://${window.location.hostname}/payresult?app_id=${getStorage(('app_id'))}&status=success&`;
      if (payment_code === 'cash') {
        return alert('温馨提示', '确认使用现金支付吗？', [
          { text: '取消', onPress: () => setOrderStatus(false)},
          { text: '确定', onPress: () => userPayMoney(data) }
        ])
      }
      userPayMoney(data)
    } catch (e) {
      console.log(e)
    }
  }

  // 是否使用免密支付
  const isUsedPassword = async (data: any)=> {
    if (authInfo.user_info.is_balance_confidential_payment) {
      return alert('温馨提示', '是否使用免密支付!', [
        { text: '取消', onPress: () => {
            dispatch(changeStatus(true));
            setOrderStatus(false);
          }
        },
        { text: '确定', onPress: async () => {
          Toast.loading('请稍候...');
          const res = await dispatch(payStore.userCreateOrder(data));
          if (res.code === 200) {
            await getPrizeInfo(res.data.ordersn);
            navigate(`/payresult?ordersn=${res.data.ordersn}&status=success&balance=${true}`, { replace: true })
            Toast.hide();
          } else {
            setOrderStatus(false)
          }
        }}
      ])
    } else {
      const user_status = await userSelfStatus('open_client_user_paypass');
      if (user_status.data.status) {
        // 是否设置密码
        const pwd_status = await isPasswordStatus();
        setOrderStatus(false);
        if (pwd_status) {
          await dispatch(changeStatus(true));
          return setOrderStatus(false);
        } else {
          return alert('温馨提示', '您有尚未设置支付密码, 是否去设置!', [
            { text: '取消', onPress: () => console.log('取消')},
            { text: '确定', onPress: () => {
                dispatch(changeStatus(false));
                navigate(`/password/forget/pay`);
              }
            }
          ])
        }
      } else {
        alert('温馨提示', '确认使用余额进行支付吗？', [
          { text: '取消', onPress: () => setOrderStatus(false)},
          { text: '确定', onPress: async () => {
              Toast.loading('请稍候...');
              const res = await dispatch(payStore.userCreateOrder(data));
              if (res.code === 200) {
                navigate(`/payresult?ordersn=${res.data.ordersn}&status=success&balance=${true}`, { replace: true })
                Toast.hide()
              } else setOrderStatus(false)
            }
          }
        ])
      }
    }
  }

  // 进行支付
  const userPayMoney = async (data: any)=> {
    try {
      const res = await dispatch(payStore.userCreateOrder(data));
      if (res) {
        const  payArr = ['wxpay', 'fuiou', 'normal_allinpay', 'lakala'];
        // if (res.code === 200) await getPrizeInfo(res.data.ordersn);
        const env = Tools.getSystemPayEnv();
        // return console.log('xxxx_payment_code', payment_code);
        if (payArr.indexOf(payment_code)>-1) {
          if(env === "ali") {
            const status = await PayUtils.aliPay(res.data.targetOrderId);
            if (status) {
              navigate(`/payresult?ordersn=${res.data.ordersn}&status=success`, {replace: true})
            } else {
              navigate(`/payresult?ordersn=${res.data.ordersn}&status=fail`, {replace: true})
            }
          } else if (env === 'h5') {
            window.location.href = res.data.mweb_url;
          } else {
            const callbackUrl = `${webUrl}/time_pay?app_id=${getStorage('app_id')}`+
              `&app_token=${getStorage('app_token')}&paysn=${res.data.paysn}&ordersn=${res.data.ordersn}`;
            const url = `${apiUrl}/client/wechat.pay_login/snsapi_base?app_id=${getStorage('app_id')}&callback=${encodeURIComponent(callbackUrl)}`;
            if (payment_code === 'fuiou') {
              if (res.data.pay_page === 1) {
                window.location.href = url;
              } else {
                if (res.data.hasOwnProperty('appId')) {
                  userWxPay.pay(res.data);
                } else {
                  Toast.info(res.msg)
                }
              }
            } else if (payment_code === 'normal_allinpay') {
              if (res.data.pay_page === 1) {
                window.location.href = url;
              } else {
                if (res.data.hasOwnProperty('appId')) {
                  userWxPay.pay(res.data);
                } else {
                  Toast.info(res.msg)
                }
              }
            } else if (payment_code === 'lakala') {
              if (res.data.pay_page === 1) {
                window.location.href = url;
              } else {
                if (res.data.hasOwnProperty('appId')) {
                  userWxPay.pay(res.data);
                } else {
                  Toast.info(res.msg)
                }
              }
            }  else if (res.code === 200) {
              if (res.data.hasOwnProperty('appId')) {
                userWxPay.pay(res.data);
              } else {
                Toast.info(res.msg)
              }
            }
          }
        } else if (payment_code === 'ali') {
          const status = await PayUtils.aliPay(res.data.targetOrderId);
          if (!status) await failOrderCallBack(res.data.ordersn)
        } else if (payment_code === 'cash') {
          Toast.success('请与加油员工确认后离开', 2);
          setTimeout(()=> {
            navigate(`/payresult?ordersn=${res.data.ordersn}&status=success`, {replace: true})
          }, 2000)
        } else if (payment_code === 'unionpay') {
          window.location.href = res.data.url;
        }
        setOrderStatus(false);
      }
    } catch (e) {
      console.log(e);
      setOrderStatus(false)
    }
  }

  // 判断是否设置支付密码
  const isPasswordStatus = async()=> {
    try {
      const status = await isSetPayPassword();
      if (status.code === 200) return true;
      return false;
    } catch (e) {
      console.log(e)
    }
  }

  const changeNumber = (num: number)=> {
    return Math.round(num*100) /100;
  }

  // 底部金额展示
  const bottomMoneyShow = ()=> {
    if (payinfo.openRechargeStatus) {
      const {recharge_money, order_amount} = payinfo.rechargeStoreInfo;
      if (isUsedAppCardStatus) {
        return {
          amount: changeNumber(Number(recharge_money) + Number(nowManageCard.sale_amount)),
          dis_money: changeNumber(bottomPay.money - order_amount)
        }
      } else {
        return {
          amount: recharge_money,
          dis_money: changeNumber(bottomPay.money - order_amount)
        }
      }
    }
    if (isUsedAppCardStatus && isShowCardStatus) {
      return {
        amount: changeNumber(Number(bottomPay.amount)+Number(nowManageCard.sale_amount)),
        dis_money: changeNumber(bottomPay.money-bottomPay.amount)
      }
    }
    return {
      amount: bottomPay.amount,
      dis_money: changeNumber(bottomPay.money-bottomPay.amount)
    }
  }
  // 是否展示零费率优惠内容
  const isShowZeroInfo = ()=> {
    const {zero_fee} = payinfo.nowPayItem;
    if (zero_fee && zero_fee > 0) setZeroStatus(true);
  }

  // 零费率活动展示内容
  const zeroActivity = ()=> {
    const {
      point_dec_money,
      coupon_dec_money,
      discount_dec_money,
      recharge_dec_money,
      platform_dec_money,
      zero_fee
    } = payinfo.nowPayItem;
    return (
      <Modal
        popup
        title={'优惠详情'}
        visible={zeroStatus}
        onClose={()=> setZeroStatus(false)}
        animationType="slide-up"
      >
        <div className={'dis-bot'}>
          <div className={'dis-item'}>
            <span>会员优惠</span>
            <span>-{discount_dec_money}元</span>
          </div>
          <div className={'dis-item'}>
            <span>优惠券优惠</span>
            <span>-{coupon_dec_money}元</span>
          </div>
          <div className={'dis-item'}>
            <span>积分优惠</span>
            <span>-{point_dec_money}元</span>
          </div>
          <div className={'dis-item'}>
            <span>平台卡优惠</span>
            <span>-{platform_dec_money}元</span>
          </div>
          <div className={'dis-item'}>
            <span>充值优惠</span>
            <span>-{recharge_dec_money}元</span>
          </div>
          <div className={'dis-item'}>
            <span>服务费</span>
            <span>+{zero_fee}元</span>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <>
      <div className={'pay-btn'}>
        <div className={'bottom-balance'} onClick={()=> isShowZeroInfo()}>
          <div className={'sun-all'}>
            <span className={'sum-text'}>合计:</span>
            <span className={'sum-money'}>￥{bottomMoneyShow().amount}</span>
          </div>
          <div className={'dis-money'}>
            <span>已优惠:</span>
            <span>{bottomMoneyShow().dis_money}元</span>
          </div>
        </div>
        <div
          className={'more-btn'}
          onClick={()=> dispatch(setMorePayStatus(true))}
        >
          更多 <MyIcon type={'iconqianjin'}/>
        </div>
        <Button className='get-money' loading={orderStatus} onClick={isActivity}>
          <span>立即支付</span>
        </Button>
      </div>
      {zeroActivity()}
    </>
  )
}


export default BottomPay;