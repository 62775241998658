import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "store/index";

interface State {
  password: string;
  modelStatus: boolean;
  password_arr: string[];
}

const initialState: State = {
  password_arr: ['','','','','',''],
  password: "",
  modelStatus: false,
};


export const passwordSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setPassword(state, action) {
      state.password = action.payload;
    },
    changeStatus(state, action) {
      state.modelStatus = action.payload;
    },
    setPasswordArr(state, action) {
      if (action.payload.length > 0) {
        let arr = action.payload.split('');
        for (let i = 0; i < 6; i++) {
          if (6 - arr.length > 0) {
            arr.push('');
          }
        }
        state.password_arr = arr;
      } else {
        state.password_arr = ['', '', '', '', '', ''];
      }
    }
  },
});


export const passwordAppInfo = (state: RootState) => state.passwordInfo;



