import { PrizesItem } from "@components/RewardSty/lucky_1";
import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "store/index";

interface RewardItem {
  award_name: string;
  pic_url: string;
  award_type: number;
  id: number;
}

interface State {
  isPushVideo: boolean;
  isShowJackPot: boolean;
  isJackpotStatus: boolean;
  isTeamShow: boolean; // 队长是否开启组队显示
  nowPrizesItem: {
    award_type: number;
    id: number;
    title: string;
    balance: number;
    coupon_list: any[];
    integral: number;
    award_id: number;
    prize_order_id?: number;
  }
  activity: {
    end_time: string;
    start_time: string;
    intro: string;
    awards?: RewardItem[];
    surplus_num: number;
    participants_num: number;
    id: number;
    is_team: 1|0;
    team_pic_url: string;
    team_statement: string;
    team_title: string;
    status: number;
    team_info?: {
      id: number
    }
  },
  prizeArr: PrizesItem[];
}

// @ts-ignore
const initialState: State = {
  isPushVideo: true,
  isShowJackPot: false,
  isJackpotStatus: false, // 是否中奖
  isTeamShow: false,
  nowPrizesItem: {
    award_type: 1,
    id: 0,
    title: '',
    balance: 0,
    coupon_list: [],
    integral: 0,
    award_id: 0
  },
  activity: {
    id: 0,
    end_time: '',
    start_time: '',
    intro: '',
    awards: [],
    surplus_num: 0,
    participants_num: 0,
    team_pic_url: '',
    team_statement: '',
    team_title: '',
    is_team: 0,
    status: 0
  },
  prizeArr: []
};


export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setActivityInfo(state, action) {
      state.activity = action.payload;
    },
    delSurplusNum(state) {
      --state.activity.surplus_num;
    },
    setJackpotStatus(state, action) {
      state.isJackpotStatus = action.payload;
    },
    setNowPrizesItem(state, action) {
      state.nowPrizesItem = action.payload;
    },
    setIsTeamShow(state, action) {
      state.isTeamShow = action.payload;
    },
    setIsShowJackPot(state, action) {
      state.isShowJackPot = action.payload;
    },
    setIsPushVideo(state, action) {
      state.isPushVideo = action.payload;
      localStorage.setItem('isPushVideo', '2');
    }
  },
});

// const { setActivityInfo } = activitySlice.actions;

export const activityInfo = (state: RootState) => state.activityInfo;


