import React  from 'react';
import './index.scss';

const Person = ()=> {
  return (
    <div className={'person-con'}>
      <div className={'person-info-top'}>个人信息顶部</div>
      <div className={'person-info-fun'}>功能模块</div>
    </div>
  )
}

export default Person