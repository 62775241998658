import React from "react";
import {ActionSheet, List, Switch} from 'antd-mobile';
import {useDispatch, useSelector} from "react-redux";
import {BalanceArr, payAppInfo} from "@store/pay.slice";
import * as payStore from "@store/pay.slice";
import { openReceipt } from "@api/user";
import { MyIcon } from "@components/AppIcon";


export const InvoiceInfo = ()=> {
  const payInfo = useSelector(payAppInfo);
  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();
  const {setIsOpenInvoiceStatus, setNowInvoiceId} = payStore.paySlice.actions;

  const changeSwitch = (val: boolean)=> {
    if(val) showActionSheet();
    disPatch(setIsOpenInvoiceStatus(val));
  }

  const setInvoiceId = async (type: number)=> {
    try {
      const res = await openReceipt({
        address: "", bank: "", bank_account: "", is_default: 0, mobile: "", tax_id: 0, tax_name: "", type
      })
      if (res.code === 200) {
        await disPatch(setNowInvoiceId(res.data.id));
      }
    } catch (e) {
      console.log(e);
    }
  }

  const showActionSheet = () => {
    const BUTTONS = ['个人发票', '企业发票', '取消'];
    ActionSheet.showActionSheetWithOptions({
      options: BUTTONS,
      cancelButtonIndex: BUTTONS.length - 1,
      maskClosable: true,
    },
    (buttonIndex) => {
      console.log(buttonIndex)
      if (buttonIndex === 2) {
        return  disPatch(setIsOpenInvoiceStatus(false))
      }
      return setInvoiceId(buttonIndex+1);
    });
  }

  if (BalanceArr.indexOf(payInfo.nowPayItem.payment_code)> -1) {
    disPatch(setIsOpenInvoiceStatus(false));
    return <></>;
  }

  return (
    <List>
      <List.Item
        thumb={<MyIcon type={'iconzhinengkefu-'} style={{fontSize: '26px'}}/>}
        multipleLine
        extra={<Switch
          checked={payInfo.isOpenInvoiceStatus}
          onChange={(val) => changeSwitch(val)}
        />}
      >是否开发票</List.Item>
    </List>
  )
}

export default InvoiceInfo;