import {Button, List, Modal, Result} from "antd-mobile";
import React, {useState} from "react";
import {useMount} from "ahooks";
import { getCarCertificationDetail } from "@api/user";
import './index.scss'
import {useNavigate} from "react-router-dom";

export interface DetailState {
  id: number;
  car_type_id: number;
  car_type_name: string;
  name: string;
  url: string;
  mobile: string;
  status: 0 | 1 | 2;
}

const CarTypeDetail = ()=> {

  const navigate = useNavigate();
  const [status, setStatus] = useState(true);
  const myImg = (src: string) => <img src={src} className="spe am-icon am-icon-md" alt="" />;

  const [detail_info, setDetailInfo] = useState<DetailState>({
    car_type_id: 0,
    car_type_name: "",
    id: 0,
    mobile: "",
    name: "",
    status: 0,
    url: ""
  });

  useMount(async ()=> {
    await setDetailData()
  })

  const setDetailData = async ()=> {
    try {
      const res = await getCarCertificationDetail();
      switch (res.code) {
        case 200:
          setStatus(true);
          setDetailInfo(res.data);
          break;
        case 400:
          Modal.alert(
            '温馨提示',
            '您尚未进行专车认证,是否进行认证',
            [
              {text: '取消'},
              {text: '认证', onPress: ()=> navigate('/special_car')}
            ]
          )
          break;
      }
    } catch (e) {
      console.log(e)
    }
  }

  const filterStatus = ()=> {
    switch (detail_info.status) {
      case 0:
        return '待审核';
      case 1:
        return '审核通过';
      case 2:
        return '审核拒绝'
    }
  }

  const resultShow = ()=> {
    return (
      <Result
        img={myImg('https://gw.alipayobjects.com/zos/rmsportal/GIyMDJnuqmcqPLpHCSkj.svg')}
        title="尚未进行认证"
        message="由于您尚未进行专车认证无法展示相关信息"
      />
    )
  }

  return (
    <>
      {
        status &&
          <>
            <div className={'detail-con'}>
                <img alt={''} src={detail_info.url}/>
            </div>
            <div className={'detail-center'}>
              {<List renderHeader={() => '认证信息'} className="my-list">
                <List.Item extra={detail_info.name}>姓名</List.Item>
                <List.Item extra={detail_info.mobile}>手机号</List.Item>
                <List.Item extra={detail_info.car_type_name}>专车类型</List.Item>
                <List.Item extra={filterStatus()}>审核状态</List.Item>
              </List>}
            </div>
            <div className={'change-btn'}>
                <Button size={'large'} type={'warning'} onClick={()=> navigate('/special_car_update')}>修改信息</Button>
            </div>
          </>
        }
      { !status && resultShow() }
    </>
  )
}

export default CarTypeDetail;