import React, {FC} from 'react';
import {Checkbox, List} from "antd-mobile";
import CouponView from "@components/Coupon";
import {useDispatch, useSelector} from "react-redux";
import {payAppInfo, paySlice} from "@store/pay.slice";
import {couponAppInfo} from "@components/Coupon/coupon.slice";
import { MutexInfo } from './pay_store_interface';
import * as CouponStore from "@components/Coupon/coupon.slice";
import Tools from '@utils/tools';
import {InfoCircleOutlined} from "@ant-design/icons";
import HuiIcon from "@assets/images/hui_icon.jpg";

const DisInfoView:FC = ()=> {
  const disInfo = useSelector(payAppInfo);
  const couponInfo = useSelector(couponAppInfo);

  const dispatch: (...args: unknown[]) => Promise<any> = useDispatch();
  const {updateDisStatus, setPayParam} = paySlice.actions;
  const {
    setCouponArr,
    setNowTabIndex,
    setModelStatus,
    initUsedCoupon
  } = CouponStore.couponSlice.actions;

  const {disMutex, nowPayItem: { mutex, payment_code, account_id }, payParams, isUsedAppCardStatus, isShowCardStatus, bottomPay, nowManageCard } = disInfo;

  const { zero_fee } = disInfo.nowPayItem;

  const getMyTitle = ()=> {
    if(disMutex.radio && disMutex.radio.length>1) {
      return <div className={'radio-title'}>以下优惠不可同享</div>
    }
  }

  // 最新优惠状态
  const getDisInfoStatus = (box_type: 'radio'|'checkbox', dis_type: string, status: boolean)=> {
    let obj: {[key: string]: any} = {};
    if (box_type === 'radio') {
      Object.keys(mutex.radio).forEach(child => {
        if (child === "coupon") { 
          obj[dis_type] = 1;
        } else {
          if (child === dis_type) {
            obj[dis_type] = status ? 1:0;
          } else {
            obj[child] = 0;
          }
        }
      })
    } else {
      Object.keys(mutex.checkbox).forEach(child => {
        if (child === "coupon") {
          console.log('====>dis_type', dis_type);
          obj[dis_type] = 1;
        } else {
          if (child === dis_type) {
            obj[dis_type] = status ? 1:0;
          } else {
            obj[child] = mutex.checkbox[child].checked ? 1 : 0
          }
        }
      })
    }
    return obj;
  }

  const changeNumber = (num: number)=> {
    return Math.round(num*100) /100;
  }

  const bottomMoneyShow = ()=> {
    if (disInfo.openRechargeStatus) {
      const {recharge_money, order_amount} = disInfo.rechargeStoreInfo;
      if (isUsedAppCardStatus) {
        return {
          amount: changeNumber(Number(recharge_money) + Number(nowManageCard.sale_amount)),
          dis_money: changeNumber(bottomPay.money - order_amount)
        }
      } else {
        return {
          amount: recharge_money,
          dis_money: changeNumber(bottomPay.money - order_amount)
        }
      }
    }
    if (isUsedAppCardStatus && isShowCardStatus) {
      return {
        amount: changeNumber(Number(bottomPay.amount)+Number(nowManageCard.sale_amount)),
        dis_money: changeNumber(bottomPay.money-bottomPay.amount)
      }
    }
    return {
      amount: bottomPay.amount,
      dis_money: changeNumber(bottomPay.money-bottomPay.amount)
    }
  }

  // 更新状态
  const checkBoxUpdate = async (box_type: 'radio'|'checkbox', dis_type: string, status: boolean)=> {
    try {
      await dispatch(updateDisStatus({box_type, status_item: dis_type, status}));
      const param = getDisInfoStatus(box_type, dis_type, status);
      console.log(param)
      const oldParam = Tools.getAllDisInfoStatus(mutex);
      const endParam = Object.assign(oldParam, param);
      if (dis_type === "coupon") {
        if (!couponInfo.ModelStatus) {
          if (disInfo.disMutex[box_type] instanceof Object) {
            let list = disInfo.disMutex[box_type].filter(item => item.dis_type === 'coupon');
            if (list.length) {
              const item = list[0];
              await dispatch(setCouponArr(item.list));
              await dispatch(setNowTabIndex(item.coupon_type-1));
              await dispatch(initUsedCoupon(item.coupon_type));
              await dispatch(setModelStatus(true));
            }
          }
        }
      }
      let data = {
        ...endParam,
        payment_code: payment_code,
        account_id: account_id,
        money: payParams.money,
        coupon_ids: ""
      }
      if (endParam.coupon === 1) {
        if (mutex[box_type].hasOwnProperty('coupon')) {
          data.coupon_ids = mutex[box_type].coupon.coupon_ids.join(',')
        }
      }
      if (box_type === "checkbox" && dis_type === "coupon") return 
      else {
        await dispatch(setPayParam(data));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const ChooseMap = (type: MutexInfo, box_type: 'radio'|'checkbox')=> {
    const {discount, point, coupon, platform} = type;
    return {
      discount_info: ()=> {
        return (
          <List.Item
            key={1}
            extra={<Checkbox checked={discount?.checked}/>}
            onClick={()=> checkBoxUpdate(box_type, "discount", !discount?.checked)}
          >
            <div className={'check-dis-all'}>
              <div className={'check-dis'}>
                {/*<MyIcon className={'oil-icon'} type={item.icon}/>*/}
                <span>会员优惠</span>
              </div>
              <span className={'text-dis'}>{discount?.discount_str}</span>
            </div>
          </List.Item>
        )
      },
      point_info: ()=> {
        return (
          <List.Item
            key={2}
            extra={<Checkbox checked={point?.checked}/>}
            onClick={()=> checkBoxUpdate(box_type, "point", !point?.checked)}
          >
            <div className={'check-dis-all'}>
              <div className={'check-dis'}>
                {/*<MyIcon className={'oil-icon'} type={item.icon}/>*/}
                <span>积分抵扣</span>
              </div>
              <span className={'text-dis'}>可用{point?.allow_point}积分, 抵扣 {point?.point_dec_money}元</span>
            </div>
          </List.Item>
        )
      },
      coupon_info: ()=> {
        return (
          <List.Item
            key={3}
            extra={<Checkbox checked={coupon?.checked}/>}
            onClick={()=> checkBoxUpdate(box_type, "coupon", !coupon?.checked)}
          >
            <div className={'check-dis-all'}>
              <div className={'check-dis'}>
                {/*<MyIcon className={'oil-icon'} type={item.icon}/>*/}
                <span>优惠券</span>
              </div>
              <span className={'text-dis'}>减{coupon?.coupon_dec_money}元</span>
            </div>
          </List.Item>
        )
      },
      platform: ()=> {
        return (
          <List.Item
            key={4}
            style={{height: '78px'}}
            extra={<Checkbox checked={platform?.checked}/>}
            onClick={()=> checkBoxUpdate(box_type, "platform", !platform?.checked)}
          >
            <div className={'check-dis-all'}>
              <div className={'check-dis'}>
                <span>平台卡</span>
              </div>
              <span className={'text-dis'}>{platform?.discount_str}</span>
            </div>
            <div className={'text-str'}>{platform?.remain_str}</div>
          </List.Item>
        )
      }
    }
  }

  const getBoxView = (type: MutexInfo, box_type: 'radio'|'checkbox')=> {
    const { discount_info, point_info, coupon_info, platform } = ChooseMap(type, box_type);
    if (Object.keys(type).length > 0) {
      // eslint-disable-next-line
      return Object.keys(type).map(item => {
        if (item === "discount") {
          return discount_info();
        } else if (item === "point") {
          return point_info();
        } else if (item === "coupon") {
          return coupon_info();
        } else if (item === "platform") {
          return platform();
        }
      })
    }
  }

  const maxMoneyInfo = ()=> {
    return (
      <div className={'max-all-info'}>
        <div className={'info-title'}>
          <span>最高优惠</span>
          <span>￥{bottomMoneyShow().dis_money}</span>
        </div>
        <div className={'info-item'}>
          <div className={'logo-left'}>
            <img className={'logo'} src={HuiIcon} alt={''}/>
            <span>本单立减金</span>
          </div>
          <span>-￥{bottomMoneyShow().dis_money}</span>
        </div>
        <div className={'bot-info'}>
          <span className={'real-money'}>实付金额</span>
          <span className={'money-text'}>￥{bottomMoneyShow().amount}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={'dis-all-con'}>
      {Number(zero_fee) > 0 && maxMoneyInfo()}
      <div className={'zero-con-title'}>
        <div className={'title-left'}>
          <span className={'title-info'}>合计优惠</span>
          <InfoCircleOutlined style={{marginLeft: '5px'}}/>
          <span className={'title-text'}>已为您默认选择最高优惠</span>
        </div>
        <span className={'title-money'}>-￥{bottomMoneyShow().dis_money}</span>
      </div>
      <List>
        {getBoxView(mutex.checkbox, "checkbox")}
      </List>
      <List renderHeader={getMyTitle()}>
        {getBoxView(mutex.radio, "radio")}
      </List>
      {/**优惠券**/}
      <CouponView/>
    </div>
  )
}

export default DisInfoView;