import React, {useState} from "react";
import {Button, Toast, Modal} from 'antd-mobile';
import { activtiyDetail } from "@api/user";
import {useMount} from "ahooks";
import Tools from '@utils/tools';
import {useNavigate} from "react-router-dom";
import Copyright from '@components/Copyright/index';
import CouponRightIcon from '@assets/images/cou_yuan_bg.png';
import {addVideo} from "@api/app";
import {getStorage} from "@utils/my_storage";

import './index.scss';

const alert = Modal.alert;

interface CouponState {
  enough_money: number;
  money: number;
  num: number;
}

const ActivityView = ()=> {
  const navigate = useNavigate();
  const [couponTextInfo, setCouponInfo] = useState<CouponState>({
    enough_money: 0,
    money: 0,
    num: 0
  });
  const [activityInfo, setActivityInfo] = useState({
    id: '',
    detail: {
      is_pay: 0,
      is_reward: 0,
      is_prize: 0,
      reward_coupon_list: []
    },
    title: '',
    video: {
      id: "",
      url: ""
    }
  });

  useMount(async ()=> {
    await localStorage.setItem('isPushVideo', '1');
    const urlParam: {dy_active: ''} = Tools.param2Obj();
    await setActivity(urlParam.dy_active);
  })

  // 展示活动信息
  const setActivity = async (id: string)=> {
    try {
      if (!id) return Toast.info('获取活动信息失败');
      const res = await activtiyDetail(id);
      if (res.code === 200) {
        setActivityInfo(res.data);
        couponInfo(res.data.detail.reward_coupon_list)
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 优惠券展示信息
  const couponInfo = async (arg: CouponState[])=> {
    if (arg instanceof Array && arg.length > 0) {
      setCouponInfo({
        enough_money: Number(arg[0].enough_money),
        money: Number(arg[0].money) * Number(arg[0].num),
        num: Number(arg[0].num)
      })
    }
  }

  // 弹出信息
  const isPushVideo = ()=> {
    if (Tools.isDouYin()) {
      if (localStorage.getItem('isPushVideo') === '2') {
        Toast.fail('您已领取过了');
      } else {
        alert('提示', '您将在抖音平台发布一条由商家提供的作品', [
          { text: '考虑一下', onPress: () => console.log('xxxx')},
          {
            text: '确定知道',
            onPress: () => pushVideo()
          }
        ])
      }
    } else {
      Toast.fail('只能在抖音平台领取');
    }
  }

  // 发布视频
  const pushVideo = async ()=> {
    try {
      Toast.loading('请稍候...', 0);
      const res = await addVideo({
        dy_token: getStorage('dy_token'),
        scene: 'activtiy',
        id: activityInfo.id,
        video_id: activityInfo.video.id
      })
      if (res.code === 200) {
        await localStorage.setItem('isPushVideo', '2');
        Toast.success('分享成功');
        setTimeout(()=> {toPage()}, 800);
      } else {
        Toast.fail('请重新扫码');
      }
    } catch (e) {
      console.log(e);
      Toast.hide();
    }
  }

  // 跳转页面
  const toPage = ()=> {
    const {detail} = activityInfo;
    if (detail.is_pay) {
      navigate('/cardpay');
    } else {
      if (detail.is_reward) {
        return navigate('/coupon');
      }
      if (detail.is_prize) {
        return navigate('/is_prize');
      }
    }
  }

  return (
    <div className={'activity-home'}>
      <div className={'activity-top'}>
        {
          activityInfo.video.url && (
            <video controls height='auto' preload='auto'>
              <source src={activityInfo.video.url} type={'video/mp4'}/>
            </video>
          )
        }
      </div>
      <div className={'activity-text'}>
        📢{activityInfo.title}💥
        🎉各种优惠·各种好礼🥳
        💗积赞&充值福利多多🌟
        💥赶快来加满油箱啦😏
      </div>
      <div className={'activity-detail'}>
        <div className={'activity-coupon'}>
          <div className={'coupon-money'}>{couponTextInfo.money}</div>
          <div className={'coupon-text-info'}>
            {
              couponTextInfo.enough_money > 0 ?
                `满${couponTextInfo.enough_money}元可使用${couponTextInfo.money/couponTextInfo.num}元`:'无门槛使用'
            }
          </div>
          <div className={'coupon-right'} onClick={()=> isPushVideo()}>
            <img src={CouponRightIcon} alt={'xxx'}/>
            <span>领取</span>
          </div>
        </div>
      </div>
      <div className={'activity-push'}>
        <Button
          type={'primary'}
          style={{background: '#fc403f'}}
          onClick={()=> navigate('/add_lottery')}
        >我是商家，免费创建引流活动
        </Button>
      </div>
      <div className={'activity-bottom'}>
        <Copyright></Copyright>
      </div>
    </div>
  )
}

export default ActivityView;