import {createSlice} from "@reduxjs/toolkit";
import {Toast} from "antd-mobile";
import {AppDispatch, RootState} from "store/index";
import { PayTypeStore, ReachargeRule, RechargeStore, UserCardItem} from "@view/user_pay/pay_store_interface";
import {computeUserOrder, createOrder, getRechargeInfo, OrderCompute, RechargeParam} from "@api/order";
import {getManageCardList, initMoneySetting } from "@api/app";
import {getStorage, setStorage} from "@utils/my_storage";
import { getUserCardList } from "@api/user";
import Tools from "@utils/tools";

export const BalanceArr = ['balance', 'card_balance', 'team_balance', 'family_balance'];

// 本地参数
function getLosParam(str: string) {
  if (getStorage(str)) {
    return getStorage(str)
  }
  return "";
}

export interface OilTypeState {
  goods: {
    id: number,
    name: string,
    price: string,
    type: {id: number, name: string}
  }
  id: number;
  name: string;
  goods_id: number;
}

interface State {
  userPayInfo: Array<PayTypeStore> | null; // 所有支付方式
  nowOilItem: OilTypeState;
  rechargeInfoStatus: boolean;
  isUsedAppCardStatus: boolean;
  isShowCardModal: boolean; // 是否展示应用平台卡列表
  disMutex: {
    radio: Array<any>;
    checkbox: Array<any>;
  };
  nowPayItem: PayTypeStore;
  initMoneyArr: string[];
  bottomMorePay: Array<PayTypeStore> | [], // 底部更多按钮打开支付方式
  showInitPay: Array<PayTypeStore> | [];
  morePayStatus: boolean;
  bottomPay: {
    money: number;
    amount: number;
  },
  payParams: OrderCompute | any;
  rechargeIdx: number;
  rechargeArr: Array<RechargeStore>;  // 展示储值支付内容
  nowRechargeRule: ReachargeRule;
  rechargeStoreInfo: {   // 当前储值优惠内容
    recharge_id: number,
    key: number,
    account_id: number,
    recharge_money: number,
    recharge_send_money: number,
    order_amount: number,
    recharge_balance: number
  };
  rechargeTabs: Array<any>;
  rechargeRuleIdx: number;
  openRechargeStatus: boolean;
  disStatus: {
    discount: number,
    coupon: number,
    point: number,
    [key: string]: any
  };
  manageCard: UserCardItem[]; // 应用平台卡列表
  nowManageCard: UserCardItem;
  isShowCardStatus: boolean; // 是否展示平台卡
  userCardData: UserCardItem[]; // 用户平台卡列表
  userCardStatus: boolean;
  isShowInvoiceModal: boolean; // 是否展示发票类型
  isOpenInvoiceStatus: boolean; // 是否开发票
  nowInvoiceId: number;
}

const initialState: State = {
  rechargeIdx: 0, //储值支付tab当前索引
  rechargeInfoStatus: false, // 是否展示储值内容
  isShowCardModal: false,
  isShowCardStatus: true,
  rechargeArr: [],
  userPayInfo: [],
  initMoneyArr: [],
  isUsedAppCardStatus: false,
  manageCard: [],
  nowManageCard: {
    id: 0,
    platform_card_data_id: 0, // 应用平台卡id,
    card_name: '', //卡名称,
    card_type: 1, // 卡类型：1，按金额；2，按次数；3，按时间；,
    card_amount: 0, // 金额（card_type为1时有效）,
    card_num: 0, // 使用次数（card_type为2时有效）,
    card_duration: 0, // 时长秒数（card_type为3时有效）,
    sale_amount: 0, // 销售金额,
    remain_card_amount: 0, // 剩余金额（card_type为1时有效）,
    remain_card_num: 0, // 剩余使用次数（card_type为2时有效）,
    card_expire: '', // 到期时间（card_type为3时有效）,
    full_reduction: 0, // 使用满减 1是 0否,
    full_discount: '', //满减折扣,
    every_reduction: false, // 使用满减 1是 0否,
    every_discount: '', // 每减折扣,
    unified_offer: false, // 统一优惠状态0:关闭1：开启,
  },
  disStatus: {
    discount: 1,
    coupon: 1,
    point: 1,
  },
  rechargeStoreInfo: {
    recharge_id: 0,
    key: 0,
    account_id: 0,
    recharge_money: 0,
    recharge_send_money: 0,
    order_amount: 0,
    recharge_balance: 0
  },
  morePayStatus: false,
  rechargeTabs: [],
  rechargeRuleIdx: -2, // 储值支付选中支付项目
  openRechargeStatus: false,
  bottomPay: {
    money: 0,
    amount: 0
  },
  disMutex: {
    radio: [],
    checkbox: []
  },
  bottomMorePay: [],
  showInitPay: [],
  nowOilItem: {
    goods: { id: 0, name: "", price: "", type: {id: 0, name: ""}},
    goods_id: 0,
    id: 0,
    name: ""
  },
  nowPayItem: {
    money: 0,
    mutex: {
      radio: {},
      checkbox: {},
      platform: {}
    },
    point_dec_money: 0,
    coupon_dec_money: 0,
    discount_dec_money: 0,
    amount: "0",
    recharge_dec_money: "0",
    payment_code: "",
    account_id: 0,
    account_name: '',
    balance: "0",
    selected: true,
    platform: {
      platform_card: {
        card_name: '',
        card_url: ''
      },
      platform_sale_amount: 0,
      platform_card_data_id: ''
    }
  },
  payParams: {
    cate_id: 0,
    money: 0,
    payment_code: "",
    sn: getLosParam('sn'),
    room_id: getLosParam('room_id'),
    qrcode_id: getLosParam('qrcode_id'),
    source: Tools.getSystemPayEnv(),
    account_id: 0,
    point: 1,
    coupon: 1,
    platform: 1,
    coupon_ids: "",
    discount: 1,
    recharge_id: "",
    key: "",
    pay_password: "",
    return_url: "",
    receipt_id: 0,
    recharge_multiple: 0
  },
  nowRechargeRule: {
    charge_money: 0,
    money_status: '0',
    money: 0,
    point_status: '0',
    point: 1,
    coupon_status: '0',
    coupon_ids: "",
    liter_good_money: 0,
    is_unify: '0',
    lock_good_money: 0,
    is_lock: '0',
    first_discount: '0',
    first_pay_discount: "",
    coupon_data: [],
    good_status: '0',
    good_name: '',
    is_show: 0
  },
  userCardData: [],
  userCardStatus: false,
  isShowInvoiceModal: false,
  isOpenInvoiceStatus: false,
  nowInvoiceId: 0
};

export const paySlice = createSlice({
  name: "pay",
  initialState,
  reducers: {
    // 是否使用发票
    setIsOpenInvoiceStatus(state, action) {
      state.isOpenInvoiceStatus = action.payload;
    },
    // 发票id
    setNowInvoiceId(state, action) {
      state.nowInvoiceId = action.payload;
    },
    // 重置当前优惠
    resetPayItem(state) {
      state.payParams = initialState.payParams;
      // initialState.nowPayItem = initialState.nowPayItem;
    },
    // 设置快速输入金额
    setInitMoneyArr(state, action) {
      state.initMoneyArr = action.payload;
    },
    // 设置当前优惠
    setDisMutex(state, action) {
      state.disMutex = action.payload;
    },
    // 设置当前油枪
    setOilType(state, action) {
      state.nowOilItem = action.payload;
    },
    // 设置更多支付方式
    setMorePayStatus(state, action) {
      state.morePayStatus = action.payload;
    },
    // 付款页是否展示平台卡
    isShowPayCard(state, action) {
      state.isShowCardStatus = action.payload;
    },
    // 隐藏、显示平台卡列表
    setUserCardModal(state, action) {
      state.isShowCardModal = action.payload;
    },
    // 是否购买平台卡
    setUsedCardStatus(state, action) {
      state.isUsedAppCardStatus = action.payload;
    },
    // 重置当前选中平台卡
    resetChooseCard(state) {
      state.nowManageCard = initialState.nowManageCard;
    },
    // 设置平台卡列表
    setManageCardData(state, action) {
      state.manageCard = action.payload;
    },
    // 设置当前使用应用平台卡
    setNowUsedManageCard(state, action) {
      state.nowManageCard = action.payload;
    },
    // 设置用户平台卡列表
    setUserCardData(state, action) {
      state.userCardData = action.payload;
    },
    // 整理优惠切换方式
    setPayParam(state, action) {
      Object.keys(action.payload).forEach((item: string) => {
        state.payParams[item] = action.payload[item];
      })
    },
    // 切换Tab储值支付内容
    setRechargeItem(state, action) {
      state.rechargeIdx = action.payload;
      state.rechargeRuleIdx = -2;
      state.rechargeInfoStatus = false;
    },
    setRechargeInfoStatus(state, action) {
      state.rechargeInfoStatus = action.payload;
    },
    // 设置储值项支付优惠
    setRechargeStoreInfo(state, action) {
      state.rechargeStoreInfo = action.payload
    },
    // 设置当前选中储值项
    setRechargeRuleIdx(state, action) {
      const {item, index} = action.payload;
      if (state.rechargeRuleIdx === index) {
        state.rechargeRuleIdx = -2;
        state.openRechargeStatus = false;
        state.nowRechargeRule = initialState.nowRechargeRule;
      } else {
        state.rechargeRuleIdx = index;
        state.openRechargeStatus = true;
        state.nowRechargeRule = item;
      }
    },
    setNowRechargeItem(state, action) {
      state.nowRechargeRule = action.payload;
    },
    // 取消储值支付
    closeRechargeStatus(state) {
      state.rechargeRuleIdx = -2;
      state.openRechargeStatus = false;
      // state.nowRechargeRule = initialState.nowRechargeRule;
      // state.rechargeIdx = -1;
    },
    // 设置储值支付Tab
    setRechargeArr(state, action) {
      state.rechargeTabs = [];
      action.payload.forEach((item: RechargeStore, index: number) => {
        let obj = {title: ""};
        obj.title = item.account_name;
        if (Array.isArray(item.recharge_item)) {
          item.recharge_item.rule = []
        }
        state.rechargeTabs.push(obj);
      })
      state.rechargeArr = action.payload
    },
    // 分割支付方式
    setUserPayInfo(state, action) {
      state.userPayInfo = action.payload;
      state.showInitPay = [];
      state.bottomMorePay = [];
      for (let i in action.payload) {
        if (!action.payload[i].hasOwnProperty("recharge")) {
          if (BalanceArr.indexOf(action.payload[i].payment_code) > -1) {
            // @ts-ignore
            state.showInitPay.push(action.payload[i]);
          } else {
            // @ts-ignore
            state.bottomMorePay.push(action.payload[i]);
          }
        }
      }
    },
    // 设置当前支付方式
    setUserNowPay(state, action) {
      const {amount, money} = action.payload.item;
      state.nowPayItem = action.payload.item;
      state.bottomPay = {amount, money};
    },
    // 支付面板切换
    changeShowPay(state, action) {
      state.showInitPay.forEach((item: any, index: number) => {
        if (index === action.payload.idx) {
          state.showInitPay[index].selected = true;
        } else {
          state.showInitPay[index].selected = false;
        }
      })
      state.bottomMorePay.map((item: any) => item.selected = false);
    },
    // 更多支付方式切换
    changeMorePay(state, action) {
      state.bottomMorePay.forEach((item: any, index: number) => {
        if (index === action.payload.idx) {
          state.bottomMorePay[index].selected = true;
        } else {
          state.bottomMorePay[index].selected = false;
        }
      })
      state.showInitPay.map((item: any) => item.selected = false);
    },
    // 优惠切换
    updateDisStatus (state, action) {
      const {box_type, status_item, status} = action.payload;
      // @ts-ignore
      let obj = state.nowPayItem.mutex[box_type];
      if (Object.keys(obj).length > 0) {
        Object.keys(obj).forEach(item => {
          if (box_type === 'radio') {
            if (item === status_item) {
              if (status_item === "coupon") {
                console.log('coupon====>', )
                state.nowPayItem.mutex.radio[status_item].checked = true;
              } else {
                state.nowPayItem.mutex.radio[status_item].checked = status;
              }
            } else {
              state.nowPayItem.mutex.radio[item].checked = false;
            }
          } else {
            if (status_item === "coupon") {
              state.nowPayItem.mutex.checkbox[status_item].checked = true;
            } else {
              state.nowPayItem.mutex.checkbox[status_item].checked = status;
            }
          }
        });
      }
    },
  },
});

const {
  setDisMutex,
  setUserPayInfo,
  setUserNowPay,
  setRechargeArr,
  changeMorePay,
  changeShowPay,
  closeRechargeStatus,
  setRechargeStoreInfo,
  setInitMoneyArr,
  setManageCardData,
  setUserCardData,
  setNowUsedManageCard,
  isShowPayCard,
} = paySlice.actions;

export const payAppInfo = (state: RootState) => state.payInfo;

// 设置快速输入金额
export const setInitMoney = ()=> async (disPatch: AppDispatch) => {
  try {
    const urlParam = Tools.param2Obj();
    let app_id = '';
    if (urlParam.hasOwnProperty('app_id')) {
      app_id = urlParam.app_id;
    } else {
      app_id = getStorage('app_id');
    }
    const res = await initMoneySetting(app_id);
    if (res.code === 200) {
      if (!res.data.hide_recharge_button) {
        disPatch(setInitMoneyArr([...res.data.custom_money, "充值"]));
        setStorage('money-arr', [...res.data.custom_money, "充值"]);
      } else {
        disPatch(setInitMoneyArr([...res.data.custom_money]));
        setStorage('money-arr', [...res.data.custom_money]);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

// 更新优惠内容
export const updateDisInfo = (item: PayTypeStore, type: "show" | "more") => (dispath: AppDispatch) => {
  try {
    let obj = {
      radio: Tools.changeDisDate(item.mutex.radio),
      checkbox: Tools.changeDisDate(item.mutex.checkbox)
    }
    // 更新优惠
    dispath(setDisMutex(obj));
  } catch (e) {
    console.log(e);
  }
}

// 获取支付方式&&更新优惠
export const updatePayTypeInfo = (data: OrderCompute) => async (disPath: AppDispatch) => {
  try {
    Toast.loading('计算优惠中...');
    let obj: any = {};
    obj.sn = getLosParam('sn');
    obj.room_id = getLosParam('room_id');
    obj.qrcode_id = getLosParam('qrcode_id');
    Object.keys(obj).forEach(item => {
      if (!obj[item]) delete obj[item];
    })
    let arr = Object.keys(data).filter(item => data[item] !== "" && data[item] !== null);
    arr.forEach(item => obj[item] = data[item]);
    // if (obj.cate_id === 0) {
    //   obj.cate_id = JSON.parse(getStorage('oil-info')).id;
    // }
    if (getLosParam('qrcode_id') || getLosParam('sn') || getLosParam('room_id')) {
      const res = await computeUserOrder(obj);
      if (res.code === 200) {
        disPath(setUserPayInfo(res.data));
        let initArr = res.data.filter(((item: PayTypeStore) => item.selected))
        disPath(updateDisInfo(initArr[0],"show"));
        disPath(setUserNowPay({item: initArr[0]}));
        // 支付方式兜底
        setStorage('payment_code', initArr[0].payment_code);
        disPath(changeRechargeDisInfo(data.recharge_id, res.data));
        Toast.hide();
      } else {
        Toast.info(res.msg);
      }
    } else {
      Toast.info('获取参数失败.请重新扫码')
    }
  } catch (e) {
    Toast.hide();
    console.log(e)
  }
}

// 配置用户已购买平台卡
export const setUserBuyCardList = (goods_id: number, cate_id: number)=> async (disPatch: AppDispatch) => {
  try {
    const arr = ['full_reduction', 'unified_offer', 'goods_offer', 'cate_offer', 'every_reduction']
    const res = await getUserCardList(1, goods_id, cate_id);
    if (res.code === 200) {
      if (res.data.length > 0) {
        let data: any = [];
        res.data.forEach((item: any) => {
          data.push(Tools.changeBool(item, arr));
        });
        await disPatch(isShowPayCard(false));
        await disPatch(setUserCardData(data));
      }
    }
  } catch (e) {
    console.log(e)
  }
}

// 配置应用平台卡
export const setManageCardList = (goods_id: number, cate_id: number)=> async (disPatch: AppDispatch) => {
  const arr = ['full_reduction', 'unified_offer', 'goods_offer', 'cate_offer', 'every_reduction']
  const res = await getManageCardList(goods_id, cate_id);
  if (res.code === 200) {
    let data: any = [];
    if (res.data.length>0) {
      res.data.forEach((item: any) => {
        item.platform_card_data_id = item.id;
        item.card_name = item.name;
        data.push(Tools.changeBool(item, arr));
      });
      disPatch(setNowUsedManageCard(data[0]))
      await disPatch(isShowPayCard(true));
    } else {
      await disPatch(isShowPayCard(false));
    }
    await disPatch(setManageCardData(data));
  } else {
    await disPatch(isShowPayCard(false));
  }
}

// 获取储值支付方式
export const setRechargeInfo = (data: RechargeParam) => async (disPatch: AppDispatch) => {
  try {
    const res = await getRechargeInfo(data);
    if (res.code === 200) {
      if (res.data.length > 0) {
        res.data.forEach((item: RechargeStore) => {
          item.account_name = `${item.account_name}(${item.balance}元)`
        })
      }
      disPatch(setRechargeArr(res.data));
    }
  } catch (e) {
    console.log(e)
  }
}

// 支付状态切换
export const changePayItem = (item: PayTypeStore, idx: number, type: "show" | "more") => async (disPath: AppDispatch) => {
  try {
    if (type === "show") {
      disPath(changeShowPay({item, idx}))
    } else {
      disPath(changeMorePay({item, idx}))
    }
    disPath(closeRechargeStatus());
  } catch (e) {
    console.log(e)
  }
}

// 储值项目切换显示优惠信息
export const changeRechargeDisInfo = (rechargeId: string, arr: Array<any> | null) => async (disPath: AppDispatch) => {
  try {
    if (arr) {
      let arrIdx = -1;
      arr.forEach((item, index) => {
        if (item.hasOwnProperty('recharge')) {
          // eslint-disable-next-line
          if (item.recharge.recharge_id == rechargeId) {
            arrIdx = index;
          }
        }
      });
      console.log(arr)
      if (arrIdx > -1) disPath(setRechargeStoreInfo(arr[arrIdx].recharge));
    }
  } catch (e) {
    console.log(e)
  }
}

// 用户提交订单
export const userCreateOrder = (data: OrderCompute) => async (disPatch: AppDispatch) => {
  try {
    let obj = {
      sn: getLosParam('sn'),
      room_id: getLosParam('room_id'),
      qrcode_id: getLosParam('qrcode_id'),
    }
    Object.keys(obj).forEach((item: string) => {
      // @ts-ignore
      if (!obj[item]) delete obj[item];
    })
    let param = Object.assign({}, data, obj);
    if (param.cate_id === 0) {
      param.cate_id = JSON.parse(getStorage('oil-info')).id;
    }
    Object.keys(param).forEach(item =>  {
      if (param[item] === "" || param[item] === null || param[item] === 'null' || param[item] === "undefined") {
        delete param[item];
      }
    })
    console.log(param)
    if (!param.payment_code) param.payment_code = getStorage('payment_code');
    return await createOrder(param);
  } catch (e) {
    console.log(e);
  }
}
