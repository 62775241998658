import React, {FC, useState} from "react";
import {useMount} from "ahooks";
import {useParams} from "react-router";
import { getPrizeAwardDetail } from "@api/rewards";
import './index.scss'

const RewArdDetAil: FC = ()=> {
  const urlParam = useParams();

  const [awardInfo, setAwardInfo] = useState({
    award_name: '',
    order_sn: '',
  });

  useMount(()=> {
    getGoodsDetail(Number(urlParam.id))
  })

  const getGoodsDetail = async (id: number)=> {
    const res = await getPrizeAwardDetail(id);
    if (res.code === 200) {
      setAwardInfo(res.data);
    }
    console.log(res);
  }

  return(
    <div className={'detail-all'}>
      <div className={'detail-center'}>
        <div className={'detail-title'}>{awardInfo?.award_name}</div>
        {/*<div className={'detail-qx'}>兑奖期限: 2020-11-22 至 2010-12-12</div>*/}
        <div className={'detail-dj'}>兑奖码: {awardInfo?.order_sn}</div>
        <div className={'detail-bot'}>
          <div className={'detail-d'}/>
          <div className={'detail-d-2'}/>
          <div className={'detail-b-text'}>兑奖详情</div>
          {/*<div className={'detail-qx-date'}>*/}
          {/*  <span>兑奖期限: </span>*/}
          {/*  <span>1020-21-12 至 2010-21-21</span>*/}
          {/*</div>*/}
          <div className={'detail-qx-date'}>
            <span>可用时段: </span>
            <span>周一至周日</span>
          </div>
          <div className={'detail-qx-date'}>
            <span>注意事项: </span>
            <span>凭券联系现场工作人员兑奖</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RewArdDetAil;