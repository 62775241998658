import {List, Toast} from "antd-mobile";
import React, {FC, useState} from "react";
import {useParams} from "react-router";
import {InputItem, Button} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import {setInitPassword, verifyPayPass, editPassword} from "@api/user";

const StrPwd = /^\d{1,}$/;

const Password: FC = ()=> {
  const urlParam = useParams();
  const navigate = useNavigate();
  const {type, entry} = urlParam;
  const [newPwd, setNewPwd] = useState('');
  const [newRepeatPwd, setRepeatNewPwd] = useState('');
  const [hasError, setHasError] = useState(false);
  const [repeatHasError, setRepeatHasError] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(true);
  const [updateOldPwd, setUpdateOldPwd] = useState('');
  const [updateError, setUpdateError] = useState(false);

  const ListTitle = (str: string)=> {
    return (
      <div className={'pwd-title'}>{str}</div>
    )
  }

  const setNewPassword = (e: string)=> {
    if (!StrPwd.test(e) && e.length === 6) {
      setHasError(true)
    } else {
      setHasError(false)
    }
    setNewPwd(e);
  }

  const setRepeatPassword = (e: string)=> {
    setRepeatNewPwd(e);
    if (newPwd === e) {
      setRepeatHasError(false);
    } else {
      setRepeatHasError(true);
    }
  }

  const setUpdatePwd = (val: string)=> {
    if (!StrPwd.test(val) && val.length === 6) {
      setUpdateError(true)
    } else {
      setUpdateError(false)
    }
    setUpdateOldPwd(val)
  }

  const makePassword = async ()=> {
    try {
      if(newPwd === newRepeatPwd && newPwd.length === 6) {
        // 设置密码
        const res = await setInitPassword(newPwd);
        if (res.code === 200) {
          Toast.success('设置成功');
          setTimeout(()=> {
            if (entry === 'pay') {
              navigate('/cardpay');
            }
          }, 1000)
        }
      } else {
        Toast.info('输入密码不一致');
      }
    } catch (e) {
      console.log(e)
    }
  }

  // 切换显示组件
  const changePwdView = async ()=> {
    try {
      if (updateOldPwd.length === 6 && StrPwd.test(updateOldPwd)) {
        // 修改密码
        if (updateStatus) {
          const res = await verifyPayPass(updateOldPwd);
          if (res.code === 200) {
            Toast.info('验证成功!')
            setUpdateStatus(false)
          }
        } else {
          const res_info = await editPassword(newRepeatPwd);
          if (res_info.code === 200) {
            Toast.success('设置成功');
            if (entry === 'pay') {
              navigate('/cardpay');
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const passwordCon = ()=> {
    return (
      <List renderHeader={()=> ListTitle('设置您的六位数字支付密码')}>
        <InputItem
          type="password"
          placeholder="******"
          maxLength={6}
          value={newPwd}
          error={hasError}
          onErrorClick={()=> Toast.fail('请输入六位数字密码')}
          onChange={(event)=> setNewPassword(event)}
        >请输入密码</InputItem>
        <InputItem
          type="password"
          placeholder="******"
          maxLength={6}
          error={repeatHasError}
          value={newRepeatPwd}
          onErrorClick={()=> Toast.fail('请与上方密码保持一致')}
          onChange={(event)=> setRepeatPassword(event)}
        >请重复输入密码</InputItem>
      </List>
    )
  }

  const setPassword = ()=> {
    return (
      <div className={'pwd-con'}>
        {passwordCon()}
        <div className={'make-btn'}>
          <Button type="primary" onClick={()=> makePassword()}>确定</Button>
        </div>
      </div>
    )
  }

  const checkOldPwd = ()=> {
    if (updateStatus) {
      return (
        <List renderHeader={()=> ListTitle('修改六位数字支付密码')}>
          <InputItem
            type="password"
            placeholder="******"
            value={updateOldPwd}
            error={updateError}
            maxLength={6}
            onErrorClick={()=> Toast.fail('请输入六位数字密码')}
            onChange={(event)=> setUpdatePwd(event)}
          >旧密码
          </InputItem>
        </List>
      )
    }
    return passwordCon();
  }

  const updatePassword = ()=> {
    return (
      <div className={'pwd-con'}>
        {checkOldPwd()}
        <div className={'make-btn'}>
          <Button type="primary" onClick={()=> changePwdView()}>确定</Button>
        </div>
      </div>
    )
  }

  if (type === 'set') return setPassword();
  if (type === 'update') return updatePassword();
  return setPassword();

}

export default Password;
