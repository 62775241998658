
export interface TabItem {
  title: string;
  path: string;
  icon: string;
}

export const TabArr: Array<TabItem> = [
  {
    title: '首页',
    path: '/',
    icon: 'iconshouye',
  },
  {
    title: '油站',
    path: '/oilmap',
    icon: 'iconziyuan',
  },
  {
    title: '商城',
    path: '/shop',
    icon: 'iconjifen1',
  },
  {
    title: '我的',
    path: '/person',
    icon: 'iconwode',
  }
]