import React, {FC} from "react";
import {List, Checkbox} from 'antd-mobile';
import {MyIcon} from "@components/AppIcon";
import {PayTypeStore} from "@view/user_pay/pay_store_interface";
import {payAppInfo, BalanceArr} from "@store/pay.slice";
import {useDispatch, useSelector} from "react-redux";
import * as payStore from "@store/pay.slice";
import {Brief} from "antd-mobile/lib/list/ListItem";
import RechargePay from "@view/user_pay/paytype/recharge";
import './pay_type.scss';

interface PayUsedTypeProps {
  type: "more" | "show";
  payMoney?: string|undefined;
}

const PayUsedType: FC<PayUsedTypeProps> = (props) => {
  const {type, payMoney} = props;
  const payinfo = useSelector(payAppInfo);
  const {showInitPay, bottomMorePay,} = payinfo;
  const {
    setMorePayStatus,
    setUserNowPay
  } = payStore.paySlice.actions
  const dispatch: (...args: unknown[]) => Promise<any> = useDispatch();

  const PayIcon = (payment_code: string) => {
    switch (payment_code) {
      case 'wxpay':
        return 'iconweixin';
      case 'balance':
        return 'iconyu';
      case 'cash':
        return 'iconyue';
      case 'card_balance':
        return 'iconyoukaxitong';
      default:
        return 'iconyue'
    }
  }

  const updateDisInfo = async (item: PayTypeStore, index: number) => {
    // 更新优惠
    await dispatch(payStore.updateDisInfo(item, type));
    await dispatch(payStore.changePayItem(item, index, type));
    await dispatch(setUserNowPay({item: item}));
    if (type === "more") {
      await dispatch(setMorePayStatus(false));
    }
  }


  const PayItemExtra = (item: PayTypeStore,) => {
    if (BalanceArr.indexOf(item.payment_code) > -1) {
      return (
        <div className={'dis-extra'}>
          <span>{item.balance}元</span>
          <Checkbox checked={item.selected}/>
        </div>
      )
    }
    return <Checkbox checked={item.selected}/>
  }

  // 支付项目子元素
  const PayItem = (item: PayTypeStore, index: number) => {
    return (
      <List.Item
        key={index}
        onClick={() => updateDisInfo(item, index)}
        multipleLine
        thumb={<MyIcon className={'pay-icon'} type={PayIcon(item.payment_code)}/>}
        extra={PayItemExtra(item)}
      >
        {item.account_name}
        {Number(item.recharge_dec_money) > 0 && <Brief>优惠{item.recharge_dec_money}元</Brief>}
      </List.Item>
    )
  }

  const showView = () => {
    if (type === "show") {
      return showInitPay.map((item: any, index: number) => {
        return PayItem(item, index)
      })
    }
    return bottomMorePay.map((item: any, index: number) => {
      return PayItem(item, index)
    })
  }

  return <List className={'pay-type'}>
    {showView()}
    {type === "show" && <RechargePay payMoney={payMoney}/>}
  </List>
}

export default PayUsedType;