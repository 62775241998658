import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "store/index";

export interface State {
  ModelStatus: boolean;
  CouponArr: {
    [key: string]: Array<State['CouponItem']>
  }
  nowTabIndex: number;
  CouponItem: {
    title: string;
    max_use: number;
    dec_money: number;
    max_dec_money: number;
    coupon_data_ids: Array<number|string>
    exp_time: string;
    check_num: number;
    checked: boolean;
  }
}

const initialState: State = {
  ModelStatus: false,
  CouponArr: {
    "1":  [],
    "2":  [],
    "3":  [],
  },
  nowTabIndex: 2,
  CouponItem: {
    title: "",
    max_use: 0,
    dec_money: 0,
    max_dec_money: 0,
    coupon_data_ids: [],
    exp_time: "",
    check_num: 0,
    checked: false,
  }
};


export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setCouponArr(state, action) {
      state.CouponArr = action.payload;
    },
    setModelStatus(state, action) {
      state.ModelStatus = action.payload;
    },
    setCouponItem(state, action) {
      state.CouponItem = action.payload;
    },
    setNowTabIndex(state, action) {
      state.nowTabIndex = action.payload;
    },
    // 更改选中状态
    changeCouponArrStatus(state, action) {
      const { nowTabIndex, index } = action.payload;
      let key = (nowTabIndex+1).toString();
      state.CouponArr[key].forEach((item, idx) => {
        if (idx === index) {
          item.checked = !item.checked
        } else {
          item.checked = false;
        }
      })
    },
    // 更改使用张数
    changeStepperNumber(state, action) {
      const { nowTabIndex, index, num } = action.payload;
      let key = (nowTabIndex+1).toString();
      console.log(nowTabIndex)
      state.CouponArr[key].forEach((item, idx) => {
        if (idx === index && item.checked) {
          item.check_num = num;
        }
      })
    },
    // 默认使用优惠券
    initUsedCoupon(state, action) {
      try {
        if(state.CouponArr[action.payload] instanceof Array) {
          if ((state.CouponArr[action.payload].length>0)) {
            if (state.CouponArr[action.payload][0].checked) {
              state.CouponItem = action.payload[0];
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 不使用优惠券
    noUsedCoupon(state) {
      state.CouponItem = {
        title: "",
        max_use: 0,
        dec_money: 0,
        max_dec_money: 0,
        coupon_data_ids: [],
        exp_time: "",
        check_num: 0,
        checked: false,
      }
    }
  },
});

export const couponAppInfo = (state: RootState) => state.couponInfo;

