import React, {useState} from "react";
import {Modal, Tabs } from "antd-mobile";
import { MyIcon } from "@components/AppIcon";
import {useMount} from "ahooks";
import { getCouponData } from "@api/user";
import { getCouponDetail } from "@api/rewards";
import './index.scss'


interface CouponState {
  id: number;
  exp_time: string;
  coupon: {
    title: string;
    type: number;
  }
}

const CouponList: React.FC = ()=> {
  const [couponArr, setCouponArr] = useState<Array<CouponState>>([]);
  const [tabIdx, setTabIdx] = useState(0);
  const [page] = useState(1);
  const tabs = [ { title: '可使用'}, { title: '不可用'}];

  useMount(() => {
    setCouponDate(0);
  })

  const changeTabData = async (idx: number)=> {
    setCouponArr([]);
    setTabIdx(idx);
    await setCouponDate(idx);
  }

  const setCouponDate = async (is_used: number)=> {
    try {
      const res = await getCouponData(0, is_used, page);
      if (res.code === 200) {
        setCouponArr([...res.data.result]);
      }
      console.log(res)
    } catch (e) {
      console.log(e)
    }
  }

  const showQrcode = async (id: number)=> {
    try {
      const res = await getCouponDetail(id);
      if (res.code === 200) {
        if (res.data.card_num_url) {
          Modal.alert('核销码', <img src={res.data.card_num_url} alt={'核销码'}/>)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const couponsItem = (item: CouponState)=> {
    return (
      <div className={'coupon-item'} key={item.id}>
        <div className={'coupon-left'}> {item.coupon.type === 1? '满':'折'} </div>
        <div className={'coupon-center'}>
          <span>{item.coupon.title}</span>
          <span>{item.exp_time}</span>
        </div>
        <div className={'coupon-right'} onClick={()=> showQrcode(item.id)}>
          <div className={'coupon-code'}>
            <MyIcon type={'iconscan'}></MyIcon>
          </div>
          <span className={'coupon-code-text'}>出示核销</span>
        </div>
      </div>
    )
  }

  // @ts-ignore
  return (
    <div>
      <div className={'top-header'}>
        <Tabs
          tabs={tabs}
          initialPage={tabIdx}
          onTabClick={(tab, index) => { changeTabData(index) }}
        >
        </Tabs>
      </div>
      <div className={'coupon-list'}>
        {couponArr.map(item => couponsItem(item))}
      </div>
    </div>
  )
}

export default CouponList;