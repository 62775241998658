import React  from 'react';
import './index.scss';

const PointShop = ()=> {
  return (
    <div className={'point-con'}>
      <div className={'point-search'}>
        <div className={'top-banner'}>顶部banner</div>
        <div className={'top-info'}>
          <div className={'person-info'}>
            <div className={'left-info'}>
              <div className={'person-info-img'}></div>
              <span className={'nick-info'}>微信昵称xxx</span>
            </div>
            <div>分享</div>
          </div>
          <div className={'info-fun'}>
            <div>
              <span>0</span>
              <span>积分</span>
            </div>
            <div>
              <span>0</span>
              <span>订单</span>
            </div>
            <div>
              <span>0</span>
              <span>卡券</span>
            </div>
          </div>
        </div>
      </div>
      <div className={'ad-text'}>公告栏</div>
      <div className={'point-list'}>商品列表</div>
    </div>
  )
}

export default PointShop