import React, {useState} from "react";
import {Button} from 'antd-mobile';
import {useMount} from "ahooks";
import { getHomeBanner } from "@api/app";
import HomeBanner from './banner';
import {MyIcon} from '@components/AppIcon';
import './home.scss';

const Home =  () => {

  const [bannerArr, setBannerArr] = useState([]);

  useMount(() => {
    setBannerImages();
  })

  // 获取首页banner
  const setBannerImages = async ()=> {
    try {
      const res = await getHomeBanner();
      if (res.code === 200 && res.data.length>0) {
        setBannerArr(res.data);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const bannerTop = ()=> {
    return (
      <div className={'banner-info'}>
        <HomeBanner bannerArr={bannerArr}/>
      </div>
    )
  }

  return (
    <>
      <div className={'home-top'}>
        {bannerTop()}
        <div className={'home-info'}>
          <div className={'home-info-name'}>
            <div className={'home-info-header'}>
              <div className={'home-info-img'}>
                <img alt={''} src={'http://zht.58zltc.com/public/uploads/2021022515540939ebe2889.png'}/>
              </div>
              <div className={'home-store-name'}>
                <span>智慧油站</span>
                <span>山东省济南市历下区</span>
              </div>
              <MyIcon className={'home-map'} type={'icondaohang'} />
            </div>
            <div className={'home-ercode'}>
              <MyIcon className={'home-ercode-icon'} type={'iconicon_erweima'} />
              <span>会员码</span>
            </div>
          </div>
          <div className={'home-info-bottom'}>
            <div className={'info-bottom-item'}>
              <span>200</span>
              <span>余额</span>
            </div>
            <div className={'info-bottom-item'}>
              <span>200</span>
              <span>积分</span>
            </div>
            <div className={'info-bottom-item'}>
              <span>200</span>
              <span>优惠券</span>
            </div>
          </div>
        </div>
      </div>
      <div className={'home-center'}>
        <div className={'home-center-left'}>左侧油价</div>
        <div className={'home-center-right'}>
          <div>充值有礼</div>
          <div>领券中心</div>
        </div>
      </div>
      <Button type="primary" className={'add-info'}>一键加油</Button>
    </>
  )
}

export default Home