import React, {FC, useRef, useState} from "react";
import {useUpdateEffect} from "ahooks";

interface RewardBgMusicState {
  isPlay: boolean;
}

const RewardBgMusic: FC<RewardBgMusicState> = (props)=> {

  const bg_music: any = useRef();
  const [isPlay, setIsPlay] = useState(false);

  useUpdateEffect(()=> {
    if (props.isPlay) {
     return startPlayMusic()
    }
    return stopPlayMusic();
  }, [props.isPlay])

  // 开始音乐
  const startPlayMusic =()=> {
    const musicC = bg_music.current;
    console.log(bg_music)
    if (musicC) musicC.play();
    setIsPlay(true);
  }
  //
  // 暂停音乐
  const stopPlayMusic =()=> {
    if (isPlay) {
      const musicC = bg_music.current;
      if (musicC) musicC.pause();
    }
  }

  return (
    <div>
      <audio ref={bg_music} id="player" autoPlay loop>
        <source src="https://zltc.oss-cn-shanghai.aliyuncs.com/13800.mp3" type="audio/mp3" />
      </audio>
    </div>
  )
}


export default RewardBgMusic;