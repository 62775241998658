import React, {FC, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {List, InputItem, Toast, Modal, Checkbox, Button} from "antd-mobile";
import {OilTypeState, payAppInfo} from "@store/pay.slice";
import {setAppUserInfo, userAppInfo} from "@store/auth.slice";
import {MyIcon} from "@components/AppIcon";
import {useMount, useUpdateEffect} from "ahooks";
import {getStorage} from "@utils/my_storage";
import * as payStore from "@store/pay.slice";
import {RightOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import BalancePay from "@components/BalancePay/index";
import MorePayTypeView from "@view/user_pay/paytype/pay_more_type";
import DisAppInfo from "./dis_info";
import PayUsedType from "./paytype/pay_type";
import BottomPay from "./user_pay_bottom";
import InvoiceInfo from './invoice/index';
import UserCardInfo from "@view/user_pay/user_card/index";
import {cardDisItem} from "@view/user_pay/user_card/card_tools";
import Tools from "@utils/tools";
import { getAppPageConfig } from "@api/app";
import { useNavigate } from "react-router";
import "./pay.scss";
import { Dialog } from "antd-mobile-v5";

// 支付内容
const UserPay: FC = (props) => {
  const navigator = useNavigate();
  const payInfo = useSelector(payAppInfo);
  const userInfo = useSelector(userAppInfo);
  const { user_pay_info } = userInfo;
  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();
  const inputRef = useRef<any>(null);
  const [mainCenter, setMainCenter] = useState<boolean>(false);
  const [payMoney, setPayMoney] = useState<string | undefined>('');
  const [inputStatus, setInputStatus] = useState(false);
  const [isShowOilModal, setShowOilModal] = useState(false); // 油枪界面展示
  const [userMoney, setUserMoney] = useState(0);

  const {
    nowOilItem,
    isUsedAppCardStatus,
    isShowCardModal,
    nowManageCard,
    isShowCardStatus,
    // nowPayItem,
  } = payInfo;

  const {
    setPayParam,
    setUsedCardStatus,
    setUserCardModal,
    closeRechargeStatus,
    setOilType
  } = payStore.paySlice.actions;

  useMount(async () => {
    await initUsedPoint();
    await disPatch(setAppUserInfo());
    if (inputRef) inputRef.current?.focus();
  })

  useUpdateEffect( ()=> {
    let obj = { platform_card_data_id: isUsedBuyCard()}
    let params = Object.assign(obj, payInfo.payParams);
    if (userMoney > 0 || Number(payMoney) > 0) {
      getRecharges(nowOilItem)
      disPatch(payStore.updatePayTypeInfo(params));
    }
  }, [payInfo.payParams])

  // 是否默认使用积分抵扣
  const initUsedPoint = async ()=> {
    try {
      const res = await getAppPageConfig('default_use_point');
      if (res.code === 200) {
        await disPatch(setPayParam({ point: res.data.status}));
      }
    } catch (e) {
      console.log(e)
    }
  }

  // 是否购买会员卡
  const isUsedBuyCard = ()=> {
    if (isShowCardStatus && isUsedAppCardStatus) return nowManageCard.id;
    return 0;
  }

  // 储值信息
  const getRecharges = (item: OilTypeState)=> {
    let obj = {
      cate_id: nowOilItem.id,
      room_id: userInfo.user_pay_info.room_id,
      money: userMoney
    };
    if (item) obj.cate_id = item.id;
    // if (obj.cate_id === 0) {
    //   obj.cate_id = JSON.parse(getStorage('oil-info')).id;
    //   obj.room_id = getStorage('room_id');
    // }
    disPatch(payStore.setRechargeInfo(obj));
  }

  // 支付输入框&&展示金额内容
  const changeUserInputStatus = () => {
    setTimeout(() => {
      if (inputRef) {
        inputRef.current?.focus();
      }
    })
    setInputStatus(false);
    setMainCenter(false);
  }

  // 清理当前当页面缓存信息
  const clearPayInfo = ()=> {
    // removeStorage('oil-info');
    // navigate(-1);
    // disPatch(resetPayItem());
    setShowOilModal(true);
  }

  // 展示金额状态
  const UserMainCenter = () => {
    if(inputStatus) return <UserInputMoney/>;
    return <UserInput/>;
  }

  // 切换油枪
  const updateOilItem = async (item: any)=> {
    await disPatch(closeRechargeStatus());
    await disPatch(setOilType(item));
    await getRecharges(item);
    await disPatch(payStore.setManageCardList(item.goods_id, item.goods.type.id))
    await disPatch(payStore.setUserBuyCardList(item.goods_id, item.goods.type.id))
    await disPatch(setPayParam({
      cate_id: item.id,
      money: userMoney,
      recharge_id: 0
    }))
    await setShowOilModal(false);
  }

  // 顶部内容
  const OilTop = () => {
    let initItem = nowOilItem;
    // 刷新后获取信息
    // if (initItem?.name === '') {
    //   if (getStorage('oil-info')) {
    //     initItem = JSON.parse(getStorage('oil-info'));
    //   }
    // }
    if (inputStatus) return OilTopView(initItem);
    return InitPayTopShow(initItem)
  }

  // 快速输入金额按钮
  const setMoney = async (item: string | undefined) => {
    if (item === '充值') {
      navigator(`/recharge_pay?qrcode_id=${getStorage('qrcode_id')}&room_id=${getStorage('room_id')}`)
    } else {
      // 计算优惠
      setUserMoney(Number(item));
      setInputStatus(true);
      setPayMoney(item);
      setMainCenter(true);
      await disPatch(closeRechargeStatus())
      await disPatch(setPayParam(
        {
          money: Number(item),
          cate_id: nowOilItem.id,
          account_id: payInfo.nowPayItem.account_id,
          recharge_id: 0,
        }
      ))
    }
  }

  // 确认支付
  const makeMoney = async (val: string | undefined) => {
    if (!val) return Toast.info('请输入正确金额');
    if (!Tools.checkMoneyStr(val)) return Toast.info('请输入正确金额');
    // 设置当前金额
    setUserMoney(Number(val));
    setInputStatus(true);
    setPayMoney(Number(val).toString());
    setMainCenter(true);
    // 清除储值支付
    await disPatch(closeRechargeStatus());
    await disPatch(setPayParam(
      {
        money: Number(val),
        cate_id: nowOilItem.id,
        account_id: payInfo.nowPayItem.account_id,
        payment_code: "",
        recharge_id: 0,
      }
    ))
  }

  // 快速充值按钮View
  const KeyHeader = () => {
    let arr = payInfo.initMoneyArr;
    if (arr.length === 0) {
      let arr_1 = getStorage('money-arr');
      if (arr_1) arr = arr_1.split(',');
    }
    if (arr.length === 0) return <></>
    return (
      <div className='money-all'>
        {arr.map((item, index) => {
          return (
            <div key={index} onClick={() => setMoney(item)}>{item}</div>
          )
        })}
      </div>
    )
  }

  // 平台卡优惠内容
  const disShow = ()=> {
    if (nowManageCard) {
      const arr = cardDisItem(nowManageCard);
      return arr?.map((item, index) =>
        <div className={'card-show-amount'} key={index}>{item.dis_str} </div>
      )
    }
  }

  const showMoreCard = (event: any)=> {
    event.stopPropagation();
    disPatch(setUserCardModal(true))
  }

  const setCardDisMoney = async ()=> {
    await disPatch(setUsedCardStatus(!isUsedAppCardStatus));
    if (!isUsedAppCardStatus) {
      await disPatch(setPayParam({
        platform: 1,
        platform_card_data_id: nowManageCard.platform_card_data_id
      }));
    } else {
      await disPatch(setPayParam({ platform: 0, platform_card_data_id: 0}));
    }
  }

  // 输入金额后顶部内容
  const OilTopView = (initItem: any)=> {
    return (
      <div className='oil-all-top'>
        <div className='oil-all-left'>
          <span>{user_pay_info?.room_name}</span>
          <span>￥{initItem.goods.price}/升</span>
        </div>
      </div>
    )
  }

  // 输入金额前顶部内容
  const InitPayTopShow = (initItem: any)=> {
    return (
      <div className='oil-all-init'>
        <div className='oil-all-left'>
          <span>{user_pay_info?.room_name}</span>
          <span>{initItem.goods.name}{initItem.name}</span>
        </div>
        <div className={'oil-all-right'}>
          <span>￥{initItem.goods.price}/升</span>
        </div>
      </div>
    )
  }

  // 选中平台卡后展示优惠
  // const chooseCardDis = ()=> {
  //   const { mutex: {checkbox,  radio}  } = nowPayItem;
  //   if (checkbox.hasOwnProperty('platform') && checkbox?.platform instanceof Object) {
  //     return (
  //       <span>{checkbox?.platform?.remain_str}</span>
  //     )
  //   }
  //   return (<span>{radio?.platform?.remain_str}</span> )
  // }

  // 平台卡优惠类型展示
  const cardDisType =()=> {
    const {card_type, card_amount} = nowManageCard;
    if (card_type === 1) {
      return (
        <>
          <div className={'card-show-amount'}>{card_amount}元</div>
        </>
      )
    } else return disShow();
  }

  // 平台卡
  // eslint-disable-next-line
  const cardInfo = ()=> {
    return (
      <div className={'card-show'} onClick={()=> setCardDisMoney()}>
        <div className={'card-show-main'}>
          <div className={'card-show-title'}>
            <MyIcon type={'iconVIP1'} />
            <span style={{marginLeft: '5px'}}>超级会员卡</span>
          </div>
          <span className={'card-more'} onClick={(e)=>showMoreCard(e)}>更多 <RightOutlined/>  </span>
        </div>
        <div className={'card-title-info'}>
          <span>开通超级会员,每月</span>
          <span>享受超级优惠</span>
        </div>

        <div className={'card-show-main'}>
          <div className={'card-show-dis'}>
            {cardDisType()}
            {/*{disShow()}*/}
            {/*{chooseCardDis()}*/}
          </div>
          <div className={'card-show-right'}>
            <div>
              <span style={{marginRight: '10px', color: '#6d4b23'}}>￥{nowManageCard.sale_amount}</span>
              <Checkbox checked={isUsedAppCardStatus}/>
            </div>
          </div>
        </div>
        <div className={'card-info-one'}>
          <span>{nowManageCard.is_first_discount === 1 ? '享受优惠':'首单不可用'} </span>
          {nowManageCard.card_type !== 3 && Number(nowManageCard?.day) > 0 && <span> | {nowManageCard.day}天内有效</span>}
        </div>
        <div className={'card-info-two'}>
          <span>在享油站超级专享特权</span>
        </div>
      </div>
    )
  }

  // 是否展示应用平台卡列表
  const UserCardShow = ()=> {
    return (
      <Modal
        popup
        visible={isShowCardModal}
        onClose={()=> disPatch(setUserCardModal(false))}
        animationType="slide-up"
        style={{height: '400px'}}
      >
        <List renderHeader={() => <div>平台卡</div>} className="popup-list">
          <UserCardInfo/>
        </List>
      </Modal>
    )
  }

  // 切换油枪
  const changeOilItem = ()=> {
    return (
      <Modal
        popup
        visible={isShowOilModal}
        onClose={()=> setShowOilModal(false)}
        animationType="slide-up"
        style={{height: '400px'}}
      >
        <List renderHeader={() => <div>选择油枪</div>} className="popup-list">
          {oilListView()}
        </List>
      </Modal>
    )
  }

  const oilListView = ()=> {
    return user_pay_info.cate.map((item: any, index) => {
      return (
        <div className={'oil-con-list'} key={index} style={{padding: '10px 0 0 10px'}}>
          {
            item.list.map((child: any, idx: number) => {
              return <OilItem key={idx} item={child}/>
            })
          }
        </div>
      )
    })
  }

  // 油枪子元素
  const OilItem = (child: any)=> {
    let item = child.item;
    return(
      <div className='oil-btn-con'>
        <Button
          className='oil-btn'
          activeClassName='oil-btm-active'
          onClick={() => updateOilItem(item)}
        >
          <span>{item.goods.name}</span>
          <span>{item.name}</span>
        </Button>
      </div>
    )
  }

  // 输入金额后展示
  const UserInputMoney = () => {
    let initItem = nowOilItem;
    return (
      <div className={'dis-header'}>
        <div onClick={changeUserInputStatus} className={'dis-left'}>
          <span>￥<span>{payMoney}</span></span>
        </div>
        <div className={'dis-right'} onClick={()=> clearPayInfo()}>
          <MyIcon type={'iconjiayouqiang'} style={{fontSize: '26px', transform: 'rotateY(180deg)'}}/>
          <div className={'oil-name'}>
            <span>{initItem.goods.name}</span>
            <span>{initItem.goods.type.name}</span>
          </div>
        </div>
      </div>
    )
  }

  // 输入加油金额
  const UserInput = () => {
    return (
      <div className='input-all'>
        <List>
          <InputItem
            ref={inputRef}
            type='money'
            clear
            moneyKeyboardAlign="left"
            placeholder='请输入加油金额'
            onChange={()=> Tools.vibrateShort()}
            onVirtualKeyboardConfirm={(evt) => makeMoney(evt)}
          >
            <span className={'money-icon'}>￥</span>
          </InputItem>
          <KeyHeader/>
        </List>
      </div>
    )
  }

  // 优惠内容&&支付方式
  const PayMainCenter = () => {
    if (mainCenter) {
      return (
        <div className={'dis-info-con'}>
          {/*优惠展示*/}
          <DisAppInfo/>
          {/**零费率展示**/}
          {inputStatus && ZeroMoneyShow()}
          <PayUsedType type={"show"} payMoney={payMoney}/>
        </div>
      )
    }
    return <></>;
  }

  const zltcInfo = ()=> {
    return <div className={'zltc-info'} style={{padding: 0}}>
      <span>1、当您下单加油时，您将按照当前订单金额支付服务费。平台发放的满减券、折扣券及您通过淘宝店铺等三方渠道购买的平台优惠券，在使用时，均应支付服务费</span>
      <span>2、将按照按照当前订单金额的0.45%支付（保留小数点后两位）服务费</span>
      <span>3、服务费策略将根据市场实际情况进行相关调整，调整后，将明示于交易页面；智联云油始终秉承“为用户节约加油消费”的宗旨进行服务费策略的制定。</span>
      <span>4、若您对服务费金额存疑或不认可，可选择放弃交易。</span>
      <span>5、服务费规则未尽事宜，请咨询平台客服18888217968。</span>
      <span>6.服务费解释权归平台所有，付款即代表同意。</span>
      <span>7、感谢您一路对智联云油的的支持，平台也一直努力做得更好，前进的路上有智联云油陪伴着您。</span>
    </div>
  }

  // 零费率展示
  const ZeroMoneyShow = ()=> {
    const {
      point_dec_money,
      coupon_dec_money,
      discount_dec_money,
      recharge_dec_money,
      platform_dec_money,
      zero_fee
    } = payInfo.nowPayItem;
    if (!zero_fee) return <></>
    return (
      <div className={'zero-con'}>

        {
          !discount_dec_money && <div className={'zero-con-item'}>
            <div className={'item-all'}>
              <div className={'icon-vip'}>vip</div>
              <span className={'item-title'}>会员优惠</span>
            </div>
            {discount_dec_money ? <span>-{discount_dec_money}元</span>:<span>暂无会员优惠</span>}
          </div>
        }
        {
          !coupon_dec_money && <div className={'zero-con-item'}>
            <div className={'item-all'}>
              <div className={'icon-vip'}>券</div>
              <span className={'item-title'}>优惠券</span>
            </div>
            {coupon_dec_money ? <span>-{coupon_dec_money}元</span>:<span>暂无可用优惠券</span>}
          </div>
        }
        {
          !point_dec_money && <div className={'zero-con-item'}>
            <div className={'item-all'}>
              <div className={'icon-vip'}>积</div>
              <span className={'item-title'}>积分优惠</span>
            </div>
            {point_dec_money ? <span>-{point_dec_money}元</span>:<span>暂无积分优惠</span>}
          </div>
        }
        {
          !platform_dec_money && <div className={'zero-con-item'}>
            <div className={'item-all'}>
              <div className={'icon-vip'}>卡</div>
              <span className={'item-title'}>平台卡优惠</span>
            </div>
            { platform_dec_money ? <span>-{platform_dec_money}元</span>:<span>暂无可用平台卡优惠</span>}
          </div>
        }
        {
          !recharge_dec_money && <div className={'zero-con-item'}>
            <div className={'item-all'}>
              <div className={'icon-vip'}>充</div>
              <span className={'item-title'}>充值优惠</span>
            </div>
            {recharge_dec_money ? <span>-{recharge_dec_money}元</span>:<span>暂无充值优惠</span>}
          </div>
        }

        <div className={'zero-con-item'} onClick={()=> Dialog.alert({
          title: '温馨提示',
          content: zltcInfo()
        })}>
          <div className={'item-all'}>
            <div className={'icon-vip-fu'}>服</div>
            <span className={'item-title'}>服务费</span>
            <QuestionCircleOutlined />
          </div>
          <span>+{zero_fee}元</span>
        </div>
      </div>
    )
  }


  return (
    <div className='oil-all'>
      <OilTop/>
      <UserMainCenter/>
      <PayMainCenter/>
      {inputStatus && isShowCardStatus && cardInfo()}
      {/**发票**/}
      {inputStatus && Tools.param2Obj().receipt === 'true' && <InvoiceInfo/>}
      {/**底部支付按钮**/}
      {inputStatus && <BottomPay/>}
      {/**余额密码支付**/}
      <BalancePay/>
      {/**更多支付方式**/}
      <MorePayTypeView />
      {/**平台卡**/}
      <UserCardShow/>
      {/**切换油枪**/}
      {changeOilItem()}
    </div>
  )
}


export default UserPay;