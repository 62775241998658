import React, {FC} from "react";
import './index.scss';

const OilMap: FC = ()=> {
  return (
    <div className={'map-con'}>
      <div className={'map-info'}>地图 map</div>
      <div className={'map-icon'}>

      </div>
    </div>
  )
}

export default OilMap;
