import React, {FC, useState} from "react";
import {useParams} from "react-router";
import {Button, InputItem, List, Toast, WhiteSpace} from "antd-mobile";
import {MyIcon} from "@components/AppIcon";
import {SendCheckCode} from "@components/SendCheckCode";
import { verifyMobile } from "@api/app";
import {useNavigate} from "react-router-dom";
import Tools from '@utils/tools';
import {useMount} from "ahooks";
import { addCarCertification, editApplyInfo } from "@api/user";
import './index.scss'
import {useSelector} from "react-redux";
import {userAppInfo} from "@store/auth.slice";


const CheckPhone: FC =()=> {
  const urlParam = useParams();
  const {type} = urlParam;
  const navigate = useNavigate();
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState<string> ('');
  const userInfo = useSelector(userAppInfo)

  useMount(()=> {
    console.log(Tools.param2Obj())
  })

  const changeMobile = (evt: any) => {
    setMobile(evt)
  }
  const changeCode = (evt: any) => {
    setCode(evt)
  }

  const checkPhoneCode = async ()=> {
    try {
      if (!code) return Toast.fail('请输入手机验证码')
      const status = await verifyMobile(code);
      if (status.code === 200) {
        switch (type) {
          case 'setPassword':
            navigate('/password/set/pay');
            break;
          case 'changePassword':
            navigate('/password/update/pay');
            break;
          case 'forgetPassword':
            navigate('/password/forget/pay');
            break;
          case 'uploadCarImage':
            await pushCardInfo();
            break;
          case 'editCarImage':
            await editCarImage();
            break;
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  // 专车认证上传
  const pushCardInfo = async ()=> {
    try {
      let obj = Object.assign({}, userInfo.checkCarType);
      const res = await addCarCertification({
        ...obj,
        mobile: mobile.replace(/\s*/g,""),
        verification_code: code
      });
      Toast.info(res.msg);
      if (res.code === 200) {
        setTimeout(()=> navigate('/special_car_detail'), 900)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const editCarImage = async ()=> {
    try {
      let obj = Object.assign({}, userInfo.checkCarType);
      const res = await editApplyInfo({
        ...obj,
        mobile: mobile.replace(/\s*/g,""),
        verification_code: code
      });
      Toast.info(res.msg);
      if (res.code === 200) {
        setTimeout(()=> navigate('/special_car_detail'), 900)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <List className='check-phone' renderHeader={'为了您的账户安全，请完成手机验证'}>
      <InputItem onChange={changeMobile} type='phone' placeholder='请输入手机号'>
        <MyIcon type='iconphone'/>
      </InputItem>
      <InputItem onChange={changeCode} maxLength={8} placeholder='请输入验证码'>
        <MyIcon type='iconAPImiyue'/>
      </InputItem>
      <SendCheckCode phone={mobile} />
      <WhiteSpace />
      <div className={'make-btn'}>
        <Button type="primary" onClick={()=> checkPhoneCode()}>确定</Button><WhiteSpace />
      </div>
    </List>
  )
}

export default CheckPhone;