import React from "react";
import {Outlet} from 'react-router-dom'
import BottomBar from "@components/TabBar/TabBar";

const Index =  () => {
  return (
    <>
      <Outlet/>
      <BottomBar/>
    </>
  )
}

export default Index