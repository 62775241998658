import React, {FC, useState} from "react";
import {Button, InputItem, List, Switch, Toast, WhiteSpace} from "antd-mobile";
import {MyIcon} from "@components/AppIcon";
import {SendCheckCode} from "@components/SendCheckCode";
import {useNavigate} from "react-router-dom";
import {useMount} from "ahooks";
import {getAppUserInfo, isUsedConfidentialPay } from "@api/user";


const CheckPhone: FC =()=> {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState<string> ('');
  const [status, changeStatus] = useState(false);

  useMount(()=> {
    getUserMobile();
  })

  const getUserMobile = async ()=> {
    try {
      const res = await getAppUserInfo();
      if (res.code === 200) {
        setMobile(res.data.mobile);
        if (res.data.is_balance_confidential_payment) changeStatus(true);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const changeMobile = (evt: any) => {
    setMobile(evt)
  }
  const changeCode = (evt: any) => {
    setCode(evt)
  }

  const makeSetting = async()=> {
    try {
      if (!code) return Toast.info('请输入验证码');
      const res = await isUsedConfidentialPay({
        mobile,
        verification_code: code,
        is_balance_confidential_payment: status ? 1:0
      })
      Toast.info(res.msg);
      if (res.code === 200) setTimeout(()=> navigate(-1), 800);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <List className='check-phone' renderHeader={'免密支付配置'}>
      <InputItem onChange={changeMobile} value={mobile} type='phone' placeholder='请输入手机号'>
        <MyIcon type='iconphone'/>
      </InputItem>
      <InputItem onChange={changeCode} maxLength={8} placeholder='请输入验证码'>
        <MyIcon type='iconAPImiyue'/>
      </InputItem>
      <SendCheckCode phone={mobile} />
      <WhiteSpace />
      <List.Item extra={
        <Switch
          style={{marginRight: '30px'}}
          checked={status}
          onChange={(val)=> changeStatus(val)}
        />}>
        <span>是否使用免密支付</span>
      </List.Item>
      <WhiteSpace />
      <WhiteSpace />

      <div className={'make-btn'}>
        <Button type="primary" onClick={()=> makeSetting()}>确定</Button><WhiteSpace />
      </div>
      <WhiteSpace />

    </List>
  )
}

export default CheckPhone;