import React from "react";
import {Modal} from "antd-mobile";
// import * as payStore from "@store/pay.slice";
import {payAppInfo, paySlice} from "@store/pay.slice";

import {useDispatch, useSelector} from "react-redux";
import PayUsedType from "@view/user_pay/paytype/pay_type";

const MorePayTypeView = ()=> {

  const payInfo = useSelector(payAppInfo);
  const dispatch: (...args: unknown[]) => Promise<any> = useDispatch();
  const {setMorePayStatus} = paySlice.actions;
  const {morePayStatus} = payInfo;

  return(
    <Modal
      popup
      visible={morePayStatus}
      onClose={()=> dispatch(setMorePayStatus(false))}
      animationType="slide-up"
    >
      <PayUsedType type={"more"}></PayUsedType>
    </Modal>
  )
}

export default MorePayTypeView;