import React, {useState} from "react";
import {Result} from "antd-mobile";
import {useInterval, useWxPay} from "@utils/app_hooks";
import {useNavigate} from "react-router-dom";
import { createPayment } from "@api/order";
import Tools from "@utils/tools";
import wx from "weixin-js-sdk-ts";
import {useMount} from "ahooks";
import {Toast} from "antd-mobile-v5";

const TimePay = ()=> {
  const naviagtor = useNavigate();
  const myImg = (src: string) => <img src={src} className="spe am-icon am-icon-md" alt="" />;
  const [count, setCount] = useState(60);
  let [code_status, setStatus] = useState(false);
  const urlParam = Tools.param2Obj();

  const userWxPay = useWxPay();

  useMount( ()=> {
    Toast.show('请稍候...')
    setTimeout(()=> makePay(), 800)
  })

  useInterval(() => {
    if (!code_status) {
      if (count === 1) {
        setCount(60);
        setStatus(true);
        return;
      }
      setCount(count - 1);
    } else wx.closeWindow();
  }, 1000);

  const closeOrder = ()=> {
    const { ordersn } = urlParam;
    naviagtor(`/payresult?status=fail&ordersn=${ordersn}`)
  }

  const makePay = async ()=> {
    try {
      const { pay_token, paysn } = urlParam;
      const res = await createPayment(pay_token, paysn);
      if (res.code === 200) {
        userWxPay.pay(res.data);
      } else {
        closeOrder();
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Result
        img={myImg('https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg')}
        title={`支付中...(${count}s)`}
        message="请在规定时间内完成支付"
      />
    </>
  )
}

export default TimePay;
