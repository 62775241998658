import React from "react";
import PasswordKeyboard from "@components/PasswordKeyboard/PasswordKeyboard";
import PasswordInput from "@components/PasswordInput/PasswordInput";
import {Modal, Toast} from "antd-mobile";
import {passwordAppInfo, passwordSlice} from "./balance.slice";
import {useDispatch, useSelector} from "react-redux";
import * as payStore from "@store/pay.slice";
import {payAppInfo} from "@store/pay.slice";
import {useUpdateEffect} from "ahooks";
import {useNavigate} from "react-router-dom";
import { getPrizeInfo } from "@api/rewards";

import "./index.scss";

const BalancePay = () => {
  const navigate = useNavigate();
  const pwd_info = useSelector(passwordAppInfo);
  const payinfo = useSelector(payAppInfo);
  const {password, password_arr} = pwd_info;
  const {changeStatus, setPassword, setPasswordArr} = passwordSlice.actions;
  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();

  useUpdateEffect(()=> {
    creatOrderUser();
  }, [password])

  const changePwd = (pwd: string) => {
    if (password.length>5) return Toast.info('密码为六位数字');
    disPatch(setPassword(password+pwd));
    disPatch(setPasswordArr(password+pwd));
  }

  const clearInputNumber = () => {
    disPatch(setPassword(""));
    disPatch(setPasswordArr(""));
  }

  const delNumber = () => {
    if (password.length > 0) {
      let pwd_str = password.substring(0, password.length - 1);
      disPatch(setPassword(pwd_str));
      disPatch(setPasswordArr(pwd_str));
    }
  }

  const getPassword = () => {
    disPatch(changeStatus(false))
    navigate('/checkPhone/forgetPassword')
  }

  const unPassword = ()=> {
    disPatch(changeStatus(false));
    navigate('/un_password');
  }

  const creatOrderUser = async()=> {
    if(password.length === 6) {
      try {
        Toast.loading('请稍候...');
        const { payment_code, account_id } = payinfo.nowPayItem;
        let data = Object.assign({}, payinfo.payParams);
        data.payment_code = payment_code;
        data.pay_password = password;
        data.account_id = account_id;
        if (data.platform === 0) delete data.platform_card_data_id;
        const res = await disPatch(payStore.userCreateOrder(data));
        if (res.code === 200) {
          await getPrizeInfo(res.data.ordersn);
          navigate(`/payresult?ordersn=${res.data.ordersn}&status=success&balance=${true}`, { replace: true })
        }
        await disPatch(changeStatus(false))
        clearInputNumber();
        await disPatch(setPasswordArr(''));
      } catch (e) {
        await disPatch(changeStatus(false));
        clearInputNumber();
        await (setPasswordArr(''));
        Toast.hide()
      }
    }
  }

  const BottomView = () => {
    return(
      <PasswordKeyboard
        changePassword={(num)=>changePwd(num)}
        delNumber={()=>delNumber()}
        clearInputNumber={()=>clearInputNumber()}
        setDrawerStatus={()=>disPatch(changeStatus(false))}
      >
        <div className='info_text'>使用虚拟资产，请验证身份，验证通过后使用余额支付</div>
        <PasswordInput pwd={password_arr}></PasswordInput>
        <div className='forget_text'>
          <span onClick={()=> unPassword()}>免密支付</span>
          <span onClick={()=> getPassword()}>忘记密码</span>
        </div>
      </PasswordKeyboard>
    )
  }

  return(
    <Modal
      style={{height: "444px"}}
      title={'输入密码'}
      popup
      onClose={()=>disPatch(changeStatus(false))}
      visible={pwd_info.modelStatus}
      animationType="slide-up"
    >
      <BottomView></BottomView>
    </Modal>
  )
}

export default BalancePay;