import {UserCardItem} from "@view/user_pay/pay_store_interface";

/**优惠内容数据**/

export const cardDisItem = (item: UserCardItem)=> {
  const {
    full_reduction,
    every_reduction,
    every_discount,
    unified_offer,
    goods_offer,
    cate_offer,
    unified_offer_value,
    full_discount
  } = item;
  if (full_reduction) return disTypePublic(full_discount, 'full');
  if (every_reduction) return disTypePublic(every_discount, 'every');
  if (unified_offer) {
    if (Number(unified_offer_value?.discount_status) === 1) {
      return [{dis_str: `${Number(unified_offer_value?.discount)/10}折`}]
    }
    return [{dis_str: `每升减${Number(unified_offer_value?.money)}元`}]
  }
  if (goods_offer) return item.goods_offer_value;
  if (cate_offer) return item.cate_offer_value;
}


const disTypePublic = (disVal: any, type: 'every'|'full'): any[] | undefined=> {
  let dis_arr: Array<{dis_str: string}> = [];
  let keys = Object.keys(disVal);
  if (keys.length === 0) return [];
  keys.forEach((item) => {
    let items = Number(item);
    let every_discount_val = disVal[items];
    if (every_discount_val.cate_offer === '1') dis_arr = every_discount_val.cate_offer_value;
    if (every_discount_val.goods_offer === '1') {
      if (type === 'every') {
        dis_arr = every_discount_val.cate_offer_value;
      } else {
        dis_arr = every_discount_val.goods_offer_value;
      }
    }
    if (every_discount_val.unified_offer === '1') {
      dis_arr = [{dis_str: every_discount_val.unified_offer_value.dis_str}]
    }
  })
  return dis_arr;
}
