import React, {FC} from "react";
import {Button, Checkbox, Modal, Stepper, Tabs} from "antd-mobile";
import * as CouponStore from "./coupon.slice";
import * as PayStore from "@store/pay.slice";
import {couponAppInfo, State} from "./coupon.slice";
import {payAppInfo} from "@store/pay.slice";
import {useDispatch, useSelector} from "react-redux";
import {useMount} from "ahooks";
import "./index.scss";


const CouponView: FC = ()=> {

  const couponInfo = useSelector(couponAppInfo);
  const payInfo = useSelector(payAppInfo);
  const {ModelStatus, CouponArr, nowTabIndex} = couponInfo;
  const {
    setModelStatus,
    changeCouponArrStatus,
    changeStepperNumber,
    setNowTabIndex,
    initUsedCoupon,
    noUsedCoupon
  } = CouponStore.couponSlice.actions;

  const {setPayParam} = PayStore.paySlice.actions;

  const dispatch: (...args: unknown[]) => Promise<State['CouponItem']> = useDispatch();

  const tabs = [
    { title: "满减券" },
    { title: "折扣券" },
    { title: "代金券" },
  ];

  useMount(()=> {
    dispatch(initUsedCoupon(nowTabIndex));
  })

  // 关闭modal
  const onClose = async()=> {
    dispatch(setModelStatus(false));
  }

  // 优惠券选中状态
  const changeCheckBox = async(index: number)=> {
    const {payment_code, account_id} = payInfo.nowPayItem;
    await dispatch(changeCouponArrStatus({nowTabIndex, index}));
    let couponItem = CouponArr[`${nowTabIndex+1}`][index];
    await dispatch(setPayParam({
      coupon: 1,
      payment_code,
      account_id,
      coupon_ids: couponItem.coupon_data_ids.join(','),
      discount: payInfo.payParams.discount,
      point: payInfo.payParams.point
    }));
    onClose();
  }

  // 优惠券选择张数
  const chooseStepper = async(num: number, index: number) => {
    try {
      await dispatch(changeStepperNumber({num, index, nowTabIndex}));
      const {payment_code, account_id} = payInfo.nowPayItem;
      let couponItem = CouponArr[`${nowTabIndex+1}`][index];
      let arr = couponItem.coupon_data_ids.filter((item, idx) => idx<=num-1);
      let coupon_ids = arr.length>0 ? arr.join(','):"";
      
      await dispatch(setPayParam({
        coupon: !couponItem.checked?1:0,
        payment_code,
        account_id,
        coupon_ids,
        discount: payInfo.payParams.discount,
        point: payInfo.payParams.point
      }));
    } catch (e) {
      console.log(e)
    }
  }

  const noUsed= async () => {
    const {payment_code, account_id} = payInfo.nowPayItem;
    dispatch(noUsedCoupon());
    await dispatch(setPayParam({
      coupon: 0,
      payment_code,
      account_id,
      coupon_ids: "",
      discount: 0,
      point: 0
    }));
    onClose();
  }

  const CouponItem = (item: State['CouponItem'], index: number): React.ReactNode=> {
    return (
      <div className='coupon_item' key={index}>
        <div className='coupon_item_main'>
          <div className='coupon_item_type'>{item.dec_money}</div>
          <div className='coupon_item_center'>
            <div className='coupon_item_card'>
              <div className='coupon_item_title'>{item.title}</div>
              <div className='coupon_item_exp_time'>{item.exp_time}</div>
              <div className={'coupon-item-stepper'}>
                <Stepper
                  showNumber
                  max={item.max_use}
                  min={1}
                  disabled={!item.checked}
                  defaultValue={item.max_use}
                  downStyle={'coupon-down'}
                  inputStyle={'coupon-input'}
                  upStyle={'coupon-up'}
                  onChange={(evt)=>chooseStepper(evt, index)}
                />
              </div>
            </div>
            <div>
              <Checkbox checked={item.checked} onChange={()=> changeCheckBox(index)}> </Checkbox>
            </div>
          </div>
        </div>
        <div className='coupon_item_bottom'>描述信息：该券单次最多可用{item.max_use}张</div>
      </div>
    )
  }

  const AllCoupon = ():React.ReactNode => {
    return Object.keys(CouponArr).map((keys)=> {
      return CouponArr[keys].map((item, index) => {
        return CouponItem(item, index);
      })
    })
  }

  return (
    <Modal
      popup
      visible={ModelStatus}
      onClose={()=>onClose()}
      animationType="slide-up"
    >
      <div style={{minHeight: '260px'}}>
        <Tabs
          tabs={tabs}
          initialPage={nowTabIndex}
          swipeable={false}
          onTabClick={(tab, index) => dispatch(setNowTabIndex(index+1))}
        >
          {AllCoupon()}
        </Tabs>
      </div>
      <Button className={'no-used'} type={'warning'} onClick={()=> noUsed()}>不使用优惠券</Button>
    </Modal>
  )
}


export default CouponView;