import React, {FC} from "react";
import './pay.scss'

const PayTextInfo: FC = () => {
    return <div className={'zltc-info'}>
      <span>1、当您下单加油时，您将按照当前订单金额支付服务费。平台发放的满减券、折扣券及您通过淘宝店铺等三方渠道购买的平台优惠券，在使用时，均应支付服务费</span>
      <span>2、将按照按照当前订单金额的0.45%支付（保留小数点后两位）服务费</span>
      <span>3、服务费策略将根据市场实际情况进行相关调整，调整后，将明示于交易页面；智联云油始终秉承“为用户节约加油消费”的宗旨进行服务费策略的制定。</span>
      <span>4、若您对服务费金额存疑或不认可，可选择放弃交易。</span>
      <span>5、服务费规则未尽事宜，请咨询平台客服18888217968。</span>
      <span>6.服务费解释权归平台所有，付款即代表同意。</span>
      <span>7、感谢您一路对智联云油的的支持，平台也一直努力做得更好，前进的路上有智联云油陪伴着您。</span>
    </div>
}

export default PayTextInfo