import HttpServer from '@utils/http';

export interface RewArdDetail {
  id: string;
  order_sn?: string;
  type?: 1|2;
}

// 点击开始抽奖
export const startAward = (id: number|undefined) => {
  return HttpServer.post({ url: 'client/prize.prize/luck_draw', params: {
    id
  }})
}

// 抽奖详情
export const getAwardGoods = (param: RewArdDetail) => {
  return HttpServer.get({ url: 'client/prize.prize/get_prize_info', query: param})
}

// 获取个人中奖信息
export const getUserPrize = () => {
  return HttpServer.get({ url: 'client/prize.prize/order_list', query: {}})
}

// 优惠券详情
export const getCouponDetail = (id: number) => {
  return HttpServer.post({ url: 'coupon/user.coupon/detail', params: {
    id
  }})
}

// 获取中奖物品详情
export const getPrizeAwardDetail = (id: number) => {
  return HttpServer.get({ url: 'client/prize.prize/order_detail', query: {
    type: 1,
    id
  }})
}

// 组队详情
export const getOrganizeTeam = (id: number) => {
  return HttpServer.get({ url: 'client/prize.prize/get_organize_team_info', query: {
    id
  }})
}

// 获取用户所有组队信息
export const getUserOrganizeTeam = (prize_id: number) => {
  return HttpServer.get({ url: 'client/prize.prize/get_organize_team_list', query: {
    prize_id
  }})
}

// 队长组队
export const setPrizeOrganizeTeam = (id: number) => {
  return HttpServer.post({ url: 'client/prize.prize/organize_team', params: {
    id
  }})
}

// 队员参与组队
export const setPartOrganizeTeam = (id: number) => {
  return HttpServer.post({ url: 'client/prize.prize/part_organize_team', params: {
    id
  }})
}

// 支付后抽奖详情
export const getPrizeInfo = (order_sn: number) => {
  return HttpServer.get({ url: 'client/prize.prize/get_prize_info', query: {
    id: '', order_sn, type: 1
  }})
}

