import React, {useState} from "react";
import { BrowserRouter as Route } from "react-router-dom";
import { AppRouter } from "@view/AppRouter";
import { PageLoading } from "@components/AppLoading";
import Login, {UserData} from "@view/login/index";
import {useDispatch, useSelector} from "react-redux";
import {userAppInfo} from "@store/auth.slice";
import * as authStore from "@store/auth.slice";
import {useMount} from "ahooks";
import Tools from "@utils/tools";
import "./App.scss";
import 'antd-mobile-v5/es/global'

const PublicEnv = Tools.getSystemPayEnv();

function App() {
  const [aliLoginStatus, setAliLoginStatus] = useState(true);
  const disPatch: (...args: unknown[]) => Promise<UserData> = useDispatch();
  const userinfo = useSelector(userAppInfo);
  const { isLoginStatus } = userinfo;
  const {setLoginStatus} = authStore.authSlice.actions;

  useMount(async ()=> {
    Tools.initUserInfo(window.location.search);
    let param = Tools.param2Obj(window.location.search);
    if (PublicEnv === "h5") {
      if ((window as any).douyin_open && Tools.isDouYin()) {
         await disPatch(authStore.douYinLogin());
      } else {
         await disPatch(authStore.checkH5Token());
      }
    } else if (PublicEnv === 'ali') {
      if (param.hasOwnProperty('app_token')) {
        setTimeout(() => setAliLoginStatus(false), 500);
        await disPatch(setLoginStatus(true));
      } else {
        setTimeout(() => setAliLoginStatus(false), 2000);
        await disPatch(authStore.aliUserLoginApp());
      }
    } else {
      await disPatch(authStore.userWxLogin());
    }
  })

  const isEnvComponent = () => {
    if (PublicEnv ==='wx') {
      return <PageLoading status={!isLoginStatus}/>
    } else if (PublicEnv === 'ali') {
      return (
        <PageLoading status={aliLoginStatus}>
          <Login/>
        </PageLoading>
      )
    } else {
      return (
        <PageLoading status={isLoginStatus}>
          <Login/>
        </PageLoading>
      )
    }
  }
  return (
    <Route>
      {isLoginStatus ? <AppRouter/> : isEnvComponent()}
    </Route>
  );
}

export default App;
