import HttpServer from '@utils/http';
import {UserData} from '@view/login/index';

export interface WxCodeInfo {
  app_id: string|null;
  code: string;
  qrcode_id?: string;
  sn?: string;
  reco_code?: string;
}

interface Receipt {
  address: string;
  bank: string;
  bank_account: string;
  is_default: 0;
  mobile: string;
  tax_id: number;
  tax_name: string;
  type: number;
}

interface AddCardInfo {
  id?: string;
  car_type_id: number;
  name: string;
  url: string;
  mobile: string;
  verification_code: string;
}

interface IsBalancePay {
  verification_code: string;
  mobile: string;
  is_balance_confidential_payment: 0|1;
}

// 微信用户授权链接
export const wxUserLoginAuthUrl = (app_id: string|null, callback:string) => {
  return HttpServer.post({ url: 'client/wechat.login/auth_url', params: {
    app_id, callback
  }})
}

// 换取微信code,拿到用户信息
export const getWxUserCodeInfo = (data: WxCodeInfo) => {
  return HttpServer.post({ url: 'client/wechat.login/register', params: data })
}

// 获取用户信息
export const getUserInfoByToken = (token: string|null) => {
  return HttpServer.post({ url: 'shop/user.index/info', params: { token }})
}

// 支付宝H5登录
export const aliUserLogin = (auth_code: string, reco_code?: string) => {
  return HttpServer.post({ url: 'client/ali.login/login', params: { auth_code, reco_code }})
}

// 支付宝H5注册
export const aliUserRegister = ({mobile, check_code}: UserData, ali_token: string) => {
  return HttpServer.post({ url: 'client/ali.login/register', params: {
    mobile: mobile.replace(/\s*/g,""),
    verification_code: check_code,
    ali_token
  }})
}

// 浏览器H5登录
export const UserLoginH5 = ({mobile, check_code}: UserData) => {
  return HttpServer.post({ url: 'user/user.index/h5_login', params: {
    mobile: mobile.replace(/\s*/g,""),
    verification_code: check_code,
  }})
}

// 抖音环境注册
export const DyRegister = ({mobile, check_code, dy_active, dy_token}: UserData) => {
  return HttpServer.post({ url: 'client/douyin.login/mobile_register', params: {
    mobile: mobile.replace(/\s*/g,""),
    verification_code: check_code,
    dy_token,
    dy_active
  }})
}


// 判断是否设置支付密码
export const isSetPayPassword = () => {
  return HttpServer.post({ url: 'shop/user.index/judgePayPass', params: {}})
}

// 设置新密码
export const setInitPassword = (pay_pass: string) => {
  return HttpServer.post({ url: 'user/user.index/setPayPass', params: {
    pay_pass
  }})
}

// 验证旧交易密码
export const verifyPayPass = (pay_pass: string) => {
  return HttpServer.post({ url: 'shop/user.index/verifyPayPass', params: {
    pay_pass
  }})
}

// 修改交易密码
export const editPassword = (pay_password: string) => {
  return HttpServer.post({ url: 'shop/user.index/editInfo', params: {
    pay_password
  }})
}

// 抖音用户登录
export const douYinLoginInfo = (code: string) => {
  return HttpServer.post({ url: 'client/douyin.login/login', params: {
    code
  }})
}

// 获取抖音活动链接
export const activtiyDetail = (id: string) => {
  return HttpServer.post({ url: 'client/douyin.video/activtiy', params: {
    id
  }})
}

// 获取优惠券列表
export const getCouponData = (is_exp: number, is_used: number, page: number) => {
  return HttpServer.post({ url: 'coupon/user.coupon/index', params: {
    is_exp,
    is_used,
    page,
  }})
}

// 用户平台卡列表
export const getUserCardList = (status: number = 1, goods_id: number, cate_id: number) => {
  return HttpServer.get({ url: 'client/card/card_user_list', query: {
    status, goods_id, cate_id
  }})
}

// 开发票
export const openReceipt = (param: Receipt) => {
  return HttpServer.post({ url: 'user/user.receipt/saveReceipt', params: param})
}

// 是否使用免密支付
export const isUsedConfidentialPay = (param: IsBalancePay) => {
  return HttpServer.post({ url: 'client/user/set_balance_confidential_payment', params: param})
}

// 获取个人信息
export const getAppUserInfo = () => {
  return HttpServer.post({ url: 'client/user/info', params: {}})
}

// 获取专车类型列表
export const getSpecialCarList = () => {
  return HttpServer.get({ url: 'client/car_type/index', query: {}})
}

// 用户申请认证
export const addCarCertification= (params: AddCardInfo) => {
  return HttpServer.post({ url: 'client/car_certification/apply', params})
}

// 申请认证详情
export const getCarCertificationDetail= () => {
  return HttpServer.get({ url: 'client/car_certification/detail', query: {}})
}

export const editApplyInfo = (params: AddCardInfo) => {
  return HttpServer.post({ url: 'client/car_certification/edit_apply', params})
}

// 获取会员信息 (充值车队卡链接使用)
export const getUserLevelCard = async () => {
  return await HttpServer.post({url: "user/user.User_level_Card/my_card", params: {}});
};

// 获取油卡充值项目
export const geConfigRecharge = async (card_id: number, card_type: number) => {
  return await HttpServer.post({url: "user/user.ConfigRecharge/detail", params: {
    card_id,
    card_type
  }});
};

// 获取支付方式
export const getPayType = async (param: { source: string }) => {
  return await HttpServer.post({ url: "/offlinestore/user.recharge/pay_type", params: param});
};

// 获取员工列表
export const getRechargeEmpList = async (param: {room_id: number, qrcode_id: number, sn?: number}) => {
  return await HttpServer.post({url: 'client/recharge/emp_list', params: param})
}

// 充值
export const userRecharge = async (param: {room_id: number, qrcode_id: number, sn?: number}) => {
  return await HttpServer.post({url: '/offlinestore/user.recharge/recharge', params: param})
}
