import {MyIcon} from '@components/AppIcon';
import React, {FC} from 'react';
import {Button} from 'antd-mobile';
import './index.scss';

interface PasswordNumber {
  changePassword: (num: string) => void;
  delNumber: ()=> void;
  clearInputNumber: ()=> void;
  setDrawerStatus: ()=> void;
}

const PasswordKeyboard2: FC<PasswordNumber> = (props) => {

  const { changePassword, delNumber, clearInputNumber, setDrawerStatus } = props;

  const addNumber = (num: string) => {
    changePassword(num);
  }

  const closePwdStatus = () => {
    clearInputNumber();
    setDrawerStatus()
  }

  return (
    <div>
      { props.children }
      <div className='pwd_keyboard'>
        <div className='pwd_line_part'>
          <Button className='pwd_key_item' onClick={() => addNumber('1')}>1</Button>
          <Button className='pwd_key_item' onClick={() => addNumber('4')}>4</Button>
          <Button className='pwd_key_item' onClick={() => addNumber('7')}>7</Button>
          <Button className='pwd_key_item' onClick={() => closePwdStatus()}>
            <MyIcon type={'iconguanbijianpan'}></MyIcon>
          </Button>
        </div>
        <div className='pwd_line_part'>
          <Button className='pwd_key_item' onClick={() => addNumber('2')}>2</Button>
          <Button className='pwd_key_item' onClick={() => addNumber('5')}>5</Button>
          <Button className='pwd_key_item' onClick={() => addNumber('8')}>8</Button>
          <Button className='pwd_key_item' onClick={() => addNumber('0')}>0</Button>
        </div>
        <div className='pwd_line_part'>
          <Button className='pwd_key_item' onClick={() => addNumber('3')}>3</Button>
          <Button className='pwd_key_item' onClick={() => addNumber('6')}>6</Button>
          <Button className='pwd_key_item' onClick={() => addNumber('9')}>9</Button>
          <Button className='pwd_key_item' onClick={() => delNumber()}>
            <MyIcon type={'icondel'} onClick={()=> delNumber()}></MyIcon>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PasswordKeyboard2;
