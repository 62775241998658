/**
 * 支付宝H5支付
 * @param callback
 */

function ready(callback: any) {
  if ((window as any).AlipayJSBridge) {
    callback && callback();
  } else {
    document.addEventListener('AlipayJSBridgeReady', callback, false);
  }
}

// 支付宝支付
export function aliPay(tradeNO: number) {
  return new Promise((resolve, reject) => {
    ready(function () {
      // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
      (window as any).AlipayJSBridge.call("tradePay", {
        tradeNO: tradeNO
      }, function (data: any) {
        if ("9000" === data?.resultCode) {
          resolve(true)
        } else {
          reject(false)
        }
      });
    });
  })
}

// 获取支付宝code
export function getAliAuthCode(): Promise<string> {
  return new Promise((resolve, reject) => {
    (window as any).ap.getAuthCode(
      {
        appId: 2021002136628695,
        scopes: ['auth_base'],
      }, (res: any) => {
        if (res.authCode) {
          resolve(res.authCode)
        } else {
          reject()
        }
      }
    )
  })
}
