import React, {useState} from 'react';
import {useMount, useToggle, useUnmount, useUpdateEffect} from "ahooks";
import LuckyOne from '@components/RewardSty/lucky_1';
import { getUserPrize } from '@api/rewards';
import {useDispatch, useSelector} from 'react-redux';
import {activityInfo, activitySlice} from '@store/activity.slice';
import AppMaskBox from "@components/AppMask";
import {useNavigate} from "react-router-dom";
import RewardGoods from "@assets/images/giftVer2.png";
import RewardImg from '@assets/images/reward_goods.png';
import noReward from '@assets/images/no_zj.png';
import RewardTopImg from '@assets/images/choujinag_1.png';

import RewardBgMusic from "@components/RewardMusic";
import LotteryBgMusic from "@components/RewardMusic/lottery_music";
import CountDown from "@components/CountDown";
import { MyIcon } from '@components/AppIcon';
import {enjoyFriend, useWeiXinApi} from '@utils/sdk/wexin';
import {useWeiXinTeam} from './reward_hooks';
import {webUrl} from '@utils/http';
import {getStorageParam} from '@utils/my_storage';
import Tools from '@utils/tools';
import TeamUpPerson from "@view/activity/reward/team";
import './index.scss';


const RewardSty:React.FC = ()=> {
  const navigator = useNavigate();
  const info = useSelector(activityInfo);
  const [bg_state, { toggle }] = useToggle(false);
  const [lottery_state, { toggle: toggleLottery }] = useToggle();
  const {setIsShowJackPot, setIsTeamShow} = activitySlice.actions;
  const {activity, isJackpotStatus, nowPrizesItem, isShowJackPot, isTeamShow} = info;
  const [rewards, setRewards] = useState([]);
  const [isRewardStatus, setRewardStatus] = useState(false);
  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();

  const param = Tools.param2Obj()

  useWeiXinApi('updateAppMessageShareData', false);

  const {hasTeam, all_info,teamUp, teamInfo, isTeamUpStatus, playerTeam} = useWeiXinTeam();

  useMount(async ()=> {
    if (param.hasOwnProperty('player')) {
      // 队员参与组队
      await playerTeam();
    }
    await setMyPrizeList();
  })

  useUpdateEffect( ()=> {
    if (activity.id > 0) {
      console.log('xxxx')
      all_info();
    }
  }, [activity.id])

  useUnmount(()=> {
    toggle(false);
    toggleLottery(false);
  })

  // 开始&&结束抽奖音乐
  const isPlayMusic = ()=> {
    toggleLottery();
  }

  const rewardList = ()=> {
    return rewards.map((item, index) => myRewards(item, index))
  }

  const goStartPlay =()=> {
    disPatch(setIsShowJackPot(false));
  }

  const navigatorJackPotDetail = async ()=> {
    await disPatch(setIsShowJackPot(false));
    navigator(`/reward_detail/${nowPrizesItem.prize_order_id}`)
  }

  const showDetail = (item: {id: number})=> {
    navigator(`/reward_detail/${item.id}`)
  }

  const closeTeamDetail = ()=> {
    disPatch(setIsTeamShow(false));
  }

  // 展示中奖记录
  const setMyPrizeList = async()=> {
    try {
      const info = await getUserPrize();
      if (info.code === 200) {
        if (info.data.length > 0) {
          setRewardStatus(true);
          setRewards(info.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 微信分享  立即组队 && 继续组队
  const wx_enjoy = async ()=> {
    // 组队成功
    if (isTeamUpStatus) return await disPatch(setIsTeamShow(true));
    // 组队进行中...
    const param = Tools.param2Obj();
    if (param.hasOwnProperty('player')) {
      let id = param.player;
      enjoyFriend({
        title: activity.team_title,
        desc: activity.team_statement,
        link: `${webUrl}/reward?app_id=${getStorageParam('app_id')}&player=${id}`,
        imgUrl: activity.team_pic_url
      });
      await disPatch(setIsTeamShow(true));
    } else {
      if (!hasTeam) await teamUp();
      else {
        enjoyFriend({
          title: activity.team_title,
          desc: activity.team_statement,
          link: `${webUrl}/reward?app_id=${getStorageParam('app_id')}&player=${activity.team_info?.id}`,
          imgUrl: activity.team_pic_url
        });
        await disPatch(setIsTeamShow(true));
      }
    }
  }

  const myRewards = (item: any, index: number)=> {
    if (isRewardStatus) {
      return(
        <div key={index} className={'my-goods'}>
          <div className={'award-text'}>
            <span className={'award-name'}>{item.award_name}</span>
            {jackPotListText(item)}
          </div>
          <span className={'show-detail'} onClick={()=> showDetail(item)}>查看详情</span>
        </div>
      )
    }
  }

  // 中奖文字信息展示
  const jackPotListText = (item: any)=> {
    let {award_type, coupon_list, balance, integral, title, award_name} = item;
    if (award_type === 1) return `获得${coupon_list.length}张优惠券`;
    if (award_type === 2) return `获得${integral}积分`;
    if (award_type === 3) return `获得${title ? title:award_name}`;
    if (award_type === 4) return `获得${balance}元`;
  }

  // 是否查看详情
  const isShowGoodsDetail = ()=> {
    if (nowPrizesItem.award_type === 3) {
      return (
        <div>
          <div className={'reward-btn'} onClick={()=> navigatorJackPotDetail()}>查看奖品详情</div>
          <div className={'reward-btn'} onClick={()=> disPatch(setIsShowJackPot(false))}>
            <span>我知道了</span>
          </div>
        </div>
      )
    }
    return (
      <div className={'reward-btn'} onClick={()=> disPatch(setIsShowJackPot(false))}>
        <span>我知道了</span>
      </div>
    )
  }

  // 奖项内容
  const setRewardGoods = ()=> {
    const {awards} = activity;
    let arr = awards?.filter(item => item.award_type !== 5);
    if (arr && arr.length >0) {
      return arr.map(item => {
        return (
          <div className={'reward-items'} key={item.id}>
            <div className={'reward-left'}>
              <img src={RewardGoods} alt={'logo'}/>
            </div>
            <div className={'reward-right'}>
              <span>{item.award_name}</span>
              <span>{jackPotListText(item)}</span>
            </div>
          </div>
        )
      })
    }
  }

  // 中奖
  const getJackPotShow = ()=> {
    return (
      <AppMaskBox status={isShowJackPot}>
        <div className={'reward-img'}>
          <img src={RewardImg} alt={'中奖了'}/>
        </div>
        <div className={'reward-text-info'}>
          <span className={'reward-text-title'}>{nowPrizesItem.title}</span>
          <span className={'reward-text-goods'}>恭喜您!{jackPotListText(nowPrizesItem)}</span>
        </div>
        {isShowGoodsDetail()}
      </AppMaskBox>
    )
  }

  // 没中奖
  const noJackPotShow = ()=> {
    return (
      <AppMaskBox status={isShowJackPot}>
        <div className={'reward-img'}>
          <img src={noReward} alt={'没中奖'}/>
        </div>
        <div className={'reward-text-info'}>
          <span className={'reward-text-goods'} style={{marginTop: '30px'}}>很遗憾！与奖品擦肩而过!</span>
        </div>
        <div className={'reward-btn'} style={{marginTop: '60px'}} onClick={()=> goStartPlay()}>再来一次</div>
      </AppMaskBox>
    )
  }

  const isAddTeam = ()=> {
    if (isTeamUpStatus) return (
      <div className={'team-btn'}>组队详情</div>
    )
    if (!hasTeam) return <div className={'team-btn'}>立即组队</div>;
    return (
      <div className={'team-btn-two'}>
        <span>继续组队</span>
        {
          teamInfo.end_time > 0 && <div className={'team-btn-time'}>
              <CountDown timeStamp={teamInfo.end_time}/>
          </div>
        }
      </div>
    )
  }

  return (
    <div className={'reward-con'}>
      <MyIcon
        className={ bg_state ? 'music-icon spin':'music-icon'}
        type={'iconmusic'}
        onClick={()=> toggle()}
      >
      </MyIcon>
      {/*{*/}
      {/*  activity.is_team === 1 && Tools.getSystemPayEnv() ==='wx' &&*/}
      {/*    <div className={'join-team'} onClick={()=> wx_enjoy()}>*/}
      {/*      {isAddTeam()}*/}
      {/*    </div>*/}
      {/*}*/}
      { Tools.isWeiXinWebView() && <div className={'join-team'} onClick={()=> wx_enjoy()}>
        {isAddTeam()}
      </div>}

      <LotteryBgMusic isPlay={lottery_state} />
      <RewardBgMusic isPlay={bg_state} />
      <div className={'reward-top-text'}>
        <img src={RewardTopImg} alt={'logo'}/>
      </div>
      { isJackpotStatus ? getJackPotShow():noJackPotShow() }
      <div className={'reward-main'}>
        <LuckyOne isPlayMusic={()=> isPlayMusic()} toggle={()=> toggle(true)}/>
      </div>
      <div className={'reward-text'}>
        <span>已有{activity.participants_num}人参与</span>
        <span>还有{activity.surplus_num}次抽奖机会</span>
      </div>
      <div className={'reward-info'}>
        <div className={'reward-goods'}>
          <div className={'reward-title'}>
            <span>我的奖品</span>
          </div>
          <div className={'reward-list'}>{isRewardStatus ? rewardList():'暂无中奖信息...'}</div>
        </div>
        <div className={'reward-goods'}>
          <div className={'reward-title'}>
            <span>活动奖品</span>
          </div>
          <div className={'reward-row'}>
            {setRewardGoods()}
          </div>
        </div>
        <div className={'reward-goods'}>
          <div className={'reward-title'}>
            <span>活动说明</span>
          </div>
          <div>{activity.intro}</div>
        </div>
        <div className={'reward-goods'}>
          <div className={'reward-title'}>
            <span>活动时间</span>
          </div>
          <div>{activity.start_time} 至 {activity.end_time}</div>
        </div>
      </div>
      <div className={'reward-bottom'} onClick={()=> navigator('/add_lottery')}>
        <div>我是商家，免费创建引流活动</div>
      </div>

      { teamInfo &&
        <AppMaskBox status={isTeamShow}>
          <TeamUpPerson
            isTeamUpStatus={isTeamUpStatus}
            closeTeamDetail={()=> closeTeamDetail()}
            teamInfo={teamInfo}
          />
        </AppMaskBox>
      }

    </div>
  )
}

export default RewardSty;
