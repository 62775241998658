import React, {useState} from "react";
import {Button, ImagePicker, InputItem, Toast} from 'antd-mobile';
import {useMount} from "ahooks";
import { useNavigate } from 'react-router-dom';
import { getSpecialCarList } from "@api/user";
import { uploadImageFile } from "@api/app";
import {useDispatch} from "react-redux";
import * as authInfo from "@store/auth.slice";
import './index.scss';


interface CarTypeState {
  id: number;
  name: string;
  url: string;
  desc: string;
  level_id: string;
  level_name: string,
  status: 0|1
}

const SpecialCar = ()=> {

  enum PageShow {
    ChooseType ,
    UploadImg,
  }

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [carTypeArr, setCarTypeArr] = useState([]);
  const [nowId, setCarTypeId] = useState(0);
  const [pageStatus, setPageStatus] = useState(PageShow.ChooseType);
  const [files, setFiles] = useState<any>([]);

  const disPatch: (...args: unknown[]) => Promise<any> = useDispatch();

  const { setCheckCarType } = authInfo.authSlice.actions;

  useMount(async ()=> {
    console.log(nowId)
    await getCarList();
  })

  const getCarList = async ()=> {
    try {
      const res = await getSpecialCarList();
      if (res.code === 200) setCarTypeArr(res.data);
    } catch (e) {
      console.log(e)
    }
  }

  const carItem = (item: CarTypeState)=> {
    return (
      <div className={nowId === item.id ? 'activity':'special-car-item'} key={item.id} onClick={()=> setCarTypeId(item.id)}>
        <div className={'special-car-left'}>
          <span>{item.name}</span>
          <span>{item.level_name}</span>
        </div>
        <div className={'special-car-img'}>
          <img src={item.url}  alt={''} />
        </div>
      </div>
    )
  }

  const uploadFile = async () => {
    if (files.length === 0) return Toast.info('请选择图片');
    const res = await uploadImageFile(files[0]?.file);
    if (res.code === 200) {
      await disPatch(setCheckCarType({
        car_type_id: nowId,
        name,
        url: res.data.url,
        mobile: 0,
        verification_code: 0,
      }))
      setTimeout(() => navigate('/checkPhone/uploadCarImage'))
    }
    Toast.info(res.msg);
  }

  const uploadImg = ()=> {
    return (
      <>
        <div className={'special-upload'}>
          <span>请拍摄行驶证首页照片</span>
          <ImagePicker
            files={files}
            onChange={(files)=> setFiles(files)}
            onImageClick={(index, fs) => console.log(index, fs)}
            multiple={false}
          />
          <InputItem placeholder={'请输入姓名'} value={name} onChange={(val) => setName(val)}/>
        </div>
        <div className={'upload-btn'}>
          <Button type={'warning'} onClick={()=> setPageStatus(PageShow.ChooseType)}>返回</Button>
          <Button type={'primary'} onClick={()=> uploadFile()}>下一步</Button>
        </div>
      </>
    )
  }

  const chooseTypePage = ()=> {
    return (
      <>
        <div className={'special-con-top'}>
          <span>选择您的车辆用途</span>
          <span>我们会根据您所填的所有信息</span>
          <span>来为您打造最合适的服务</span>
        </div>
        <div className={'special-main'}>
          <span className={'special-item-title'}>请选择车辆类型</span>
          { carTypeArr.map((item, index) => carItem(item)) }
        </div>
        <div className={'special-btn'} onClick={()=> setPageStatus(PageShow.UploadImg)}>
          <Button type={'warning'}>下一步</Button>
        </div>
      </>
    )
  }

  return (
    <div className={'special-con'}>
      { pageStatus === 0 ? chooseTypePage():uploadImg() }
    </div>
  )
}

export default SpecialCar;

