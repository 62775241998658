import HttpServer from '@utils/http';

interface userPay {
  qrcode_id: number;
  room_id?: string|null;
  store_id: number;
}

export interface OrderCompute {
  [key: string]: any;
  cate_id?: number;    //设备id  扫收款码/自助买单必传
  money?: number;   //买单金额
  payment_code?:  string;
  sn?: string|null; //订单编码  扫员工订单码必传
  room_id?: string|null;   //门店id  自助买单必传
  qrcode_id?: string|null; //二维码id  扫收款码必传
  source?: "wx"|"ali"|"h5";
  account_id?: number; //支付方式下的账户id
  point?: 1|0;   //是否使用积分优惠 1是 0否
  coupon?: 1|0;   //是否使用优惠券抵扣 1是 0否
  coupon_ids?: string;   //选择使用的优惠券ids 多张英文逗号隔开
  discount?: 1|0 ;  //是否使用会员折扣 1是 0否
  recharge_id: string;
  key: string;
  pay_password?: string;
  return_url?: string;
  platform_card_data_id?: number;
  platform?: number;
  recharge_multiple: 1|0;
}

export interface RechargeParam {
  cate_id: number;
  room_id: number;
  money: number;
}

interface OilInfo {
  qrcode_id?: number;
  room_id?: number;
}

// 获取油品信息
export const getOilInfo = (data: OilInfo) => {
  return HttpServer.post({ url: 'client/index/show_index', params: data})
}


// 获取用户付款信息
export const userPayInfo = (data: userPay) => {
  return HttpServer.post({ url: 'offlinestore/user.pay/index', params: data})
}

// 加油买单优惠计算  /
export const computeUserOrder = (data: OrderCompute) => {
  return HttpServer.post({ url: 'client/order/compute', params: data})
}

// 储值支付项目
export const getRechargeInfo = (data: RechargeParam) => {
  return HttpServer.post({ url: 'client/recharge/recharge_item', params: data})
}

// 用户下单
export const createOrder = (data: OrderCompute) => {
  return HttpServer.post({ url: 'client/order/create_order', params: data})
}

// 获取订单详情
export const getOrderDetail = (ordersn: string) => {
  return HttpServer.post({ url: 'offlinestore/user.order/detail', params: {ordersn}})
}

// 是否有未确认订单
export const isCashStatus = async () => {
  return await HttpServer.post({url: 'offlinestore/user.order/cash_order_status', params: {
    status: 1
  }})
}

// 富有调起支付
export const createPayment = async (pay_token: string, paysn: string) => {
  return await HttpServer.post({url: 'client/order/create_payment', params: {
    pay_token,
    paysn
  }})
}

// 取消订单回调
export const failOrderCallBack = async (ordersn: string) => {
  return await HttpServer.post({url: 'client/pay_fail/order', params: {
    ordersn
  }})
}

// 支付订单失败
export const setPayFail = async (sn: string) => {
  return await HttpServer.post({ url: '/client/order/scan_pay_fail', params: {
    sn
  }})
}
